import { useState } from 'react';
import { CaretDown, CaretRight, IconContext } from '@phosphor-icons/react';

import { IngredientCategoryDTO } from '../../dto/IngredientCategoryDTO';

import classes from './style.module.css';

interface AccordionProps {
    category: IngredientCategoryDTO;
    children: React.ReactNode;
}

const Accordion = ({ category, children }: AccordionProps) => {
    const [isVisible, setIsVisible] = useState(false);

    function handleOpen() {
        setIsVisible(!isVisible);
    }

    return (
        <div className={classes.container}>
            <button
                className={classes.button}
                onClick={handleOpen}
            >
                <IconContext.Provider
                    value={{
                        size: 20,
                        color: '#272727',
                        weight: 'bold'
                    }}
                >
                    {
                        isVisible ?
                            <CaretDown />
                            :
                            <CaretRight />
                    }
                </IconContext.Provider>
                <p className={classes.textButton}>
                    {category.label}
                </p>
            </button>
            {
                isVisible &&
                <>
                    {children}
                </>
            }
        </div>
    )
}

export default Accordion;