import { useLayoutEffect, useState } from "react";
import { Check, Detective, DeviceMobileCamera, EnvelopeSimple, PaperPlaneTilt, Stethoscope, TextAa } from "@phosphor-icons/react";

import Header from "../../../components/Header";
import Loading from "../../../components/Loading";

import { administratorListUsers, administratorPushNotification } from "../../../dao/AdministratorDAO";
import { UserDTO } from "../../../dto/UserDTO";

import NotFoundPhoto from '../../../images/notFoundPhoto.png';

import classes from './style.module.css';
import CardStatus from "../../../components/CardStatus";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import Textarea from "../../../components/Textarea";
import { useAlert } from "../../../hooks/alert";

const Users = () => {
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [listUsers, setListUsers] = useState<UserDTO[]>([]);
    const [userSelected, setUserSelected] = useState<UserDTO>({} as UserDTO);
    const [typeDocument, setTypeDocument] = useState('');
    const [visibleModalNutritionist, setVisibleModalNutritionist] = useState(false);
    const [visibleModalPushNotification, setVisibleModalPushNotification] = useState(false);

    const [notificationTitle, setNotificationTitle] = useState('')
    const [notificationMessage, setNotificationMessage] = useState('')

    function handleCloseModal() {
        setVisibleModalNutritionist(false)
        setTypeDocument('')
        setUserSelected({} as UserDTO)
    }

    function handleOpenModal(user: UserDTO) {
        if (user.isNutritionist && user.category.label !== 'Nutricionista') {
            setUserSelected(user)
            setTypeDocument(userSelected.certificate?.indexOf('pdf') !== -1 ? 'PDF' : 'IMAGE')
            setVisibleModalNutritionist(true)
        }
    }

    function handleOpenModalNotification(user: UserDTO) {
        setUserSelected(user)
        setVisibleModalPushNotification(true)
    }

    async function handleSendNotification() {
        setLoadingModal(true)

        const result = await administratorPushNotification(userSelected.id, notificationTitle, notificationMessage, userSelected.tokenFirebase);

        setLoadingModal(false)

        if (result.statusCode === 200) {
            setUserSelected({} as UserDTO)
            setVisibleModalPushNotification(false)

            return showAlert({
                message: result.message || '',
                type: 'success'
            })
        }
    }

    async function loadUsers() {
        const result = await administratorListUsers();

        if (result.statusCode === 200) {
            let list: UserDTO[] = [];
            result.data.map((user: UserDTO) => {
                if (user.isActive !== -1) {
                    list.push(user)
                }
            })
            setListUsers(list)
        }

        setLoading(false)
    }

    useLayoutEffect(() => {
        if (reload) {
            loadUsers()
            setReload(false)
        }
    }, [reload])

    return (
        <>
            {loading ?
                <Loading text="Carregando usuários..." />
                :
                <div className={classes.body}>
                    {listUsers.map((user, index) => {
                        return (
                            <div className={classes.item} key={index}>
                                <div className={classes.info}>
                                    <div className={classes.photo}>
                                        <img src={user.photo ? user.photo : NotFoundPhoto} className={classes.photo} />
                                    </div>
                                    <div className={classes.containerItem}>
                                        <div className={classes.containerName}>
                                            <p className={classes.infoName}>{user.name}</p>
                                            {(!user.isActive) ?
                                                <CardStatus text="Pendente" type="orange" />
                                                :
                                                <>
                                                    {(user.isActive === -1) ?
                                                        <CardStatus text="Desativado" type="red" />
                                                        :
                                                        <CardStatus text="Ativo" type="green" />
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className={classes.itemMenu}>
                                            <div>
                                                <p className={classes.infoText}>{user.birthDate}</p>
                                                <p className={classes.infoText}>{user.city} - {user.state}</p>
                                                <p className={classes.infoText}>{user.email}</p>

                                            </div>
                                            <div className={classes.menuContainer}>
                                                {user.category.label === 'Nutricionista' ?
                                                    <Stethoscope size={25} color="#FF6E00" />
                                                    :
                                                    (user.isNutritionist) ?
                                                        <button className={classes.button} onClick={() => handleOpenModal(user)} title="Aprovar Certificado">
                                                            <Stethoscope size={25} color="#2727273b" />
                                                        </button>
                                                        : <></>
                                                }
                                                <button className={classes.button} title={user.category.label !== 'Administrador' ? 'Tornar Administrador' : 'Remover de Administrador'}>
                                                    {(user.category.label !== 'Administrador') ?
                                                        <Detective size={25} color="#2727273b" />
                                                        :
                                                        <Detective size={25} color="#FF6E00" />
                                                    }
                                                </button>
                                                {user.tokenFirebase ?
                                                    <button className={classes.button} onClick={() => handleOpenModalNotification(user)} title="Enviar Notificação">
                                                        <DeviceMobileCamera size={25} color="#FF6E00" />
                                                    </button>
                                                    :
                                                    <DeviceMobileCamera size={25} color="#2727273b" />
                                                }
                                                <EnvelopeSimple size={25} color="#FF6E00" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            {visibleModalNutritionist &&
                <div className={classes.modalMenuContainer}>
                    <div className={classes.modalMenuBody}>
                        <div className={classes.modalHeader}>
                            <p className={classes.modalHeaderTitle}>Aprovar nutricionista</p>
                            <button className={classes.modalHeaderButton} onClick={handleCloseModal} >
                                <p className={classes.modalHeaderButtonText}>x</p>
                            </button>
                        </div>
                        <div className={classes.modalBody}>
                            <div>
                                <p>Nome: {userSelected.name}</p>
                                <p>Data de Nascimento: {userSelected.birthDate}</p>
                                <p>Cidade: {userSelected.city} / {userSelected.state}</p>
                                <p>Documento: {typeDocument}</p>
                            </div>
                            {typeDocument === 'PDF' ?
                                <embed src={userSelected.certificate} className={classes.contentPdf} />
                                :
                                <img src={userSelected.certificate} />
                            }
                        </div>
                        <div className={classes.modalFooter}>
                            <button className={classes.buttonGreen} onClick={() => { }} >
                                <p className={classes.modalFooterButtonText}>APROVAR</p>
                            </button>
                            <button className={classes.buttonRed} onClick={() => { }} >
                                <p className={classes.modalFooterButtonText}>RECUSAR</p>
                            </button>
                        </div>
                    </div>
                </div>
            }
            {visibleModalPushNotification &&
                <Modal
                    title='Enviar Notificação'
                    onRequestClose={() => setVisibleModalPushNotification(false)}
                    textButtonConfirm='Enviar'
                    onRequestConfirm={handleSendNotification}
                    loading={loadingModal}
                >
                    <Input
                        icon={<TextAa size={32} />}
                        value={notificationTitle}
                        onChange={(e) => setNotificationTitle(e.target.value)}
                        placeholder="Título"
                        marginBottom
                    />
                    <Textarea
                        icon={<TextAa size={32} />}
                        value={notificationMessage}
                        onChange={(e) => setNotificationMessage(e.target.value)}
                        placeholder="Mensagem"
                    />
                </Modal>
            }
        </>
    )
}

export default Users;