import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Medal, TextAlignJustify } from '@phosphor-icons/react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Textarea from '../../components/Textarea';
import Dropdown from '../../components/Dropdown';
import ButtonPrimary from '../../components/ButtonPrimary';

import { useAlert } from '../../hooks/alert';
import { newCalled } from '../../dao/UserDAO';

import classes from './style.module.css';
import { useAuth } from '../../hooks/auth';
import Loading from '../../components/Loading';

const NewReportBug = () => {
    const { type } = useParams();
    const { showAlert } = useAlert();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [typeCalled, setTypeCalled] = useState('');
    const [description, setDescription] = useState('');

    const listTypeCalled = [
        {
            label: 'Reclamação',
            value: 'Reclamação'
        },
        {
            label: 'Sugestão',
            value: 'Sugestão'
        },
        {
            label: 'Elogio',
            value: 'Elogio'
        },
        {
            label: 'Solicitação',
            value: 'Solicitação'
        },
        {
            label: 'Denúncia',
            value: 'Denúncia'
        },
        {
            label: 'Reportar Bug',
            value: 'Reportar Bug'
        }
    ];

    async function handleEnviar() {
        setLoading(true)

        const response = await newCalled(typeCalled, description, user.id);

        if (response.statusCode === 201) {
            showAlert({ type: 'success', message: 'Enviado com sucesso!' })
        } else if (response.statusCode === 406) {
            showAlert({ type: 'error', message: response?.message || '' })
        } else {
            showAlert({ type: 'warning', message: response?.message || '' })
        }

        setLoading(false)
    }

    useEffect(() => {
        if (type === 'report-bug') {
            setTypeCalled('Reportar Bug')
        }
    }, [type])

    return (
        <>
            {loading ? <Loading text='Enviando...'/> :

                <div className={classes.container}>
                    <div className={classes.containerHeader}>
                        <Header back />
                    </div>
                    <div className={classes.body}>
                        <div className={classes.content}>
                            {type === 'report-bug' ?
                                <p className={classes.title}>Relatar um problema</p>
                                :
                                <p className={classes.title}>Abrir um chamado</p>
                            }
                            <div className={classes.line} />
                            {type !== 'report-bug' &&
                                <Dropdown
                                    value={typeCalled}
                                    onChange={e => setTypeCalled(e.target.value)}
                                    listData={listTypeCalled}
                                    icon={<Medal />}
                                    placeholder='Selecione o tipo do chamado'
                                    marginBottom
                                />
                            }
                            <Textarea
                                icon={<TextAlignJustify />}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder={type === 'report-bug' ? 'O que deu errado?' : 'Como podemos ajudar?'}
                            />
                            <ButtonPrimary
                                text='ENVIAR'
                                onClick={() => handleEnviar()}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default NewReportBug;