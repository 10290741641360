import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { EnvelopeSimple, Lock } from "@phosphor-icons/react"

import { useAuth } from "../../hooks/auth"
import { useAlert } from "../../hooks/alert"
import { validateMail } from "../../hooks/validateForm"

import HeaderNew from "../../components/HeaderNew"
import Input from "../../components/Input"
import ButtonPrimary from "../../components/ButtonPrimary"
import ButtonNeutro from "../../components/ButtonNeutro"

import logo from '../../images/logo-orange.svg'

import classes from './style.module.css'

const Login = () => {
    const { showAlert } = useAlert()
    const { signIn } = useAuth()
    const navigate = useNavigate();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    async function handleContinue() {
        setButtonLoading(true)

        if (!validateMail(email)) {
            setButtonLoading(false)
            return showAlert({ message: 'E-mail inválido.', type: 'error' })
        }

        if (password === '') {
            setButtonLoading(false)
            return showAlert({ message: 'Senha deve ser preenchido.', type: 'error' })
        }

        await signIn({
            email,
            password
        })

        setButtonLoading(false)
    }

    return (
        <body>
            <div className={classes.container}>
                <div className={classes.containerHeader}>
                    <div className={classes.contentHeader}>
                        <HeaderNew newAccount />
                    </div>
                </div>
                <div className={classes.contentBody}>
                    <img src={logo} className={classes.logo} />
                    <div className={classes.contentForm}>
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            icon={<EnvelopeSimple />}
                            placeholder='E-mail'
                            marginBottom
                        />
                        <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type='password'
                            icon={<Lock />}
                            placeholder='Senha'
                            onKeyDown={(e) => { e.key === 'Enter' && handleContinue() }}
                        />
                        <ButtonPrimary
                            text='ACESSAR'
                            onClick={handleContinue}
                            disabled={buttonLoading}
                        />
                        <ButtonNeutro
                            text='Esqueceu sua senha?'
                            onClick={() => navigate('/recoverPassword')}
                            disabled={buttonLoading}
                        />
                    </div>
                </div>
            </div>
        </body >
    )
}

export default Login;