import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import { ReactComponent as Logo } from '../../images/logo-orange.svg';
import imageNotFound from '../../images/notFoundPhoto.png'

import classes from './style.module.css';
import { Bell, IconContext, List } from '@phosphor-icons/react';

interface IHeaderHome {
    onClickLogin: () => void;
    notification: number;
}

const HeaderHome = ({ onClickLogin, notification }: IHeaderHome) => {
    const { user, logoutUser } = useAuth();
    const navigate = useNavigate();

    function handleRoute(route: string) {
        navigate(route)
    }

    return (
        <div className={classes.container}>
            {user.id ?
                <img src={user.photo ?? imageNotFound} className={classes.photo} alt={user.name} />
                :
                <button
                    className={classes.buttonLogo}
                    onClick={() => handleRoute('/')}
                >
                    <Logo className={classes.logo} />
                </button>
            }
            <div className={classes.contentMenu}>
                {user.id ?
                    <>
                        <Button icon={<Bell />} onClick={() => handleRoute('/menu/notifications')} notification={notification} />
                        <Button icon={<List />} onClick={() => handleRoute('/menu')} />
                    </>
                    :
                    <button
                        className={classes.button}
                        onClick={onClickLogin}
                    >
                        <p className={classes.buttonText}>Login</p>
                    </button>
                }
            </div>
        </div>
    )
}

interface IButton {
    icon: any;
    onClick: () => void;
    notification?: number;
}

function Button({ icon, onClick, notification = 0 }: IButton) {
    return (
        <button
            className={classes.buttonMenu}
            onClick={onClick}
        >
            <IconContext.Provider value={{ size: 30, color: '#FF6E00' }}>
                {icon}
            </IconContext.Provider>
            {notification > 0 &&
                <div className={classes.iconNumber}>
                    <p className={classes.iconNumberText}>{notification}</p>
                </div>
            }
        </button>
    )
}

export default HeaderHome;