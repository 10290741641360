import { ReactNode } from 'react';
import { parseCookies } from 'nookies';
import { useNavigate } from 'react-router-dom';
import { CaretRight, Desktop, FilePlus, Heart, IconContext, Notebook, Question, SignOut, Trash, Wallet } from '@phosphor-icons/react';

import HeaderNew from '../../components/HeaderNew';

import { useAuth } from '../../hooks/auth';
import { isAdmin } from '../../hooks/isAdmin';

import classes from './style.module.css'

const Menu = () => {
    const { '@letscook.user': tokenAdmin } = parseCookies();
    const { user, logoutUser } = useAuth();
    const navigate = useNavigate();

    return (
        <body>
            <div className={classes.container}>
                <div className={classes.containerHeader}>
                    <div className={classes.contentHeader}>
                        {/* <HeaderNew buttonRight={{ icon: <PencilSimple />, onClick: () => navigate('/menu/editUser') }} /> */}
                        <HeaderNew />
                    </div>
                </div>
                <div className={classes.containerBody}>
                    <div className={classes.contentBody}>
                        <img src={user.photo} className={classes.photo} />
                        <div className={classes.infoContainer}>
                            <p className={classes.infoName}>{user.name}</p>
                        </div>
                        <Options title="Receitas">
                            <ButtonOption
                                icon={<FilePlus />}
                                label='Enviar'
                                onClick={() => navigate('/menu/recipes/new')}
                            />
                            <ButtonOption
                                icon={<Heart />}
                                label='Favoritas'
                                onClick={() => navigate('/menu/recipes/favorites')}
                            />
                            <ButtonOption
                                icon={<Notebook />}
                                label='Minhas Receitas'
                                onClick={() => navigate('/menu/recipes/myRecipes')}
                            />
                        </Options>
                        <Options title="Configurações">
                            <ButtonOption
                                icon={<Wallet />}
                                label='Plano'
                                onClick={() => navigate('/menu/plans')}
                            />
                        </Options>
                        {isAdmin(tokenAdmin) &&
                            <Options title="Administrador">
                                <ButtonOption
                                    icon={<Desktop />}
                                    label='Painel'
                                    onClick={() => navigate('/administrator')}
                                />
                            </Options>
                        }
                        <Options title="Suporte">
                            <ButtonOption
                                icon={<Question />}
                                label='Ajuda'
                                onClick={() => navigate('/menu/help')}
                            />
                        </Options>
                        <Options title="Conta">
                            <ButtonOption
                                icon={<Trash />}
                                label='Excluir Conta'
                                onClick={() => navigate('/menu/deleteAccount')}
                            />
                            <ButtonOption
                                icon={<SignOut />}
                                label='Sair'
                                onClick={logoutUser}
                                right={false}
                            />
                        </Options>
                    </div>
                </div>
            </div>
        </body >
    )
}

interface IOptions {
    title: string;
    children: ReactNode;
}

function Options({ title, children }: IOptions) {
    return (
        <div className={classes.optionsContainer}>
            <p className={classes.optionsTitle}>{title}</p>
            <div className={classes.optionsContent}>
                {children}
            </div>
        </div>
    )
}

interface IButtonOption extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: any;
    label: string;
    right?: boolean;
}

function ButtonOption({ icon, label, right = true, ...rest }: IButtonOption) {
    return (
        <button className={classes.optionsButton} {...rest}>
            <IconContext.Provider value={{ size: 32, color: '#272727' }} >
                {icon}
            </IconContext.Provider>
            <p className={classes.optionsButtonText}>{label}</p>
            {right && <CaretRight size={15} color='#272727' />}
        </button>
    )
}

export default Menu;