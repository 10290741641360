import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RecipeDTO } from '../../dto/RecipeDTO';
import { IngredientDTO } from '../../dto/IngredientDTO';

import classes from './style.module.css';

import HeartSuit from '../../images/Figmoji/heart-suit.png';
import Cooking from '../../images/Figmoji/cooking.png';
import Watch from '../../images/Figmoji/watch.png';
import DirectHit from '../../images/Figmoji/direct-hit.png';
import { converterMinutes } from '../../hooks/converterMinutes';
import { CallBell, Clock, IconContext, Star, Tote } from '@phosphor-icons/react';

interface CardRecipeProps {
    recipe: RecipeDTO;
    listSelectedIngredientsUser?: IngredientDTO[];
}

const CardRecipe = ({
    recipe,
    listSelectedIngredientsUser = [],
}: CardRecipeProps) => {
    const navigate = useNavigate();
    const [totalIngredientsUser, setTotalIngredientsUser] = useState(0);

    function handleViewRecipe(id: string) {
        navigate(`/viewRecipe/${id}`)
    }

    function loadInformation() {
        let total = 0;
        listSelectedIngredientsUser.map(ingredientUser => {
            let selected = recipe.ingredients.findIndex((ingredientRecipe: IngredientDTO) => ingredientRecipe.id === ingredientUser.id);
            if (selected >= 0) {
                total = total + 1;
            }
        })
        setTotalIngredientsUser(total);
    }

    useEffect(() => {
        loadInformation()
    }, [])

    return (
        <button
            className={classes.container}
            onClick={() => handleViewRecipe(recipe.id)}
        >
            <img src={recipe.photo} className={classes.photo} />
            <div className={classes.infoContainer}>
                <p className={classes.title}>{recipe.title}</p>
                <div className={classes.infoDetailsContainer}>
                    <InfoDetails icon={<Star />} text={String(recipe?.evaluation?.amountAssessments ?? '5.0')} />
                    <InfoDetails icon={<Clock />} text={converterMinutes(recipe.time)} />
                    <InfoDetails icon={<CallBell />} text={String(recipe.portions)} />
                    {(Object.keys(listSelectedIngredientsUser).length > 0) &&
                        <InfoDetails icon={<Tote />} text={`${totalIngredientsUser} / ${recipe.countIngredients}`} />
                    }
                </div>
            </div>
        </button>
    )
}

interface IInfoDetails {
    icon: any;
    text: string;
}

function InfoDetails({ icon, text }: IInfoDetails) {
    return (
        <div className={classes.infoDetailsContent}>
            <IconContext.Provider
                value={{ size: 20, color: '#FF6E00' }}
            >
                {icon}
            </IconContext.Provider>
            <p className={classes.infoDetailsText}>
                {text}
            </p>
        </div>
    )
}

export default CardRecipe;