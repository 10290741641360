import { useLayoutEffect, useState } from "react";
import { Camera, PencilSimple, Plus, TextAa } from "@phosphor-icons/react";

import { administratorCreateMeasure, administratorDeleteMeasure, administratorEditMeasure, administratorListMeasures } from "../../../dao/AdministratorDAO";
import { useAlert } from "../../../hooks/alert";

import { RecipeMeasureDTO } from "../../../dto/RecipeMeasureDTO";

import Loading from "../../../components/Loading";
import Modal from "../../../components/Modal";
import InputAttachment from "../../../components/InputAttachment";
import Input from "../../../components/Input";

import classes from './style.module.css';

const IngredientMeasurements = () => {
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [listMesuares, setListMesuares] = useState<RecipeMeasureDTO[]>([]);
    const [mesuareSelected, setMesuareSelected] = useState<RecipeMeasureDTO>({} as RecipeMeasureDTO);
    const [isVisibleModal, setIsVisibleModal] = useState(false);

    const [photo, setPhoto] = useState<File>({} as File);
    const [photoUrl, setPhotoUrl] = useState('');
    const [titleMeasure, setTitleMeasure] = useState('');

    function openModal(measure?: RecipeMeasureDTO) {
        setIsVisibleModal(true);
        if (measure) {
            setMesuareSelected(measure);
            setPhotoUrl(measure.photo);
            setTitleMeasure(measure.label);
        }
    }

    function closeModal() {
        setIsVisibleModal(false);
        setMesuareSelected({} as RecipeMeasureDTO);
        setPhoto({} as File)
        setPhotoUrl('');
        setTitleMeasure('');
        setLoadingModal(false);
    }

    const handleChangePhoto = async (e: any) => {
        const selectedFile = e.target.files[0];
        const objectUrl = URL.createObjectURL(selectedFile)

        setPhotoUrl(objectUrl)
        setPhoto(selectedFile)
    };

    async function handleSaveNewMeaseure() {
        if (titleMeasure.length < 3) {
            return showAlert({
                message: 'Preencha o nome da medida.',
                type: 'error'

            })
        }

        setLoadingModal(true);

        const response = await administratorCreateMeasure(titleMeasure, photo);

        if (response.statusCode === 201) {
            setReload(true);
            closeModal();

            return showAlert({
                message: 'Medida cadastrada com sucesso.',
                type: 'success'

            })
        } else {
            setLoadingModal(false);
            return showAlert({
                message: response.message ? response.message : 'Falha ao criar medida.',
                type: 'warning'
            })
        }
    }

    async function handleSaveEditMeasure() {
        if (titleMeasure.length < 1) {
            return showAlert({
                message: 'Preencha o nome da medida.',
                type: 'error'

            })
        }

        setLoadingModal(true);

        const response = await administratorEditMeasure(mesuareSelected.id, titleMeasure, photo, mesuareSelected.photo);

        if (response.statusCode === 200) {
            setReload(true);
            closeModal();

            return showAlert({
                message: 'Medida editada com sucesso.',
                type: 'success'

            })
        } else {
            setLoadingModal(false);
            return showAlert({
                message: response.message ? response.message : 'Falha ao editar a medida.',
                type: 'warning'
            })
        }
    }

    async function handleDeleteMeasure() {
        if (mesuareSelected.id) {
            setLoadingModal(true)

            const response = await administratorDeleteMeasure(mesuareSelected.id);

            if (response.statusCode === 200) {
                setReload(true);
                closeModal();

                return showAlert({
                    message: 'Medida excluída com sucesso.',
                    type: 'success'

                })
            } else {
                setLoadingModal(false);
                return showAlert({
                    message: response.message ? response.message : 'Falha ao excluir a medida.',
                    type: 'warning'
                })
            }
        }
    }

    async function loadRecipes() {
        const result = await administratorListMeasures();

        if (result.statusCode === 200) {
            setListMesuares(result.data)
        }

        setLoading(false)
    }

    useLayoutEffect(() => {
        if (reload) {
            loadRecipes()
            setReload(false)
        }
    }, [reload])


    return (
        <>
            {
                loading ?
                    <Loading text="Carregando medidas..." />
                    :
                    <div className={classes.container}>
                        <div className={classes.body}>
                            {listMesuares.map((item, index) => {
                                return (
                                    <div className={classes.item} key={index}>
                                        <div className={classes.photo}>
                                            <img src={item.photo} className={classes.photo} />
                                        </div>
                                        <div className={classes.contentTitle} >
                                            <p>{item.label}</p>
                                        </div>
                                        <button className={classes.button} onClick={() => openModal(item)}>
                                            <PencilSimple size={22} color='#FFFFFF' />
                                        </button>
                                    </div>
                                )
                            })}
                            <button className={classes.buttonMenu} onClick={() => openModal()}>
                                <Plus size={30} color='#FFFFFF' weight="bold" />
                            </button>
                        </div>
                    </div>
            }
            {isVisibleModal &&
                <Modal
                    title={mesuareSelected.id ? 'Editar Medida' : 'Adicionar Nova Medida'}
                    onRequestClose={closeModal}
                    onRequestConfirm={mesuareSelected.id ? handleSaveEditMeasure : handleSaveNewMeaseure}
                    textButtonConfirm={mesuareSelected.id ? 'Salvar' : 'Adicionar'}
                    loading={loadingModal}
                >
                    <div className={classes.modalContentPhoto}>
                        {photoUrl !== '' ?
                            <img src={photoUrl} className={classes.modalPhoto} />
                            :
                            <>
                                <Camera size={32} color='#272727' />
                                <p className={classes.modalTextPhoto}> Nenhuma foto selecionada. </p>
                            </>
                        }
                    </div>
                    <InputAttachment
                        value={photo.name}
                        onChange={e => handleChangePhoto(e)}
                        placeholder="Foto do ingrediente"
                        marginBottom
                    />
                    <Input
                        icon={<TextAa size={32} />}
                        value={titleMeasure}
                        onChange={(e) => setTitleMeasure(e.target.value)}
                        placeholder="Nome da medida"
                        marginBottom
                    />
                    {mesuareSelected.id &&
                        <button className={classes.modalButton} onClick={handleDeleteMeasure}>
                            <p className={classes.modalButtonText}>Excluir Medida</p>
                        </button>
                    }
                </Modal>
            }
        </>
    )
}

export default IngredientMeasurements;