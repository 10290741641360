import { Check } from '@phosphor-icons/react';
import { ButtonHTMLAttributes } from 'react';
import classes from './style.module.css';

interface CheckboxProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
    value: any;
}

const Checkbox = ({
    label,
    value,
    ...props
}: CheckboxProps) => {
    return (
        <button className={classes.container} {...props} >
            <div className={` ${value ? classes.checkSelected : classes.check} `}>
                {value && <Check size={25} weight="bold" color='#FF6E00' />}
            </div>
            <p className={` ${value ? classes.textSelected : classes.text} `}> {label} </p>
        </button>
    )
}

export default Checkbox;