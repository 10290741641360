import { useNavigate } from 'react-router-dom';

import classes from './style.module.css';

import { ReactComponent as Logo } from '../../images/logo.svg';

import { useAuth } from '../../hooks/auth';

interface HeaderProps {
    logo?: boolean;
    back?: boolean;
    login?: boolean;
    onPressLogin?: () => void;
}

const Header = ({
    logo = true,
    back = false,
    login = false,
    onPressLogin
}: HeaderProps) => {
    const { user, logoutUser } = useAuth();
    const navigate = useNavigate();

    function handleGoBack() {
        navigate(-1)
    }

    function handleRoute(route: string) {
        navigate(route)
    }

    function handleLogoutUser() {
        logoutUser()
    }

    return (
        <div className={classes.container}>
            <div className={classes.containerLogo}>
                {
                    logo &&
                    <button
                        className={classes.contentLogo}
                        onClick={() => handleRoute('/')}
                    >
                        <Logo className={classes.logo} />
                    </button>
                }
            </div>
            <div className={classes.containerMenu}>
                {
                    user.id ?

                        <>
                            {
                                back ?
                                    <button onClick={handleGoBack} className={classes.button}>VOLTAR</button>
                                    :
                                    <>
                                        <img src={user.photo} className={classes.photo} alt={user.name} />
                                        <button onClick={handleLogoutUser} className={classes.button}>
                                            <p className={classes.text}>SAIR</p>
                                        </button>
                                    </>
                            }
                        </>
                        :
                        <>

                            {
                                back ?
                                    <button onClick={handleGoBack} className={classes.button}>VOLTAR</button>
                                    :
                                    <>
                                        {
                                            login ?
                                                <>
                                                    <button onClick={() => handleRoute('/recoverPassword')} className={classes.button}>
                                                        <p className={classes.text}>RECUPERAR SENHA</p>
                                                    </button>
                                                    <button onClick={handleGoBack} className={classes.button}>
                                                        <p className={classes.text}>VOLTAR</p>
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    <button onClick={() => handleRoute('/newAccount')} className={classes.button}>
                                                        <p className={classes.text}>CRIAR CONTA</p>
                                                    </button>
                                                    <button onClick={onPressLogin} className={classes.button}>
                                                        <p className={classes.text}>LOGIN</p>
                                                    </button>
                                                </>
                                        }
                                    </>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default Header;