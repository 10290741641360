

import classes from './style.module.css';

interface Props {
    text: string;
    type: 'green' | 'orange' | 'red';
}

const CardStatus = ({
    text,
    type
}: Props) => {
    return (
        <div className={
            type == 'green' ?
                classes.cardGreen
                : (type == 'orange') ?
                    classes.cardOrange :
                    classes.cardRed
        }>
            <p className={
                type == 'green' ?
                    classes.cardTextGreen
                    : (type == 'orange') ?
                        classes.cardTextOrange :
                        classes.cardTextRed
            }>
                {text}
            </p>
        </div>
    )
}

export default CardStatus;