import { RecipeModePreparationDTO } from '../../dto/RecipeModePreparationDTO';
import classes from './style.module.css';

interface CardModePreparationProps {
    modePreparation: RecipeModePreparationDTO;
    buttonRemove?: () => void;
}

const CardModePreparation = ({
    modePreparation,
    buttonRemove
}: CardModePreparationProps) => {

    return (
        <div className={classes.container}>
            <p className={classes.position}>
                {modePreparation.position}
            </p>
            <p className={classes.description}>
                {modePreparation.description}
            </p>
            {buttonRemove &&
                <div className={classes.buttonRemoveContent} >
                    <button onClick={buttonRemove} className={classes.buttonRemove}>
                        <p className={classes.buttonRemoveText}>x</p>
                    </button>
                </div>
            }
        </div>
    )
}

export default CardModePreparation;