
import { SpinnerDotted } from 'spinners-react';
import classes from './style.module.css';

interface Props {
    title: string;
    onRequestClose?: () => void;
    textButtonCancel?: string;
    onRequestConfirm: () => void;
    textButtonConfirm?: string;
    loading?: boolean;
    children: React.ReactNode;
}

const Modal = ({
    title,
    onRequestClose,
    textButtonCancel,
    onRequestConfirm,
    textButtonConfirm,
    loading = false,
    children
}: Props) => {
    return (
        <div className={classes.containerModal}>
            <div className={classes.contentModal}>
                {
                    loading ?
                        <div className={classes.containerLoading}>
                            <SpinnerDotted
                                size={50}
                                thickness={100}
                                speed={100}
                                color="rgba(255, 110, 0, 1)"
                            />
                        </div>
                        :
                        <>

                            <div className={classes.headerModal}>
                                <p className={classes.headerTitle}>
                                    {title}
                                </p>
                            </div>
                            <div className={classes.bodyModal}>
                                {children}
                            </div>
                            <div className={classes.footerModal}>
                                {onRequestClose &&
                                    <button className={classes.footerButton}>
                                        <p
                                            className={classes.footerButtonText}
                                            onClick={onRequestClose}
                                        >
                                            {!textButtonCancel ? 'Cancelar' : textButtonCancel}
                                        </p>
                                    </button>
                                }
                                <button
                                    className={classes.footerButton}
                                    onClick={onRequestConfirm}
                                >
                                    <p className={classes.footerButtonTextConfirm}>
                                        {!textButtonConfirm ? 'Confirmar' : textButtonConfirm}
                                    </p>
                                </button>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default Modal;