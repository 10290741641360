import { AppleLogo, FacebookLogo, GooglePlayLogo, InstagramLogo, TwitterLogo } from '@phosphor-icons/react';
import classes from './style.module.css';

const Footer = () => {
    return (
        <div className={classes.container}>
            <div className={classes.containerInfo}>
                <div className={classes.containerColumn}>
                    <p className={classes.title}>Sobre</p>
                    <a href='/quem-somos' className={classes.subTitle}>Quem somos</a>
                    <a href='/politica-de-privacidade' className={classes.subTitle}>Política de privacidade</a>
                    <a href='/termos-de-uso' className={classes.subTitle}>Termos de uso</a>
                    <a href='/anuncie' className={classes.subTitle}>Anuncie</a>
                    <a href='/contato' className={classes.subTitle}>Contato</a>
                </div>
                <div className={classes.containerColumn}>
                    <p className={classes.title}>Rede</p>
                    <a href='https://www.instagram.com/letscookoriginal/' target="_blank" className={classes.contentApp}>
                        <InstagramLogo size={20} className={classes.icon} color='#FFFFFF' />
                        <p className={classes.subTitle}>Instagram</p>
                    </a>
                    <a href='https://twitter.com/letscookoficial' target="_blank" className={classes.contentApp}>
                        <TwitterLogo size={20} className={classes.icon} color='#FFFFFF' />
                        <p className={classes.subTitle}>Twitter</p>
                    </a>
                    <a href='https://facebook.com/letscookoriginal' target="_blank" className={classes.contentApp}>
                        <FacebookLogo size={20} className={classes.icon} color='#FFFFFF' />
                        <p className={classes.subTitle}>Facebook</p>
                    </a>
                </div>
                <div className={classes.containerColumn}>
                    <p className={classes.title}>Aplicativo</p>
                    <a href='https://play.google.com/store/apps/details?id=com.digitalmovel.LetsCook' target="_blank" className={classes.contentApp}>
                        <GooglePlayLogo size={20} className={classes.icon} color='#FFFFFF' />
                        <p className={classes.subTitle}>Google Play</p>
                    </a>
                    <a href='https://apps.apple.com/br/app/lets-cook/id6466206861' className={classes.contentApp}>
                        <AppleLogo size={20} className={classes.icon} color='#FFFFFF' />
                        <p className={classes.subTitle}>App Store</p>
                    </a>
                </div>
            </div>
            <div className={classes.containerCopyright}>
                <p className={classes.textCopyright}>
                    Desenvolvido por
                </p>
                <p className={classes.textCopyrightEmphasis}>
                    Adrian Salomon Ferreira Abdesalam
                </p>
            </div>
        </div>
    )
}

export default Footer;