import { v4 as uuidv4 } from 'uuid';

import { RecipeCreateDTO } from "../dto/RecipeCreateDTO";
import { ResponseDTO } from "../dto/ResponseDTO";
import { IngredientDTO } from '../dto/IngredientDTO';

import { api } from "../services/apiClient";

async function newRecipe(recipe: RecipeCreateDTO) {
    let response: ResponseDTO = {} as ResponseDTO;
    let urlPhoto: string = '';
    let id = uuidv4();

    if (!response.statusCode) {
        if (!recipe.title) {
            response = {
                statusCode: 406,
                message: 'Preencha o nome da receita!'
            }
        }
    }

    if (!response.statusCode) {
        if (!recipe.time) {
            response = {
                statusCode: 406,
                message: 'Preencha o tempo de preparo da receita!'
            }
        }
    }

    if (!response.statusCode) {
        if (!recipe.portions) {
            response = {
                statusCode: 406,
                message: 'Preencha o número de porções!'
            }
        }
    }

    if (!response.statusCode) {
        if (!recipe.photo.name) {
            response = {
                statusCode: 406,
                message: 'Insira uma foto da receita!'
            }
        }
    }

    if (!response.statusCode) {
        if (Object.keys(recipe.categories).length == 0) {
            response = {
                statusCode: 406,
                message: 'Selecione uma categoria!'
            }
        }
    }

    if (!response.statusCode) {
        if (Object.keys(recipe.ingredients).length < 1) {
            response = {
                statusCode: 406,
                message: 'Selecione ao menos um ingrediente!'
            }
        }
    }

    if (!response.statusCode) {
        if (Object.keys(recipe.modePreparation).length == 0) {
            response = {
                statusCode: 406,
                message: 'Descreva o modo de preparo!'
            }
        }
    }

    // MONTA E FAZ O UPLOAD DA FOTO
    if (!response.statusCode) {
        if (!!recipe.photo.name) {
            const photoFile = new FormData();
            photoFile.append('file', recipe.photo as any);

            await api.post('/upload/recipe/photo', photoFile, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((result) => {
                if (result.status !== 201) {
                    response = {
                        statusCode: 500,
                        message: 'Falha de comunicação no servidor de imagens!'
                    }
                }
                urlPhoto = result.data.url;
            }).catch((err) => {
                response = {
                    statusCode: 500,
                    message: 'Arquivo inválido! Tamanho máximo 5MB e formatos aceitos: jpg, jpeg, png!'
                }
            });
        }
    }

    // REGISTRA A NOVA RECEITA
    if (!response.statusCode) {
        await api.post('/recipe/create', {
            id,
            photo: urlPhoto,
            title: recipe.title,
            time: recipe.time,
            portions: recipe.portions,
            categories: recipe.categories,
            ingredients: recipe.ingredients,
            modePreparation: recipe.modePreparation
        }).then((result) => {
            response = {
                statusCode: 201,
                message: 'Receita criada com sucesso!'
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao criar a receita!'
            }
        });
    }

    return response
}

async function viewRecipe(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/recipe/view', {
            params: {
                id: id
            }
        }).then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data[0]
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function createViewRecipe(idRecipe: string, idUser?: string) {
    let response: ResponseDTO = {} as ResponseDTO;
    const device = {
        applicationVersion: 'web',
        modelName: navigator.userAgent,
        deviceName: '',
        osVersion: ''
    };

    try {
        await api.post('/recipe/createView', {
            idUser: idUser,
            idRecipe: idRecipe,
            device
        }).then((result) => {
            if (result.status === 201) {
                response = {
                    statusCode: 201
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }
        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAll() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/recipe/listAll').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAllFavorites(idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/recipe/listAllFavorites', {
            params: {
                idUser: idUser
            }
        }).then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listTopView() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/recipe/listTopView').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listTopNew() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/recipe/listTopNew').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listTopEvaluation() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/recipe/listTopEvaluation').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listOfRegisteredRecipes() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/category/recipe/listOfRegisteredRecipes').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAllCategories() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/category/recipe/listAll').then((result) => {
            if (Object.keys(result.data).length > 0) {
                let newList = result.data.filter((item: any) => item.label !== 'Todas')

                response = {
                    statusCode: 200,
                    data: newList
                }
            }
        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAllCategoriesNewRecipe() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/category/recipe/new/listAll').then((result) => {
            if (Object.keys(result.data).length > 0) {
                let newList = result.data.filter((item: any) => item.label !== 'Todas')

                response = {
                    statusCode: 200,
                    data: newList
                }
            }
        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAllMeasure() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/measure/listAll').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma medida encontrada!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listRecipesWithMyIngredients(ingredients: IngredientDTO[]) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.post('/recipe/listWithMyIngredients', ingredients
        ).then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }
        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response
}

async function findRecipesByCategoryId(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/recipe/findByCategoryId', {
            params: {
                id: id
            }
        }).then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma receita encontrada!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function findRecipesByTitle(text: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/recipe/findByTitle', {
            params: {
                search: text
            }
        }).then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma receita encontrada!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function createEvaluation(value: number, idRecipe: string, idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    const retorno = await evaluationConsultation(idRecipe, idUser);

    if (Object.keys(retorno.data).length === 0) {

        try {
            await api.post('/recipe/createEvaluation', {
                idRecipe,
                idUser,
                value
            }).then((result) => {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }).catch(() => {
                response = {
                    statusCode: 400,
                    message: 'Falha ao criar a avaliação!'
                }
            });
        } catch {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        }
    } else {
        response = {
            statusCode: 200,
            data: 'Receita já foi avaliada!'
        }
    }

    return response
}

async function evaluationConsultation(idRecipe: string, idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.post('/recipe/evaluationConsultation', {
            idRecipe,
            idUser
        }).then((result) => {
            response = {
                statusCode: 200,
                data: result.data
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao consultar avaliação!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response
}

async function updateEvaluation(value: number, idRecipe: string, idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.put('/recipe/updateEvaluation', {
            idRecipe,
            idUser,
            value
        }).then((result) => {
            response = {
                statusCode: 200,
                data: result.data
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao criar a avaliação!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response
}

async function createFavorite(idRecipe: string, idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    const retorno = await favoriteConsultation(idRecipe, idUser);

    if (Object.keys(retorno.data).length === 0) {

        try {
            await api.post('/recipe/createFavorite', {
                idRecipe,
                idUser
            }).then((result) => {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }).catch(() => {
                response = {
                    statusCode: 400,
                    message: 'Falha ao criar a favorite!'
                }
            });
        } catch {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        }
    } else {
        response = {
            statusCode: 200,
            data: 'Receita já foi favoritada!'
        }
    }

    return response
}

async function favoriteConsultation(idRecipe: string, idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.post('/recipe/favoriteConsultation', {
            idRecipe,
            idUser
        }).then((result) => {
            response = {
                statusCode: 200,
                data: result.data
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao consultar favorito!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response
}

async function deleteFavorite(idRecipe: string, idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.delete('/recipe/deleteFavorite', {
            params: {
                idRecipe,
                idUser
            }
        }).then((result) => {
            response = {
                statusCode: 200,
                data: result.data
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao deletar favorito!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response
}


export {
    newRecipe,
    listAll,
    listAllFavorites,
    listTopView,
    listTopNew,
    listTopEvaluation,
    viewRecipe,
    createViewRecipe,
    listOfRegisteredRecipes,
    listAllCategories,
    listAllCategoriesNewRecipe,
    listAllMeasure,
    listRecipesWithMyIngredients,
    findRecipesByCategoryId,
    findRecipesByTitle,
    createEvaluation,
    updateEvaluation,
    evaluationConsultation,
    createFavorite,
    favoriteConsultation,
    deleteFavorite
}