import ButtonPrimary from '../../../components/ButtonPrimary';
import HeaderNew from '../../../components/HeaderNew';

import classes from './style.module.css'

const Plans = () => {
    
    return (
        <body>
            <div className={classes.container}>
                <div className={classes.containerHeader}>
                    <div className={classes.contentHeader}>
                        <HeaderNew />
                    </div>
                </div>
                <div className={classes.containerBody}>
                    <div className={classes.contentBody}>
                        <p className={classes.title}>Meu Plano</p>
                        <div className={classes.plansContainer}>
                            <CardPlan
                                active={true}
                                amount='Grátis'
                                title="Ajudante"
                                description={`Ingredientes salvos por 7 dias. \nConta logada por 7 dias.`}
                            />
                        </div>
                        {/* <p className={classes.title}>Planos</p>
                        <div className={classes.plansContainer}>
                            <CardPlan
                                active={false}
                                amount='R$ 9,90'
                                title="Cozinheiro"
                                description={`Ingredientes salvos por 365 dias. \nConta logada por 356 dias. \nLista de mercado.`}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </body >
    )
}

interface ICardPlan {
    active: boolean;
    amount: string;
    title: string;
    description: string;
}

function CardPlan({ active, amount, title, description }: ICardPlan) {
    return (
        <div className={classes.cardContainer}>
            <div className={classes.cardHeader}>
                <p className={classes.cardHeaderText}>{amount}</p>
            </div>

            <p className={classes.cardTitle}>{title}</p>
            <p className={classes.cardDescription}>{description}</p>

            {!active &&
                <ButtonPrimary
                    text="Ativar"
                    onClick={() => { }}
                    marginTop={false}
                />
            }
        </div>
    )
}

export default Plans;