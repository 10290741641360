import { InputHTMLAttributes, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BookBookmark, Books, BugBeetle, Campfire, CaretUp, Carrot, Clock, CursorClick, Detective, HandHeart, IconContext, IdentificationBadge, MagnifyingGlass, Megaphone, Plus, Question, ShootingStar, SignOut, Timer, X } from "@phosphor-icons/react";
import { parseCookies } from "nookies";

import Header from "../../components/Header";
import Input from "../../components/Input";
import CardRecipe from "../../components/CardRecipe";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import CardCategoryRecipe from "../../components/CardCategoryRecipe";
import CardNotFound from "../../components/CardNotFound";

import { RecipeDTO } from "../../dto/RecipeDTO";
import { RecipeCategoryDTO } from "../../dto/RecipeCategoryDTO";

import classes from './style.module.css';

import { findRecipesByCategoryId, findRecipesByTitle, listAllCategories, listTopEvaluation, listTopView, listAllFavorites, listTopNew } from "../../dao/RecipeDAO";

import { isAdmin } from "../../hooks/isAdmin";
import { useAuth } from "../../hooks/auth";
import CardRecipeHorizontal from "../../components/CardRecipeHorizontal";
import LoadingTransparent from "../../components/LoadingTransparent";
import HeaderHome from "../../components/HeaderHome";

import imageHamburguer from '../../images/hamburguer.png';
import notFoundRecipe from '../../images/vetor-error-404.jpg';
import { converterMinutes } from "../../hooks/converterMinutes";
import { listNotification } from "../../dao/UserDAO";
import { NotificationDTO } from "../../dto/Notification";

const Home = () => {
    const { '@letscook.user': tokenAdmin } = parseCookies();
    const { logoutUser, user } = useAuth();
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false)
    const [listIngredientsUser, setListIngredientsUser] = useState([]);
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);
    const [isVisibleModalLogin, setIsVisibleModalLogin] = useState(false);
    const [listAllFavoritesRecipes, setListAllFavoritesRecipes] = useState<RecipeDTO[]>([]);
    const [numberNotifications, setNumberNotifications] = useState(0);
    const [listTopViewRecipes, setListTopViewRecipes] = useState<RecipeDTO[]>([]);
    const [listTopNewRecipes, setListTopNewRecipes] = useState<RecipeDTO[]>([]);
    const [listTopEvaluationRecipes, setListTopEvaluationRecipes] = useState<RecipeDTO[]>([]);
    const [listRecipesFoundSearch, setListRecipesFoundSearch] = useState<RecipeDTO[]>([]);
    const [listCategories, setListCategories] = useState<RecipeCategoryDTO[]>([]);
    const [categorySelected, setCategorySelected] = useState<RecipeCategoryDTO>({} as RecipeCategoryDTO);
    const [textSearch, setTextSearch] = useState('');

    const [menuSelected, setMenuSelected] = useState<'Novidades' | 'Favoritas'>('Novidades');

    const scrollTop = () => {
        window.scrollTo(0, 0)
    }

    async function loadInitial() {
        const listIngredientsUserStorage = localStorage.getItem('@letscook.ingredients');
        if (listIngredientsUserStorage) {
            setListIngredientsUser(JSON.parse(listIngredientsUserStorage))
        }

        await Promise.all([loadListTopViewRecipes(), loadListTopNewRecipes(), loadListTopEvaluationRecipes(), loadListAllRecipeCategories(), loadListAllFavorites()])

        setLoading(false);
    }

    async function loadListAllFavorites() {
        if (user.id) {
            const result = await listAllFavorites(user.id);

            if (result.statusCode === 200) {
                setListAllFavoritesRecipes(result.data)
            }

            return result.data
        }
    }

    async function loadListNotifications() {
        if (user.id) {
            const result = await listNotification(user.id);

            if (result.statusCode === 200) {
                let count = 0
                result.data.map((item: NotificationDTO) => {
                    if (!item.isViewed) {
                        count++
                    }
                })
                setNumberNotifications(count)
            }

            return result.data
        }
    }

    async function loadListTopViewRecipes() {
        const result = await listTopView();

        if (result.statusCode === 200) {
            setListTopViewRecipes(result.data)
        }

        return result.data
    }

    async function loadListTopNewRecipes() {
        const result = await listTopNew();

        if (result.statusCode === 200) {
            setListTopNewRecipes(result.data)
        }

        return result.data
    }

    async function loadListTopEvaluationRecipes() {
        const result = await listTopEvaluation();

        if (result.statusCode === 200) {
            setListTopEvaluationRecipes(result.data)
        }

        return result.data
    }

    async function loadListAllRecipeCategories() {
        const result = await listAllCategories();

        if (result.statusCode === 200) {
            setListCategories(result.data)
        }

        return result.data
    }

    async function handleSearchRecipe(categoryId?: string) {
        if (textSearch.length > 0) {
            setLoadingSearch(true)
            setCategorySelected({} as RecipeCategoryDTO)
            const response = await findRecipesByTitle(textSearch);

            if (response.statusCode === 200) {
                setListRecipesFoundSearch(response.data)
            } else {
                setListRecipesFoundSearch([])
            }
            setLoadingSearch(false)
        } else if (categoryId) {
            setLoadingSearch(true)
            const response = await findRecipesByCategoryId(categoryId);

            if (response.statusCode === 200) {
                setListRecipesFoundSearch(response.data)
            }
            setLoadingSearch(false)
        } else {
            setListRecipesFoundSearch([])
        }

    }

    async function handleFindCategoryRecipe(category: RecipeCategoryDTO) {
        if (categorySelected.id === category.id) {
            setCategorySelected({} as RecipeCategoryDTO)
            setListRecipesFoundSearch([])
            setTextSearch('')
        } else {
            await handleSearchRecipe(category.id)
            setCategorySelected(category)
            setTextSearch('')
        }
    }

    useEffect(() => {
        handleSearchRecipe()
    }, [textSearch])

    useEffect(() => {
        loadListAllFavorites()
        loadListNotifications()
    }, [user.id])

    useEffect(() => {
        loadInitial()
    }, [])

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
        }
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    return (
        <>
            {loading ? <Loading text="Carregando receitas..." /> :
                <body>
                    {loadingSearch && <LoadingTransparent />}
                    <aside>
                        <div className={classes.header}>
                            <HeaderHome onClickLogin={() => navigate('/login')} notification={numberNotifications} />
                        </div>
                        <div className={classes.containerSearch}>
                            <div className={classes.contentSearch}>
                                <p className={classes.textSearch}>Encontre sua</p>
                                <p className={classes.textSearch}>Receita Favorita</p>

                                <InputSearch
                                    type="text"
                                    value={textSearch}
                                    onChange={(e) => setTextSearch(e.target.value)}
                                    clear={() => setTextSearch('')}
                                    placeholder="Pesquisar Aqui"
                                />
                                <img src={imageHamburguer} className={classes.photoSearch} />
                            </div>
                        </div>
                        <div className={classes.containerCategories}>
                            <p className={classes.title}>Categorias</p>
                            <div className={classes.contentCategories}>
                                {listCategories.map((category, index) => {
                                    return (
                                        <CardCategoryRecipe
                                            key={index}
                                            category={category}
                                            selected={category.id == categorySelected.id}
                                            onClick={() => handleFindCategoryRecipe(category)}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        <div className={classes.containerRecipes}>
                            {Object.keys(listRecipesFoundSearch).length === 0 && textSearch.length == 0 ?
                                <>
                                    <div className={classes.contentMenuTitle}>
                                        <button className={classes.contentMenuTitleButton} onClick={() => setMenuSelected('Novidades')}>
                                            <p className={menuSelected === 'Novidades' ? classes.titleSelected : classes.title}>Novidades</p>
                                        </button>
                                        {Object.keys(listAllFavoritesRecipes).length > 0 &&
                                            <button className={classes.contentMenuTitleButton} onClick={() => setMenuSelected('Favoritas')}>
                                                <p className={menuSelected === 'Favoritas' ? classes.titleSelected : classes.title}>Favoritas</p>
                                            </button>
                                        }
                                    </div>
                                    <div className={classes.contentRecipes}>
                                        {menuSelected === 'Favoritas' ?
                                            <>{listAllFavoritesRecipes.map((item, index) => <ButtonCardRecipe recipe={item} key={index} />)}</>
                                            :
                                            <>{listTopNewRecipes.map((item, index) => <ButtonCardRecipe recipe={item} key={index} />)}</>
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    {Object.keys(listRecipesFoundSearch).length == 0 ?
                                        <>
                                            {!loadingSearch &&
                                                <>
                                                    <p className={classes.title}>Resultado de "{textSearch}"</p>
                                                    <div className={classes.notFoundContent}>
                                                        <img src={notFoundRecipe} className={classes.notFoundImage} />
                                                        <p className={classes.notFoundText}>Nenhuma receita encontrada.</p>
                                                    </div>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <p className={classes.title}>{textSearch.length > 0 ? `Resultado de "${textSearch}"` : categorySelected.label}</p>
                                            <div className={classes.contentRecipes}>
                                                {listRecipesFoundSearch.map((item, index) => <ButtonCardRecipe recipe={item} key={index} />)}
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                        {scrollPosition < 10 &&
                            <div className={classes.menuBottomContainer}>
                                <ButtonBottom icon={<Carrot />} label="Selecionar meus ingredientes" onClick={() => navigate('/listIngredients')} />
                                <ButtonBottom icon={<Books />} label="Receitas com meus ingredientes" onClick={() => navigate('/viewListRecipes')} />
                            </div>
                        }
                        {scrollPosition > 100 &&
                            <ButtonScrollTop handleScrollTop={scrollTop} />
                        }
                    </aside>
                    <Footer />
                </body>
            }
        </>
    )
}

interface IInputSearch extends InputHTMLAttributes<HTMLInputElement> {
    clear: () => void;
    value: string;
}

function InputSearch({ clear, value, ...props }: IInputSearch) {
    return (
        <div className={classes.contentInput}>
            <div className={classes.inputIcon}>
                <MagnifyingGlass size={32} color='#FF6E00' />
            </div>
            <input value={value} {...props} className={classes.input} />
            {value.length > 0 &&
                <button className={classes.inputClear} onClick={clear}>
                    <X size={20} color='#FF6E00' />
                </button>
            }
        </div>
    )
}

interface IButtonCardRecipe {
    recipe: RecipeDTO;
    column?: boolean;
}

function ButtonCardRecipe({ recipe, column }: IButtonCardRecipe) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    function handleViewRecipe(id: string) {
        navigate(`/viewRecipe/${id}`)
    }

    return (
        <button className={classes.buttonRecipeContent} onClick={() => handleViewRecipe(recipe.id)}>
            {loading && <div className={classes.buttonRecipeContentLoading} />}
            <div className={classes.buttonRecipeTimeContent}>
                <Clock size={16} color="#FF6E00" weight="bold" />
                <p className={classes.buttonRecipeTimeText}>{converterMinutes(recipe.time)}</p>
            </div>
            <img src={recipe.photo} className={classes.buttonRecipePhoto} onLoad={() => setLoading(false)} />
            <p className={classes.buttonRecipeTitle}>{recipe.title}</p>
        </button>
    )
}

interface IButtonBottom extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: any;
    label: string;
}

function ButtonBottom({ icon, label, ...rest }: IButtonBottom) {
    return (
        <button className={classes.menuBottomButton} {...rest}>
            <IconContext.Provider
                value={{ size: 35, color: '#FFFFFF' }}
            >
                {icon}
            </IconContext.Provider>
            <p className={classes.menuBottomButtonText}>
                {label}
            </p>
        </button>
    )
}

interface IButtonScrollTop {
    handleScrollTop: () => void;
}

function ButtonScrollTop({ handleScrollTop }: IButtonScrollTop) {
    return (
        <button
            className={classes.containerButtonScrollTop}
            onClick={handleScrollTop}
        >
            <CaretUp size={40} color='#FFFFFF' />
        </button>
    )
}


export default Home;