import { Navigate } from "react-router-dom";
import { parseCookies } from "nookies";
import { isAdmin } from "../hooks/isAdmin";

export const ProtectedRouteAdmin = ({ children }: any) => {
  const { '@letscook.user': user } = parseCookies();

  if (!isAdmin(user)) {
    return <Navigate to="/error/404" />;
  }
  
  return children;
};