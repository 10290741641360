import { IconContext } from "@phosphor-icons/react";
import { InputHTMLAttributes, useEffect, useState } from "react";

import classes from './style.module.css';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
    icon: any;
    value: string;
    marginTop?: boolean;
    marginBottom?: boolean;
    shadow?: boolean;
}

const Textarea = ({
    icon,
    marginTop,
    marginBottom,
    value,
    shadow = false,
    ...props
}: Props) => {
    const [isFocused, setIsFocused] = useState(false);

    function handleInputFocus() {
        setIsFocused(!isFocused)
    }

    return (
        <div className={`
                ${classes.container}
                ${marginTop && classes.marginTop}
                ${marginBottom && classes.marginBottom}
                ${shadow && classes.shadow}
            `}
        >
            <div className={classes.input}>
                <div className={classes.contentIcon}>
                    <IconContext.Provider
                        value={{
                            size: 32,
                            color: isFocused || !!value ? '#FF6E00' : '#AEAEB3'
                        }}
                    >
                        {icon}
                    </IconContext.Provider>
                </div>
                <textarea
                    onFocus={handleInputFocus}
                    onBlur={handleInputFocus}
                    className={classes.textarea}
                    value={value}
                    {...props}
                />
            </div>
            {
                (isFocused || !!value) &&
                <div className={classes.line} />
            }
        </div>
    )
}

export default Textarea;