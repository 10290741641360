import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowsClockwise, ArticleMedium, CallBell, Camera, Clock, Flask, ForkKnife, IconContext, MagnifyingGlass, Medal, PuzzlePiece, Star, TextAlignJustify } from '@phosphor-icons/react';

import ButtonPrimary from '../../components/ButtonPrimary';
import ButtonSecondary from '../../components/ButtonSecondary';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Input from '../../components/Input';
import ButtonIngredient from '../../components/ButtonIngredient';
import CardRecipeItem from '../../components/CardRecipeItem';
import Textarea from '../../components/Textarea';
import CardModePreparation from '../../components/CardModePreparation';
import Loading from '../../components/Loading';
import ButtonNeutro from '../../components/ButtonNeutro';
import CardCategoryRecipe from '../../components/CardCategoryRecipe';
import Modal from '../../components/Modal';
import Checkbox from '../../components/Checkbox';

import HeartSuit from '../../images/Figmoji/heart-suit.png';
import Cooking from '../../images/Figmoji/cooking.png';
import Watch from '../../images/Figmoji/watch.png';

import { useAlert } from '../../hooks/alert';
import { removeAccents } from '../../hooks/removeAccents';

import { listAll } from '../../dao/IngredientDAO';
import { listAllCategoriesNewRecipe, listAllMeasure, newRecipe } from '../../dao/RecipeDAO';

import { IngredientCategoryDTO } from '../../dto/IngredientCategoryDTO';
import { IngredientDTO } from '../../dto/IngredientDTO';
import { RecipeMeasureDTO } from '../../dto/RecipeMeasureDTO';
import { IngredientRecipeDTO } from '../../dto/IngredientRecipeDTO';
import { RecipeCategoryDTO } from '../../dto/RecipeCategoryDTO';
import { RecipeModePreparationDTO } from '../../dto/RecipeModePreparationDTO';

import classes from './style.module.css';
import { converterMinutes } from '../../hooks/converterMinutes';
import { validateRecipeTitle } from '../../dao/AdministratorDAO';

interface IGroupDragDropIngredient {
    title: string;
    itens: IngredientRecipeDTO[]
    ;
}

interface IGroupDragDropModePreparation {
    title: string;
    itens: RecipeModePreparationDTO[];
}

const NewRecipeOld = () => {
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonSelected, setButtonSelected] = useState<'INGREDIENTES' | 'MODO DE PREPARO'>('INGREDIENTES');
    const [title, setTitle] = useState('');
    const [titleWarning, setTitleWarning] = useState(false);
    const [time, setTime] = useState('');
    const [portions, setPortions] = useState('');
    const [categories, setCategories] = useState<RecipeCategoryDTO[]>([]);
    const [photo, setPhoto] = useState<File>({} as File);
    const [photoUrl, setPhotoUrl] = useState('');
    const [textSearch, setTextSearch] = useState('');
    const [listAllCategories, setListAllCategories] = useState<any[]>([]);
    const [listAllIngredients, setListAllIngredients] = useState<IngredientCategoryDTO[]>([]);
    const [listIngredientsSelected, setListIngredientsSelected] = useState<IngredientRecipeDTO[]>([]);
    const [listAllMeasures, setListAllMeasures] = useState<RecipeMeasureDTO[]>([]);
    const [listIngredientsFoundSearch, setListIngredientsFoundSearch] = useState<IngredientDTO[]>([]);
    const [selectedIngredients, setSelectedIngredients] = useState<IngredientDTO>({} as IngredientDTO);
    const [complement, setComplement] = useState('');
    const [amount, setAmount] = useState('');
    const [measure, setMeasure] = useState<RecipeMeasureDTO>({} as RecipeMeasureDTO);
    const [listMethodOfPreparation, setListMethodOfPreparation] = useState<RecipeModePreparationDTO[]>([])
    const [textMethodOfPreparation, setTextMethodOfPreparation] = useState('');

    const [visibleModalDragDrop, setVisibleModalDragDrop] = useState(false)
    const [titleDragDrop, setTitleDragDrop] = useState('')

    const [groupDragDropModePreparation, setGroupDragDropModePreparation] = useState<IGroupDragDropModePreparation[]>([]);
    const [groupDrapDropModePreparationSelected, setGroupDrapDropModePreparationSelected] = useState('')

    const [groupDragDropIngredient, setGroupDragDropIngredient] = useState<IGroupDragDropIngredient[]>([]);
    const [groupDrapDropSelected, setGroupDrapDropSelected] = useState('')

    function addGroupDragDrop(type: string) {
        if (type === 'INGREDIENTES') {
            let newGroup: IGroupDragDropIngredient;
            if (Object.keys(groupDragDropIngredient).length === 0) {
                newGroup = {
                    title: titleDragDrop,
                    itens: listIngredientsSelected
                }
            } else {
                newGroup = {
                    title: titleDragDrop,
                    itens: []
                }
            }
            setGroupDragDropIngredient(old => [...old, newGroup])
            setListIngredientsSelected([])
        }
        if (type === 'MODO DE PREPARO') {
            let newGroup: IGroupDragDropModePreparation;
            if (Object.keys(groupDragDropModePreparation).length === 0) {
                newGroup = {
                    title: titleDragDrop,
                    itens: listMethodOfPreparation
                }
            } else {
                newGroup = {
                    title: titleDragDrop,
                    itens: []
                }
            }
            setGroupDragDropModePreparation(old => [...old, newGroup])
            setListMethodOfPreparation([])
        }

        closeModalDragDrop()
    }

    function closeModalDragDrop() {
        setTitleDragDrop('')
        setVisibleModalDragDrop(false)
    }

    const handleChangePhoto = (e: any) => {
        const selectedFile = e.target.files[0];
        const objectUrl = URL.createObjectURL(selectedFile)

        setPhotoUrl(objectUrl)
        setPhoto(selectedFile)
    };

    const handleInputPhotoClick = () => {
        const input = document.getElementById('file-input');

        if (input) {
            input.click();
        }
    };

    function handleSelectedIngredient(ingredient: IngredientDTO) {
        setTextSearch('');
        setSelectedIngredients(ingredient);
        setListIngredientsFoundSearch([]);
    }

    function handleSaveIngredients() {

        if (selectedIngredients.title.length === 0) {
            return showAlert({
                message: 'Selecione um ingrediente.',
                type: 'error'
            })
        }

        if (measure.label.length === 0) {
            return showAlert({
                message: 'Selecione uma medida.',
                type: 'error'
            })
        }

        if (measure.label !== 'a gosto') {
            if (amount.length === 0) {
                return showAlert({
                    message: 'Digite a quantidade.',
                    type: 'error'
                })
            }
        }

        const ingredient = {
            id: selectedIngredients.id,
            title: selectedIngredients.title,
            photo: selectedIngredients.photo,
            amount: amount,
            measure: {
                id: measure.id,
                label: measure.label,
            },
            complement: complement
        };

        if (Object.keys(groupDragDropIngredient).length > 0) {
            if (groupDrapDropSelected.length === 0) {
                return showAlert({
                    message: 'Selecione a etapa do ingrediente.',
                    type: 'error'
                })
            }

            const listGroup = groupDragDropIngredient;
            const index = listGroup.findIndex((group) => group.title == groupDrapDropSelected);
            let groupIngredients = listGroup[index].itens;
            groupIngredients.push(ingredient)

            if (index !== -1) {
                const newGroup = {
                    title: listGroup[index].title,
                    itens: groupIngredients
                }

                listGroup[index] = newGroup;
            }

            setGroupDragDropIngredient(listGroup)
            setGroupDrapDropSelected('')
        } else {
            setListIngredientsSelected(old => [...old, ingredient])
        }
        setAmount('')
        setComplement('')
        setSelectedIngredients({} as IngredientDTO)
        setMeasure({} as RecipeMeasureDTO)

        document.getElementById('refSearch')?.focus();

        return showAlert({
            message: 'Ingrediente salvo na lista.',
            type: 'success'
        })
    }

    function handleRemoveIngredient(ingredient: IngredientRecipeDTO, group: string, position: number) {
        if (Object.keys(groupDragDropIngredient).length > 0) {
            const listGroup = groupDragDropIngredient;
            const index = listGroup.findIndex((item) => item.title === group);
            const groupIngredients = listGroup[index].itens;
            const newGroupIngredients = groupIngredients.filter((item, index) => (item.id !== ingredient.id || index !== position))

            if (index !== -1) {
                const newGroup = {
                    title: listGroup[index].title,
                    itens: newGroupIngredients
                }

                listGroup[index] = newGroup;
                setGroupDragDropIngredient(listGroup)
                setListIngredientsSelected([])
            }
        } else {
            setListIngredientsSelected(listIngredientsSelected.filter((item) => item.id !== ingredient.id))
        }

        document.getElementById('refSearch')?.focus();
    }

    function handleCancelIngredient() {
        setAmount('')
        setComplement('')
        setSelectedIngredients({} as IngredientDTO)
        setMeasure({} as RecipeMeasureDTO)
    }

    function handleSaveMethodOfPreparation() {
        if (textMethodOfPreparation.length === 0) {
            return showAlert({
                message: 'Descreve a etapa de preparo.',
                type: 'error'
            })
        }

        const array = textMethodOfPreparation.split('\n')
        array.map((line, key) => {

            if (Object.keys(groupDragDropModePreparation).length > 0) {
                if (groupDrapDropModePreparationSelected.length === 0) {
                    return showAlert({
                        message: 'Selecione a etapa do modo de preparo.',
                        type: 'error'
                    })
                }

                const listGroup = groupDragDropModePreparation;
                const index = listGroup.findIndex((group) => group.title == groupDrapDropModePreparationSelected);
                let groupModePreparation = listGroup[index].itens;
                const length = Object.keys(groupModePreparation).length + 1
                groupModePreparation.push({ position: length, description: line })

                if (index !== -1) {
                    const newGroup = {
                        title: listGroup[index].title,
                        itens: groupModePreparation
                    }

                    listGroup[index] = newGroup;
                }

                setGroupDragDropModePreparation(listGroup)
                setGroupDrapDropModePreparationSelected('')
            } else {
                const length = Object.keys(listMethodOfPreparation).length + 1

                setListMethodOfPreparation(old => [...old, {
                    position: length + key,
                    description: line
                }])
            }
        })

        setTextMethodOfPreparation('');

        document.getElementById('refMethodOfPreparation')?.focus();

        return showAlert({
            message: 'Modo de preparado adicionado.',
            type: 'success'
        })
    }

    function handleRemoveMethodOfPreparation(method: RecipeModePreparationDTO, group?: string) {
        if (Object.keys(groupDragDropModePreparation).length > 0) {
            const listGroup = groupDragDropModePreparation;
            const index = listGroup.findIndex((item) => item.title === group);
            const groupModePreparation = listGroup[index].itens;
            const newGroupModePreparation = groupModePreparation.filter((item) => item.position !== method.position)
            const newGroupModePreparationNewPosition = newGroupModePreparation.map((item, index) => { return { position: index + 1, description: item.description } })

            if (index !== -1) {
                const newGroup = {
                    title: listGroup[index].title,
                    itens: newGroupModePreparationNewPosition
                }

                listGroup[index] = newGroup;
                setGroupDragDropModePreparation(listGroup)
                setListMethodOfPreparation([])
            }
        } else {
            setListMethodOfPreparation(listMethodOfPreparation.filter((item) => item.position !== method.position))
        }

        document.getElementById('refMethodOfPreparation')?.focus();
    }

    async function handleContinue() {
        let response = '';

        if (step === 0) { response = await validateStep0() }

        if (response === '') {
            setStep(step + 1)
        } else {
            showAlert({
                type: 'error',
                message: response
            })
        }
    }

    async function validateStep0() {
        if (photoUrl.length === 0) {
            return 'Selecione uma foto!';
        }
        if (title.length < 2) {
            return 'Título inválido!';
        }
        if (Object.keys(categories).length === 0) {
            return 'Selecione uma categoria!';
        }
        if (time.length === 0 || Number(time) < 0 || Number(time) === 0) {
            return 'Tempo inválido!';
        }
        if (portions.length === 0 || Number(portions) < 0 || Number(portions) === 0) {
            return 'Porções inválida!';
        }
        return ''
    }

    function handleBack() {
        setStep(step - 1)
    }

    function handleFoundSearch() {
        if (textSearch.length > 2) {
            setSelectedIngredients({} as IngredientDTO)
            let ingredients: IngredientDTO[] = [];

            listAllIngredients.map(category => {
                category.ingredients !== null &&
                    category.ingredients?.find(item => {
                        if (removeAccents(item.title).toUpperCase().includes(removeAccents(textSearch).toUpperCase())) {
                            ingredients.push(item);
                        }
                        if (item.tag) {
                            if (removeAccents(item.tag).toUpperCase().includes(removeAccents(textSearch).toUpperCase())) {
                                let find = ingredients.findIndex(obj => obj.id == item.id);
                                if (find == -1) {
                                    ingredients.push(item);
                                }
                            }
                        }
                    })
            })

            setListIngredientsFoundSearch(ingredients);
        }
    };

    function handleSelectedButton() {
        if (buttonSelected === 'INGREDIENTES') {
            setButtonSelected('MODO DE PREPARO');
            document.getElementById('refMethodOfPreparation')?.focus();
        } else {
            setButtonSelected('INGREDIENTES');
            document.getElementById('refSearch')?.focus();
        }
    }

    async function handleSaveRecipe() {
        setButtonLoading(true)

        let ingredients = listIngredientsSelected;
        let modePreparation = listMethodOfPreparation;

        if (Object.keys(groupDragDropIngredient).length > 0) {
            if (Object.keys(groupDragDropIngredient).length === 1) {
                ingredients = groupDragDropIngredient[0].itens

            } else {
                groupDragDropIngredient.map(group => group.itens.map(item => {
                    let ingredient: any = item
                    ingredient.step = group.title
                    ingredients.push(ingredient)
                }))
            }
        }

        if (Object.keys(groupDragDropModePreparation).length > 0) {
            if (Object.keys(groupDragDropModePreparation).length === 1) {
                modePreparation = groupDragDropModePreparation[0].itens
            } else {
                groupDragDropModePreparation.map(group => group.itens.map(item => {
                    let mode: any = item
                    mode.step = group.title
                    modePreparation.push(mode)
                }))
            }
        }

        const response = await newRecipe({
            title,
            time,
            portions,
            photo,
            categories,
            ingredients,
            modePreparation
        })

        if (response.statusCode === 201) {
            await showAlert({
                message: 'Receita criada com sucesso.',
                type: 'success'
            })
            return navigate(-1)
        }

        if (response.statusCode === 500) {
            await showAlert({
                message: response.message ? response.message : 'Falha ao criar a receita.',
                type: 'warning'
            })
        } else {
            await showAlert({
                message: response.message ? response.message : 'Preencha corretamente os dados.',
                type: 'error'
            })
        }

        setButtonLoading(false)
    }

    async function loadListAllIngredients() {
        const result = await listAll();

        if (result.statusCode === 200) {
            setListAllIngredients(result.data)
        } else {
            navigate(-1)
            return showAlert({
                message: 'Falha ao carregar os dados.',
                type: 'error'
            })
        }
    }

    async function loadingsetListMeasures() {
        const result = await listAllMeasure();

        if (result.statusCode === 200) {
            setListAllMeasures(result.data)
        } else {
            navigate(-1)
            return showAlert({
                message: 'Falha ao carregar os dados.',
                type: 'error'
            })
        }

        setLoading(false)
    }

    async function loadingListAllCategories() {
        const result = await listAllCategoriesNewRecipe();

        if (result.statusCode === 200) {
            setListAllCategories(result.data)
        } else {
            navigate(-1)
            return showAlert({
                message: 'Falha ao carregar os dados.',
                type: 'error'
            })
        }
    }

    function handleSelectedCategory(category: RecipeCategoryDTO) {
        let index = categories.findIndex(obj => obj == category);

        if (index === -1) {
            setCategories([...categories, category])
        } else {
            setCategories(categories.filter((item) => item.id !== category.id))
        }
    }

    function handleSelectedMeasure(measure: RecipeMeasureDTO) {
        setMeasure(measure);
        if (measure.label === 'a gosto') {
            document.getElementById('refComplement')?.focus();
        } else {
            document.getElementById('refAmount')?.focus();
        }
    }

    async function validateTitle() {
        const response = await validateRecipeTitle(title);

        if (response.statusCode === 200) {
            setTitleWarning(true)
        } else {
            setTitleWarning(false)
        }
    }

    useEffect(() => {
        handleFoundSearch()
    }, [textSearch]);

    useEffect(() => {
        loadingListAllCategories()
        loadListAllIngredients()
        loadingsetListMeasures()
    }, [])

    return (
        <>
            {loading ? <Loading text="Carregando informações..." /> :
                <div className={classes.container}>
                    <div className={classes.body}>
                        {/* FOTO */}
                        <button
                            className={classes.containerPhoto}
                            onClick={handleInputPhotoClick}
                        >
                            <input
                                type="file"
                                id="file-input"
                                style={{ display: "none" }}
                                onChange={e => handleChangePhoto(e)}
                            />
                            {photoUrl !== '' ?
                                <img src={photoUrl} className={classes.photo} />
                                :
                                <>
                                    <Camera size={32} color='#FFFFFF' />
                                    <p className={classes.textPhoto}>
                                        Selecionar a Foto da Receita
                                    </p>
                                </>
                            }
                        </button>
                        {/* INFORAMAÇÕES DA RECEITA */}
                        {step > 0 &&
                            <div className={classes.containerInfo}>
                                <div className={classes.containerTitle}>
                                    <p className={classes.title}>{title}</p>
                                </div>
                                <div className={classes.containerDetails}>
                                    <InfoDetails icon={<Star />} text={String('5.0')} />
                                    <InfoDetails icon={<Clock />} text={converterMinutes(time)} />
                                    <InfoDetails icon={<CallBell />} text={String(portions)} />
                                </div>
                            </div>
                        }
                        {/* DADOS DA RECEITA */}
                        {step === 0 &&
                            <div className={classes.containerForm}>
                                {titleWarning &&
                                    <p className={classes.textWarning}>Receita já existe no sistema!</p>
                                }
                                <Input
                                    icon={<Medal />}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    onBlur={validateTitle}
                                    placeholder='Titulo da Receita'
                                    marginBottom
                                />
                                <Input
                                    icon={<Clock />}
                                    value={time}
                                    onChange={(e) => setTime(e.target.value)}
                                    placeholder='Tempo de Preparo (minutos)'
                                    marginBottom
                                    type='number'
                                    min={0}
                                />
                                <Input
                                    icon={<ForkKnife />}
                                    value={portions}
                                    onChange={(e) => setPortions(e.target.value)}
                                    placeholder='Porções'
                                    marginBottom
                                    type='number'
                                    min={0}
                                />
                                <div className={classes.contentCategories}>
                                    <p className={classes.categoriasLabel}>Selecione a(s) categoria(s)</p>
                                    <div className={classes.containerCategories}>
                                        {listAllCategories.map((category, index) => {
                                            let selected = categories.findIndex(obj => obj == category);
                                            return (
                                                <CardCategoryRecipe
                                                    key={index}
                                                    category={category}
                                                    selected={selected >= 0}
                                                    onClick={() => handleSelectedCategory(category)}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                                <ButtonPrimary
                                    text='CONTINUAR'
                                    onClick={handleContinue}
                                />
                            </div>
                        }
                        <div className={classes.containerBody}>
                            {step > 0 &&
                                <>
                                    <div className={classes.contentCategoriesView}>
                                        <p className={classes.categoriasLabel}>Categorias:</p>
                                        <div className={classes.containerCategoriesView}>
                                            {categories.map((category, index) => {
                                                return (
                                                    <CardCategoryRecipe
                                                        key={index}
                                                        category={category}
                                                        selected={false}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={classes.containerDetailsRecipe}>
                                        <div className={classes.containerButton}>
                                            <button
                                                className={buttonSelected === 'INGREDIENTES' ? classes.buttonLeftSelected : classes.buttonLeft}
                                                onClick={handleSelectedButton}
                                            >
                                                INGREDIENTES
                                            </button>
                                            <button
                                                className={buttonSelected === 'MODO DE PREPARO' ? classes.buttonRightSelected : classes.buttonRight}
                                                onClick={handleSelectedButton}
                                            >
                                                MODO DE PREPARO
                                            </button>
                                        </div>
                                        {step === 1 &&
                                            <div className={classes.containerButtonGrupoDragDrop}>
                                                <ButtonNeutro
                                                    text='Adicionar etapa'
                                                    onClick={() => setVisibleModalDragDrop(true)}

                                                />
                                            </div>
                                        }
                                        {buttonSelected === 'INGREDIENTES' &&
                                            <>
                                                <div className={classes.containerGrupoDragDrop}>
                                                    {Object.keys(groupDragDropIngredient).length > 0 ?
                                                        (groupDragDropIngredient.map((group, index) => {
                                                            return (
                                                                <>
                                                                    <p className={classes.titleGrupoDragDrop} key={index}>{group.title}</p>
                                                                    <div className={classes.containerIngredients}>
                                                                        {group.itens.map((ingredient, index) => {
                                                                            return (
                                                                                <>
                                                                                    {step === 1 ?
                                                                                        <CardRecipeItem
                                                                                            key={index}
                                                                                            ingredient={ingredient}
                                                                                            buttonRemove={() => handleRemoveIngredient(ingredient, group.title, index)}
                                                                                        /> :
                                                                                        <CardRecipeItem
                                                                                            key={index}
                                                                                            ingredient={ingredient}
                                                                                        />
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </>
                                                            )
                                                        }))
                                                        :
                                                        <div className={classes.containerIngredients}>
                                                            {listIngredientsSelected.map((ingredient, index) => {
                                                                return (
                                                                    <>
                                                                        {step === 1 ?
                                                                            <CardRecipeItem
                                                                                key={index}
                                                                                ingredient={ingredient}
                                                                                buttonRemove={() => handleRemoveIngredient(ingredient, '', index)}
                                                                            /> :
                                                                            <CardRecipeItem
                                                                                key={index}
                                                                                ingredient={ingredient}
                                                                            />
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                                {step === 1 &&
                                                    <div className={classes.containerModalInsert}>
                                                        <div className={classes.containerFormDetails}>
                                                            <Input
                                                                id='refSearch'
                                                                icon={<MagnifyingGlass />}
                                                                value={textSearch}
                                                                onChange={(e) => setTextSearch(e.target.value)}
                                                                placeholder='Pesquisar ingrediente'
                                                                spellCheck={false}
                                                            />
                                                            <button
                                                                className={classes.buttonModalInsert}
                                                                onClick={() => loadListAllIngredients()}
                                                            >
                                                                <ArrowsClockwise size={20} />
                                                            </button>
                                                        </div>
                                                        <div className={classes.containerListIngredients}>
                                                            {(Object.keys(listIngredientsFoundSearch).length > 0) ?
                                                                <>
                                                                    <div className={classes.containerIngredientTitle}>
                                                                        <p className={classes.ingredientTitle}>INGREDIENTE(S) ENCONTRADO(S)</p>
                                                                    </div>
                                                                    <div className={classes.containerIngredientsList}>
                                                                        {listIngredientsFoundSearch.map((ingredient, index) => {
                                                                            return (
                                                                                <ButtonIngredient
                                                                                    key={index}
                                                                                    ingredient={ingredient}
                                                                                    selected={false}
                                                                                    onClick={() => handleSelectedIngredient(ingredient)}
                                                                                />
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                </>
                                                                :
                                                                (textSearch.length > 2) &&
                                                                <div className={classes.containerNotFound}>
                                                                    <p className={classes.textNotFound}>
                                                                        NENHUM INGREDIENTE ENCONTRADO
                                                                    </p>
                                                                </div>
                                                            }
                                                            {(selectedIngredients?.id) &&
                                                                <div className={classes.containerSelected}>
                                                                    <div className={classes.containerSelectedItem}>
                                                                        <div className={classes.containerIngredientSelected}>
                                                                            <div className={classes.containerPhotoIngredientSelected}>
                                                                                <img
                                                                                    src={selectedIngredients.photo}
                                                                                    className={classes.photoIngredientSelected}
                                                                                />
                                                                            </div>
                                                                            <div className={classes.containerInfoIngredientSelected}>
                                                                                <p className={classes.titleIngredientSelected}>
                                                                                    {selectedIngredients.title} {complement && `- ${complement}`}
                                                                                </p>
                                                                                <p className={classes.textDetailsIngredientSelected}>
                                                                                    {amount} {measure.label}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={classes.containerSelectedForm}>
                                                                        <label className={classes.containerMeasuaresLabel}>Medida</label>
                                                                        <div className={classes.containerMeasuares}>
                                                                            {measure.id ?
                                                                                <div className={classes.containerMeasuaresItem}>
                                                                                    <button className={classes.buttonCloseMeasure} onClick={() => setMeasure({} as RecipeMeasureDTO)}>X</button>
                                                                                    <img src={measure.photo} className={classes.containerMeasuaresItemPhoto} />
                                                                                    <p className={classes.containerMeasuaresItemText}>{measure.label}</p>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    {listAllMeasures.map((item, index) => {
                                                                                        const selected = item.id === measure.id;
                                                                                        return (
                                                                                            <button className={selected ? classes.containerMeasuaresItemSelected : classes.containerMeasuaresItem} key={index} onClick={() => handleSelectedMeasure(item)}>
                                                                                                <img src={item.photo} className={classes.containerMeasuaresItemPhoto} />
                                                                                                <p className={selected ? classes.containerMeasuaresItemTextSelected : classes.containerMeasuaresItemText}>{item.label}</p>
                                                                                            </button>
                                                                                        )
                                                                                    })}
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        {(measure.label !== 'a gosto') &&
                                                                            <Input
                                                                                id='refAmount'
                                                                                icon={<Flask />}
                                                                                value={amount}
                                                                                onChange={(e) => setAmount(e.target.value)}
                                                                                onKeyDown={(e) => e.key === "Enter" && handleSaveIngredients()}
                                                                                placeholder='Quantidade'
                                                                                marginBottom
                                                                            />
                                                                        }
                                                                        <Input
                                                                            id='refComplement'
                                                                            icon={<PuzzlePiece />}
                                                                            value={complement}
                                                                            onChange={(e) => setComplement(e.target.value)}
                                                                            onKeyDown={(e) => e.key === "Enter" && handleSaveIngredients()}
                                                                            placeholder='Complemento'
                                                                            marginBottom
                                                                        />
                                                                        {Object.keys(groupDragDropIngredient).length > 0 &&
                                                                            <div className={classes.containerListGroupDragDrop}>
                                                                                {groupDragDropIngredient.map((group, index) => {
                                                                                    return <Checkbox label={group.title} value={group.title == groupDrapDropSelected} onClick={() => setGroupDrapDropSelected(group.title)} />
                                                                                })}
                                                                            </div>
                                                                        }
                                                                        <ButtonPrimary
                                                                            text='ADICIONAR'
                                                                            onClick={handleSaveIngredients}
                                                                            marginTop={false}
                                                                        />
                                                                        <ButtonNeutro
                                                                            text='CANCELAR'
                                                                            onClick={handleCancelIngredient}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        {buttonSelected === 'MODO DE PREPARO' &&
                                            <>
                                                <div className={classes.containerGrupoDragDrop}>
                                                    {Object.keys(groupDragDropModePreparation).length > 0 ?
                                                        (groupDragDropModePreparation.map((group, index) => {
                                                            return (
                                                                <>
                                                                    <p className={classes.titleGrupoDragDrop} key={index}>{group.title}</p>
                                                                    <div className={classes.containerMethodOfPreparation}>
                                                                        {group.itens.map((method, index) => {
                                                                            return (
                                                                                <>
                                                                                    {step === 1 ?
                                                                                        <CardModePreparation
                                                                                            key={index}
                                                                                            modePreparation={method}
                                                                                            buttonRemove={() => handleRemoveMethodOfPreparation(method, group.title)}
                                                                                        />
                                                                                        :
                                                                                        <CardModePreparation
                                                                                            key={index}
                                                                                            modePreparation={method}
                                                                                        />
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </>
                                                            )
                                                        }))
                                                        :
                                                        <div className={classes.containerMethodOfPreparation}>
                                                            {listMethodOfPreparation.map((method, index) => {
                                                                return (
                                                                    <>
                                                                        {step === 1 ?
                                                                            <CardModePreparation
                                                                                key={index}
                                                                                modePreparation={method}
                                                                                buttonRemove={() => handleRemoveMethodOfPreparation(method, '')}
                                                                            />
                                                                            :
                                                                            <CardModePreparation
                                                                                key={index}
                                                                                modePreparation={method}
                                                                            />
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                                {step === 1 &&
                                                    <div className={classes.containerModalInsert}>
                                                        <div className={classes.containerMethodOfPreparation}>
                                                            <Textarea
                                                                id='refMethodOfPreparation'
                                                                icon={<TextAlignJustify />}
                                                                value={textMethodOfPreparation}
                                                                onChange={(e) => setTextMethodOfPreparation(e.target.value)}
                                                                placeholder='Descrever o Passo a Passo do Modo de Preparo da Receita'
                                                                marginBottom
                                                            />
                                                            {Object.keys(groupDragDropModePreparation).length > 0 &&
                                                                <div className={classes.containerListGroupDragDrop}>
                                                                    {groupDragDropModePreparation.map((group, index) => {
                                                                        return <Checkbox label={group.title} value={group.title == groupDrapDropModePreparationSelected} onClick={() => setGroupDrapDropModePreparationSelected(group.title)} key={index} />
                                                                    })}
                                                                </div>
                                                            }
                                                            <ButtonPrimary
                                                                text='ADICIONAR'
                                                                onClick={handleSaveMethodOfPreparation}
                                                                marginTop={false}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        {step === 1 &&
                                            <>
                                                {(!selectedIngredients?.id) &&
                                                    <div className={classes.containerButtons}>
                                                        <ButtonSecondary text='VOLTAR' onClick={handleBack} marginTop={false} />
                                                        <ButtonPrimary text='CONTINUAR' onClick={handleContinue} marginTop={false} disabled={(Object.keys(listIngredientsSelected).length === 0 || Object.keys(listMethodOfPreparation).length === 0) && Object.keys(groupDragDropIngredient).length === 0 && Object.keys(groupDragDropModePreparation).length === 0} />
                                                    </div>
                                                }
                                            </>
                                        }
                                        {step === 2 &&
                                            <div className={classes.containerButtons}>
                                                <ButtonSecondary text='VOLTAR' onClick={handleBack} marginTop={false} />
                                                <ButtonPrimary text='ENVIAR RECEITA' onClick={handleSaveRecipe} loading={buttonLoading} marginTop={false} />
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {visibleModalDragDrop &&
                        <Modal
                            title={`Adicionar Etapa - ${buttonSelected}`}
                            loading={false}
                            onRequestClose={closeModalDragDrop}
                            onRequestConfirm={() => addGroupDragDrop(buttonSelected)}
                            children={
                                <Input
                                    id='refTitleDragDrop'
                                    icon={<ArticleMedium />}
                                    value={titleDragDrop}
                                    onChange={(e) => setTitleDragDrop(e.target.value)}
                                    onKeyDown={(e) => e.key === "Enter" && addGroupDragDrop(buttonSelected)}
                                    placeholder='Titulo'
                                />
                            }
                        />
                    }
                </div>
            }
        </>
    )
}

interface IInfoDetails {
    icon: any;
    text: string;
}

function InfoDetails({ icon, text }: IInfoDetails) {
    return (
        <div className={classes.infoDetailsContent}>
            <IconContext.Provider value={{ size: 20, color: '#FF6E00' }} >
                {icon}
            </IconContext.Provider>
            <p className={classes.infoDetailsText}>{text}</p>
        </div>
    )
}

export default NewRecipeOld;