import axios, { AxiosError } from "axios";
import { parseCookies } from "nookies";
import { signOut } from "../hooks/auth";
import { AuthTokenError } from "./errors/AuthTokenError";

export function setupAPIClient() {
    const { '@letscook.token': token } = parseCookies();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const api = axios.create({
        baseURL: import.meta.env.VITE_API_URL,
    })

    api.interceptors.response.use(response => {
        return response;
    }, (error: AxiosError) => {
        if (error.response?.status === 401) {
            if (typeof window !== undefined) {
                signOut()
            } else {
                return Promise.reject(new AuthTokenError())
            }
        }

        return Promise.reject(error);
    })

    return api;
}