
import { BeatLoader } from 'react-spinners';
import classes from './style.module.css';

interface ButtonNeutroProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    loading?: boolean;
}

const ButtonNeutro = ({
    text,
    loading = false,
    ...props
}: ButtonNeutroProps) => {
    return (
        <button {...props} className={classes.container} >
            {
                loading ?
                    <BeatLoader
                        color={'#FFFFFF'}
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    :
                    <p className={classes.text}>
                        {text.toUpperCase()}
                    </p>
            }
        </button>
    )
}

export default ButtonNeutro;