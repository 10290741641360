
import { useEffect, useState } from 'react';
import classes from './style.module.css';
import { CalledDTO } from '../../../dto/CalledDTO';
import { listAllCalled, listAllCalledStatus, updateCalled } from '../../../dao/AdministratorDAO';
import Loading from '../../../components/Loading';
import { dateFormat } from '../../../hooks/dateFormat';
import Modal from '../../../components/Modal';
import { Tag, TextAlignJustify } from '@phosphor-icons/react';
import Textarea from '../../../components/Textarea';
import ButtonNeutro from '../../../components/ButtonNeutro';
import { useAuth } from '../../../hooks/auth';
import { useAlert } from '../../../hooks/alert';
import Dropdown from '../../../components/Dropdown';

const Called = () => {
    const { user } = useAuth();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [listCalled, setListCalled] = useState<CalledDTO[]>([]);
    const [listCalledStatus, setListCalledStatus] = useState<any[]>([]);
    const [calledSelected, setCalledSelected] = useState({} as CalledDTO);

    const [textCalled, setTextCalled] = useState('');
    const [newStatus, setNewStatus] = useState('');

    function handleCloseModal() {
        setTextCalled('');
        setNewStatus('');
        setCalledSelected({} as CalledDTO);
    }

    function handleViewModal(item: CalledDTO) {
        setCalledSelected(item);
    }

    async function handleSaveCalled() {
        if (textCalled.length < 3) {
            return showAlert({
                message: 'Digite uma resposta.',
                type: 'error'
            })
        }

        if (newStatus.length === 0) {
            return showAlert({
                message: 'Selecione o novo status.',
                type: 'error'
            })
        }

        setLoadingModal(true);

        const result = await updateCalled(calledSelected.id, user.id, textCalled, Number(newStatus), calledSelected.status, calledSelected.description);

        if (result.statusCode === 200) {
            handleCloseModal();
            setReload(true);

            showAlert({
                message: 'Chamado atualizado com sucesso.',
                type: 'success'
            })
        } else {
            showAlert({
                message: 'Falha ao atualizar o chamado.',
                type: 'warning'
            })
        }

        setLoadingModal(false)
    }

    async function loadInitial() {
        const [listCalled, listCalledStatus] = await Promise.all([listAllCalled(), listAllCalledStatus()]);

        if (listCalled.statusCode === 200) {
            setListCalled(listCalled.data)
        }
        if (listCalledStatus.statusCode === 200) {
            let value: any[] = [];
            {
                listCalledStatus.data.map((item: any) => {
                    value.push({
                        value: item.id,
                        label: item.label
                    })
                })
            }
            setListCalledStatus(value)
        }

        setLoading(false);
    }

    useEffect(() => {
        if (reload) {
            loadInitial();
            setReload(false);
        }
    }, [reload])

    return (
        <>
            {loading ? <Loading text="Carregando chamados..." /> :
                <div className={classes.container}>
                    <div className={classes.body}>
                        <h1>Called</h1>
                        <div className={classes.content}>
                            {listCalled.map((item, index) => {
                                return (
                                    <button className={classes.buttonCalled} onClick={() => handleViewModal(item)} key={index}>
                                        <div className={classes.buttonCalleContent}>
                                            <p className={classes.buttonCalledTitle}>{item.description}</p>
                                            <div>
                                                <p className={classes.buttonCalledText}>{dateFormat(item.createdAt)} - {item.type} - {item.status}</p>
                                            </div>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
            {calledSelected.id &&
                <Modal
                    title='Detalhes do chamado'
                    onRequestClose={handleCloseModal}
                    onRequestConfirm={() => handleSaveCalled()}
                    textButtonConfirm="Responder"
                    loading={loadingModal}
                >
                    {calledSelected.calledHistory?.map((item, index) => {
                        return (
                            <div className={classes.itemContainer} key={index}>
                                <div className={classes.itemContent}>
                                    <p className={classes.itemTextInfo}>{dateFormat(item.createdAt)} - {item.userName}</p>
                                </div>
                                <p className={classes.itemTextMessage}>{item.message}</p>
                            </div>
                        )
                    })}

                    <div className={classes.modalForm}>
                        {!calledSelected.calledHistory &&
                            <div className={classes.modalContent}>
                                <p className={classes.modalDescription}>
                                    <b>Descrição do cliente:</b> {calledSelected.description}
                                </p>
                            </div>
                        }
                        <div className={classes.modalLine} />
                        <Textarea
                            id='refTextarea'
                            icon={<TextAlignJustify />}
                            value={textCalled}
                            onChange={(e) => setTextCalled(e.target.value)}
                            placeholder='Descrever a solução'
                            marginBottom
                        />
                        <Dropdown
                            icon={<Tag size={32} />}
                            listData={listCalledStatus}
                            value={newStatus}
                            onChange={(e) => setNewStatus(e.target.value)}
                            placeholder="Selecione o novo status"
                        />
                    </div>
                </Modal>
            }
        </>
    )
}

export default Called;