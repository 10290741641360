import classes from './style.module.css';

import { RecipeCategoryDTO } from '../../dto/RecipeCategoryDTO';
import { ButtonHTMLAttributes, useState } from 'react';

interface CardCategoryRecipeProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    category: RecipeCategoryDTO;
    selected: boolean;
}

const CardCategoryRecipe = ({
    category,
    selected,
    ...props
}: CardCategoryRecipeProps) => {
    const [loading, setLoading] = useState(true);

    return (
        <button className={selected ? classes.containerSelected : classes.container} {...props} >
            {loading && <div className={classes.containerLoading} />}
            <img src={category.photo} className={classes.icon} onLoad={() => setLoading(false)} />
            <p className={selected ? classes.textSelected : classes.text}>{category.label}</p>
        </button>
    )
}

export default CardCategoryRecipe;