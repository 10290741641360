import { Key } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../components/ButtonPrimary";

import Header from "../../components/Header"
import Input from "../../components/Input";

import { useAuth } from "../../hooks/auth";
import { useAlert } from '../../hooks/alert';
import { activateAccount, authentication } from '../../dao/UserDAO';

import VetorActiveCode from '../../images/vetor-active-code.jpg';

import classes from './style.module.css';
import Loading from "../../components/Loading";
import { UserDTO } from "../../dto/UserDTO";

const ActivationCode = () => {
    const { user } = useAuth();
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const [screenLoading, setScreenLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [code, setCode] = useState('');

    async function validateAccount() {
        const result = await authentication();

        if (result.statusCode === 200) {
            const user: UserDTO = result.data;
            if (user.isActive) {
                navigate('/')
            }
        }

        setScreenLoading(false)
    }

    async function handleActive() {
        setButtonLoading(true)

        const result = await activateAccount(code)

        if (result.statusCode === 201) {
            await showAlert({
                message: 'Conta ativada com sucesso.',
                type: 'success'
            })
            navigate('/')
        } else {
            await showAlert({
                message: 'Código inválido.',
                type: 'error'
            })
        }

        setButtonLoading(false)
    }

    useEffect(() => {
        validateAccount()
    }, [])

    return (
        <>
            {
                screenLoading ?
                    <Loading text="Carregando informações..." />
                    :

                    <div className={classes.container}>
                        <div className={classes.containerHeader}>
                            <Header />
                        </div>
                        <div className={classes.containerBody}>
                            <div className={classes.containerImage}>
                                <img
                                    className={classes.photo}
                                    src={VetorActiveCode}
                                />
                            </div>
                            <div className={classes.containerDetails}>
                                <p className={classes.title}>
                                    Estamos quase lá!
                                </p>
                                <p className={classes.text}>
                                    Insira o código de 6 digitos que enviamos para o seu email, para ativar sua conta.
                                </p>
                                <div className={classes.containerInput}>
                                    <Input
                                        icon={<Key />}
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        placeholder='Código'
                                    />
                                </div>
                                <ButtonPrimary
                                    text="ATIVAR"
                                    onClick={handleActive}
                                    disabled={buttonLoading}
                                />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default ActivationCode;