import { useEffect, useState } from 'react';

import HeaderNew from '../../../components/HeaderNew';

import classes from './style.module.css'
import { deleteNotification, listNotification, viewNotification } from '../../../dao/UserDAO';
import { useAuth } from '../../../hooks/auth';
import Loading from '../../../components/Loading';
import { CaretRight } from '@phosphor-icons/react';
import { dateFormat } from '../../../hooks/dateFormat';
import { NotificationDTO } from '../../../dto/Notification';
import { useAlert } from '../../../hooks/alert';

const Notifications = () => {
    const { user } = useAuth();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [listNotifications, setListNotifications] = useState<NotificationDTO[]>([]);
    const [notificationSelected, setNotificationSelected] = useState<NotificationDTO>({} as NotificationDTO);

    async function loadInitial() {
        const result = await listNotification(user.id);

        if (result.statusCode === 200) {
            setListNotifications(result.data)
        }

        setLoading(false)
    }

    async function handleViewNotification(item: NotificationDTO) {
        setNotificationSelected(item)
        await viewNotification(item.id)
        setReload(true)
    }

    async function handleDelete(id: string) {
        const response = await deleteNotification(id);

        if(response.statusCode === 200) {
            setNotificationSelected({} as NotificationDTO)
            setReload(true)
            return showAlert({
                message: 'Notificação excluída.',
                type: 'success'
            })
        } else {
            return showAlert({
                message: 'Falha ao excluir notificação.',
                type: 'warning'
            }) 
        }
    }

    useEffect(() => {
        if (reload) {
            loadInitial()
            setReload(false)
        }
    }, [reload])

    return (
        <>
            {loading ? <Loading text='Carregando informações...' />
                :
                <body>
                    <div className={classes.container}>
                        <div className={classes.containerHeader}>
                            <div className={classes.contentHeader}>
                                <HeaderNew />
                            </div>
                        </div>
                        <div className={classes.containerBody}>
                            <div className={classes.contentBody}>
                                {!notificationSelected.id ?
                                    <>
                                        <p className={classes.title}>Notificações</p>
                                        <div className={classes.containerNotification}>
                                            {listNotifications.map((item, index) => {
                                                return (
                                                    <ButtonNotification
                                                        key={index}
                                                        title={item.title}
                                                        isViewed={item.isViewed}
                                                        createdAt={item.createdAt}
                                                        seenAt={item.seenAt}
                                                        onClick={() => handleViewNotification(item)}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </>
                                    :
                                    <div className={classes.viewContainer}>
                                        <div className={classes.viewContent}>
                                            <p className={classes.title}>{notificationSelected.title}</p>
                                            <p>{notificationSelected.message}</p>
                                        </div>
                                        <p>{`Recebido em ${dateFormat(notificationSelected.createdAt)}`}</p>
                                        <div className={classes.viewContentButton}>
                                            <button className={classes.viewButton} onClick={() => setNotificationSelected({} as NotificationDTO)}>
                                                <p className={classes.viewButtonText}>Fechar</p>
                                            </button>
                                            <button className={classes.viewButton} onClick={() => handleDelete(notificationSelected.id)}>
                                                <p className={classes.viewButtonTextDelete}>Excluir</p>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </body>
            }
        </>
    )
}

interface IButtonNotification extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    isViewed: boolean;
    createdAt: string;
    seenAt: string;
}

function ButtonNotification({ title, isViewed, createdAt, seenAt, ...props }: IButtonNotification) {
    return (
        <button className={isViewed ? classes.buttonContainerView : classes.buttonContainer} {...props}>
            <div className={classes.buttonContent}>
                <p className={isViewed ? classes.buttonTitleView : classes.buttonTitle}>{title}</p>
                <p className={isViewed ? classes.buttonTextView : classes.buttonText}>{dateFormat(createdAt)}</p>
            </div>
            <CaretRight size={20} color={isViewed ? '#272727' : '#FFFFFF'} />
        </button >
    )
}

export default Notifications;