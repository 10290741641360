import { useEffect, useState } from "react";

import Loading from "../../../components/Loading";

import { administratorListPlans } from "../../../dao/AdministratorDAO";
import { PlanDTO } from "../../../dto/PlanDTO";

import classes from './style.module.css';

const Plans = () => {
    const [loading, setLoading] = useState(false);
    const [listPlans, setListPlans] = useState<PlanDTO[]>([]);

    async function loadInitial() {
        const response = await administratorListPlans();

        if (response.statusCode === 200) {
            setListPlans(response.data)
        }

        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    useEffect(() => {
        setLoading(true)
        loadInitial()
    }, [])
    return (
        <>
            {loading ? <Loading /> :
                <div className={classes.container}>
                    <div className={classes.body}>
                        <div className={classes.contentTitle}>
                            <p className={classes.title}>Planos cadastrados</p>
                        </div>
                        <div className={classes.content}>
                            {listPlans.map((plan, index) => {
                                return (
                                    <div className={classes.cardContent} key={index}>
                                        <div className={classes.cardContentTitle} style={{ backgroundColor: plan.color }}>
                                            <p className={classes.cardTitle}>{plan.label}</p>
                                        </div>
                                        <div className={classes.cardContentBody}>
                                            <p>Ingredientes salvos por {plan.ingredientsExpireDays} dias.</p>
                                            <p>Conta logada por {plan.sessionExpiresDays} dias.</p>
                                        </div>
                                        <div className={classes.cardContentValue}>
                                            <p className={classes.valueText}>{plan.value === '0,00' ? 'Grátis' : `R$ ${plan.value} anual`}</p>
                                        </div>
                                        <div className={classes.cardContentVisivle}>
                                            <p className={plan.visible ? classes.textVisibleActive : classes.textVisible}>{plan.visible? 'ATIVO' : 'INTERNO'}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Plans;