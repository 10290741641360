import classes from './style.module.css';

import { ReactComponent as Logo } from '../../images/logo.svg'

interface LoadingProps {
    text?: string;
}

const Loading = ({
    text = 'Carregando...'
}: LoadingProps) => {
    return (
        <div className={classes.container}>
            <Logo className={classes.logo} />
            <p className={classes.text}>
                {text}
            </p>
        </div>
    )
}

export default Loading;