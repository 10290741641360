
import { BeatLoader } from 'react-spinners';
import classes from './style.module.css';

interface ButtonSecondaryProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    loading?: boolean;
    marginTop?: boolean;
}

const ButtonSecondary = ({
    text,
    loading = false,
    marginTop = true,
    ...props
}: ButtonSecondaryProps) => {
    return (
        <button
            {...props}
            className={`
                ${classes.container}
                ${marginTop && classes.marginTop}
            `}
        >
            {
                loading ?
                    <BeatLoader
                        color={'#FFFFFF'}
                        loading={loading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    :
                    <p className={classes.text}>
                        {text.toUpperCase()}
                    </p>
            }
        </button>
    )
}

export default ButtonSecondary;