import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarBlank, Camera, EnvelopeSimple, FileText, GlobeHemisphereEast, Lock, MapPin, User } from '@phosphor-icons/react';

import ButtonPrimary from '../../components/ButtonPrimary';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Dropdown from '../../components/Dropdown';
import InputAttachment from '../../components/InputAttachment';
import TermOfUse from '../../components/TermOfUse';

import { useAlert } from '../../hooks/alert';

import { listCities, listStates } from '../../dao/SystemDAO';
import { newAccount } from '../../dao/UserDAO';

import HeaderNew from '../../components/HeaderNew';

import classes from './style.module.css'

const NewAccount = () => {
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [photo, setPhoto] = useState<File>({} as File);
    const [photoUrl, setPhotoUrl] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [checkNutritionist, setCheckNutritionist] = useState(false);
    const [certificate, setCertificate] = useState<File>({} as File);
    const [checkTerms, setCheckTerms] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    function handleTerm() {
        setModalVisible(true)
    }

    const handleInputPhotoClick = () => {
        const input = document.getElementById('file-input');

        if (input) {
            input.click();
        }
    };

    const handleChangePhoto = (e: any) => {
        const selectedFile = e.target.files[0];
        const objectUrl = URL.createObjectURL(selectedFile)

        setPhotoUrl(objectUrl)
        setPhoto(selectedFile)
    };

    const handleChangeCerticate = (e: any) => {
        const selectedFile = e.target.files[0];

        setCertificate(selectedFile)
    };

    async function handleContinue() {
        setButtonLoading(true)

        const response = await newAccount({
            photo, name, email, password, birthDate, state,
            city, isNutritionist: checkNutritionist, certificate, termsOfUse: checkTerms
        })

        if (response.statusCode === 201) {
            await showAlert({
                message: 'Conta criada com sucesso.',
                type: 'success'
            })
            return navigate(-1)
        }

        if (response.statusCode === 500) {
            await showAlert({
                message: response.message ? response.message : 'Falha ao criar conta.',
                type: 'warning'
            })
        } else {
            await showAlert({
                message: response.message ? response.message : 'Preencha corretamente os dados.',
                type: 'error'
            })
        }

        setButtonLoading(false)
    }

    return (
        <body>
            <div className={classes.container}>
                <div className={classes.containerHeader}>
                    <div className={classes.contentHeader}>
                        <HeaderNew />
                    </div>
                </div>
                <div className={classes.containerBody}>
                    <div className={classes.contentBody}>
                        <div className={classes.containerInput}>
                            <button className={classes.buttonPhoto} onClick={handleInputPhotoClick}>
                                <input
                                    accept="image/*"
                                    type="file"
                                    id="file-input"
                                    style={{ display: "none" }}
                                    onChange={e => handleChangePhoto(e)}
                                />
                                {photoUrl !== '' ?
                                    <img
                                        src={photoUrl}
                                        className={classes.photo}
                                    />
                                    :
                                    <>
                                        < Camera
                                            size={40}
                                            color='#FFFFFF'
                                        />
                                        <p className={classes.textButtonPhoto}>
                                            Adicione a sua foto
                                        </p>
                                    </>
                                }
                            </button>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                icon={<User />}
                                placeholder='Nome Completo'
                                marginBottom
                            />
                            <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                icon={<EnvelopeSimple />}
                                placeholder='E-mail'
                                marginBottom
                            />
                            <Input
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                icon={<Lock />}
                                placeholder='Senha'
                                marginBottom
                                autoComplete="new-password"
                                id="new-password"
                            />
                            <Input
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                                icon={<CalendarBlank />}
                                placeholder='Data de Nascimento'
                                onFocus={(e) => e.currentTarget.type = 'date'}
                                marginBottom
                            />
                            <Dropdown
                                value={state}
                                onChange={e => setState(e.target.value)}
                                listData={listStates()}
                                icon={<GlobeHemisphereEast />}
                                placeholder='Estado'
                                marginBottom
                            />
                            <Dropdown
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                listData={listCities(state)}
                                icon={<MapPin />}
                                placeholder='Cidade'
                                marginBottom
                            />
                            {
                                checkNutritionist &&
                                <InputAttachment
                                    accept="image/*,.pdf"
                                    value={certificate.name}
                                    onChange={e => handleChangeCerticate(e)}
                                    placeholder='Certificado de Conclusão'
                                    marginBottom
                                />
                            }
                            <Checkbox
                                label='Sou nutricionista'
                                value={checkNutritionist}
                                onClick={() => setCheckNutritionist(!checkNutritionist)}
                            />
                            <div className={classes.containerTerm}>
                                <Checkbox
                                    label='Aceito os termos de uso.'
                                    value={checkTerms}
                                    onClick={() => setCheckTerms(!checkTerms)}
                                />
                                <button
                                    className={classes.buttonTerm}
                                    onClick={handleTerm}
                                >
                                    <FileText
                                        size={30}
                                        color='#AEAEB3'
                                    />
                                </button>
                            </div>
                            <ButtonPrimary
                                text='CRIAR CONTA'
                                onClick={handleContinue}
                                loading={buttonLoading}
                                disabled={buttonLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {modalVisible &&
                <div className={classes.modalContainer} >
                    <div className={classes.modalContent}>
                        <div className={classes.modalHeader}>
                            <p className={classes.modalTitle}>
                                Termos de Uso
                            </p>
                        </div>
                        <div className={classes.modalBody}>
                            <div className={classes.modalChildren}>
                                <TermOfUse />
                            </div>
                            <ButtonPrimary
                                text='FECHAR'
                                onClick={() => setModalVisible(false)}
                            />
                        </div>
                    </div>
                </div>
            }
        </body >
    )
}

export default NewAccount;