import { useEffect, useLayoutEffect, useState } from "react";
import { Camera, Hash, MagnifyingGlass, PencilSimple, Plus, Tag, TextAa } from "@phosphor-icons/react";

import { administratorCreateIngredient, administratorListIngredientCategories, administratorListIngredients } from "../../../dao/AdministratorDAO";
import { removeAccents } from "../../../hooks/removeAccents";
import { useAlert } from "../../../hooks/alert";

import { IngredientDTO } from "../../../dto/IngredientDTO";
import { IngredientCategoryDTO } from "../../../dto/IngredientCategoryDTO";

import Loading from "../../../components/Loading";
import CardStatus from "../../../components/CardStatus";
import InputAttachment from "../../../components/InputAttachment";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import Dropdown from "../../../components/Dropdown";

import classes from './style.module.css';

const Ingredients = () => {
    const { showAlert } = useAlert();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [listIngredients, setListIngredients] = useState<IngredientDTO[]>([]);
    const [listIngredientsFoundSearch, setListIngredientsFoundSearch] = useState<IngredientDTO[]>([]);
    const [listCategories, setListCategories] = useState<any[]>([]);
    const [photo, setPhoto] = useState<File>({} as File);
    const [photoUrl, setPhotoUrl] = useState('');
    const [titleIngredient, setTitleIngredient] = useState('');
    const [tagIngredient, setTagIngredient] = useState('');
    const [category, setCategory] = useState<IngredientCategoryDTO>({} as IngredientCategoryDTO)
    const [textSearch, setTextSearch] = useState('')

    const handleChangePhoto = async (e: any) => {
        const selectedFile = e.target.files[0];
        const objectUrl = URL.createObjectURL(selectedFile)

        setPhotoUrl(objectUrl)
        setPhoto(selectedFile)
    };

    async function loadIngredientes() {
        const result = await administratorListIngredients();

        if (result.statusCode === 200) {
            setListIngredients(result.data)
            setListIngredientsFoundSearch(result.data)
        }
    }

    async function loadCategories() {
        const result = await administratorListIngredientCategories();

        if (result.statusCode === 200) {
            let listCategories: any[] = [];
            result.data.map((category: IngredientCategoryDTO) => {
                listCategories.push({
                    label: category.label,
                    value: category
                })
            })

            setListCategories(listCategories)
        }

        setLoading(false)
    }

    function closeModal() {
        setIsVisibleModal(false);
        setTitleIngredient('');
        setTagIngredient('');
        setPhoto({} as File);
        setPhotoUrl('');
        setCategory({} as IngredientCategoryDTO);
    }

    async function handleSaveNewIngredient() {
        if (!photo.name) {
            return showAlert({
                message: 'Selecione uma foto.',
                type: 'error'

            })
        }

        if (titleIngredient.length < 2) {
            return showAlert({
                message: 'Preencha o título do ingrediente.',
                type: 'error'

            })
        }

        setLoadingModal(true);

        const response = await administratorCreateIngredient(photo, titleIngredient, tagIngredient, category.id);

        if (response.statusCode === 201) {
            setReload(true);
            setIsVisibleModal(false);
            setLoadingModal(false);
            setTitleIngredient('');
            setPhoto({} as File);
            setPhotoUrl('');
            setCategory({} as IngredientCategoryDTO);

            return showAlert({
                message: 'Ingrediente cadastrado com sucesso.',
                type: 'success'

            })
        } else {
            setLoadingModal(false);
            return showAlert({
                message: response.message ? response.message : 'Falha ao criar ingrediente.',
                type: 'warning'
            })
        }

    }

    function handleSearchIngredient() {
        if (textSearch.length > 2) {
            let ingredients: IngredientDTO[] = [];

            if (Object.keys(listIngredientsFoundSearch).length > 0) {
                listIngredientsFoundSearch.map(ingredient => {
                    if (removeAccents(ingredient.title).toUpperCase().includes(removeAccents(textSearch).toUpperCase())) {
                        ingredients.push(ingredient);
                    }
                    if (ingredient.tag) {
                        if (removeAccents(ingredient.tag).toUpperCase().includes(removeAccents(textSearch).toUpperCase())) {
                            let find = ingredients.findIndex(obj => obj.id == ingredient.id);
                            if (find == -1) {
                                ingredients.push(ingredient);
                            }
                        }
                    }
                })
            } else {
                listIngredients.map(ingredient => {
                    if (removeAccents(ingredient.title).toUpperCase().includes(removeAccents(textSearch).toUpperCase())) {
                        ingredients.push(ingredient);
                    }
                    if (ingredient.tag) {
                        if (removeAccents(ingredient.tag).toUpperCase().includes(removeAccents(textSearch).toUpperCase())) {
                            let find = ingredients.findIndex(obj => obj.id == ingredient.id);
                            if (find == -1) {
                                ingredients.push(ingredient);
                            }
                        }
                    }
                })
            }
            setListIngredientsFoundSearch(ingredients)
        } else {
            setListIngredientsFoundSearch(listIngredients)
        }
    }

    useLayoutEffect(() => {
        if (reload) {
            loadIngredientes()
            loadCategories()
            setReload(false)
        }
    }, [reload])

    useEffect(() => {
        handleSearchIngredient()
    }, [textSearch || reload])

    return (
        <>
            {loading ? <Loading text="Carregando ingredientes..." />
                :
                <div className={classes.container}>
                    <div className={classes.body}>
                        <div className={classes.contentSearch}>
                            <Input
                                value={textSearch}
                                onChange={(e) => setTextSearch(e.target.value)}
                                icon={<MagnifyingGlass />}
                                placeholder='Pesquisar ingrediente'
                            />
                        </div>
                        {listIngredientsFoundSearch.map((item, index) => {
                            return (
                                <div className={classes.item} key={index}>
                                    <div className={classes.info}>
                                        <div className={classes.infoDetails}>
                                            <div className={classes.photo}>
                                                <img src={item.photo} className={classes.photo} />
                                            </div>
                                            <div className={classes.infoTitle}>
                                                <p className={classes.title}>{item.title}</p>
                                                <p className={classes.tag}># {item.tag}</p>
                                                <CardStatus text={String(item.category)} type="orange" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemMenu}>
                                        <PencilSimple size={25} color="#AEAEB3" />
                                    </div>
                                </div>
                            )
                        })}
                        <button
                            className={classes.buttonMenu}
                            onClick={() => setIsVisibleModal(true)}
                        >
                            <Plus
                                size={30}
                                color='#FFFFFF'
                                weight="bold"
                            />
                        </button>
                    </div>
                </div>
            }
            {isVisibleModal &&
                <Modal
                    title='Adicionar Ingrediente'
                    onRequestClose={closeModal}
                    onRequestConfirm={handleSaveNewIngredient}
                    loading={loadingModal}
                >
                    <div className={classes.contentPhoto}>
                        {
                            photoUrl !== '' ?
                                <img
                                    src={photoUrl}
                                    className={classes.photo}
                                />
                                :
                                <>
                                    <Camera
                                        size={32}
                                        color='#272727'
                                    />
                                    <p className={classes.textPhoto}>
                                        Nenhuma foto selecionada.
                                    </p>
                                </>
                        }
                    </div>
                    <InputAttachment
                        value={photo.name}
                        onChange={e => handleChangePhoto(e)}
                        placeholder="Foto do ingrediente"
                        marginBottom
                    />
                    <Input
                        icon={<TextAa size={32} />}
                        value={titleIngredient}
                        onChange={(e) => setTitleIngredient(e.target.value)}
                        placeholder="Nome do ingrediente"
                        marginBottom
                    />
                    <Input
                        icon={<Hash size={32} />}
                        value={tagIngredient}
                        onChange={(e) => setTagIngredient(e.target.value)}
                        placeholder="Tag"
                        marginBottom
                    />
                    <Dropdown
                        icon={<Tag size={32} />}
                        listData={listCategories}
                        value={category.label}
                        onChange={(e) => setCategory(JSON.parse(e.target.value))}
                        placeholder="Selecione a categoria"
                    />
                </Modal>
            }
        </>
    )
}

export default Ingredients;
