import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { destroyCookie, setCookie } from "nookies";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

import { UserDTO } from "../dto/UserDTO";

import { api } from "../services/apiClient";
import { authentication } from "../dao/UserDAO";
import { useAlert } from "./alert";

interface AuthContextData {
    user: UserDTO;
    isAuthenticated: boolean;
    signIn: (credentials: SignInProps) => Promise<void>;
    signUp: (credentials: SignUpProps) => Promise<void>;
    logoutUser: () => Promise<void>;
}

type AuthProviderProps = {
    children: ReactNode;
}

interface SignInProps {
    email: string;
    password: string;
}

interface SignUpProps {
    name: string;
    email: string;
    password: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function signOut() {
    const navigate = useNavigate();

    try {
        destroyCookie(null, '@letscook.token', { path: '/' })
        destroyCookie(null, '@letscook.user', { path: '/' })
        navigate('/')
    } catch (error) {
        throw new Error('Problema ao sair.')
    }
}

export function AuthProvider({ children }: AuthProviderProps) {
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [user, setUser] = useState<UserDTO>({} as UserDTO);
    const isAuthenticated = !!user;

    async function loadStorageData() {
        try {
            const result = await authentication();
            if (result.statusCode === 200) {
                const user = result.data;
                setUser(user)

                if (!user.isActive) {
                    navigate('/activationCode')
                }
            }
        } catch {
            return signOut()
        }
    }

    async function signIn({ email, password }: SignInProps) {
        const passwordCrypto = CryptoJS.MD5(password);
        const device = {
            applicationVersion: 'web',
            modelName: navigator.userAgent,
            deviceName: '',
            osVersion: ''
        };

        try {
            const response = await api.post('/user/login', {
                email,
                password: String(passwordCrypto),
                device
            })

            const { id, photo, name, birthDate, state, city, category, token } = response.data;

            setCookie(undefined, '@letscook.token', token, {
                maxAge: 60 * 60 * 24 * 30,
                path: '/'
            })

            const cryptoUser = CryptoJS.MD5(category.label);
            setCookie(undefined, '@letscook.user', String(cryptoUser), {
                maxAge: 60 * 60 * 24 * 30,
                path: '/'
            })

            setUser({
                id,
                photo,
                name,
                birthDate,
                state,
                city,
                category,
                tokenFirebase: token
            })

            api.defaults.headers.common['Authorization'] = `Bearer ${token}`

            navigate('/activationCode')

        } catch {
            showAlert({
                message: 'E-mail e/ou senha inválido!',
                type: 'warning'
            })
        }
    }

    async function signUp({ name, email, password }: SignUpProps) {
        // try {
        //     const response = api.post('/users', {
        //         name,
        //         email,
        //         password
        //     })

        //     navigate('/')
        // } catch (error) {
        //     throw new Error('Falha ao criar a conta.')
        // }
    }

    async function logoutUser() {
        try {
            destroyCookie(null, '@letscook.token', { path: '/' })
            destroyCookie(null, '@letscook.user', { path: '/' })
            navigate('/')
            setUser({} as UserDTO)
        } catch (error) {
            throw new Error('Falha ao sair.')
        }
    }

    useEffect(() => {
        loadStorageData()
    }, []);

    return (
        <AuthContext.Provider value={{
            user,
            isAuthenticated,
            signIn,
            signUp,
            logoutUser
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    return context;
}