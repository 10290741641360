import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BookBookmark, CallBell, Clock, GearSix, IconContext, Plus, Star, Tag, X } from "@phosphor-icons/react";

import { useAlert } from "../../../hooks/alert";

import Loading from "../../../components/Loading";

import { RecipeDTO } from "../../../dto/RecipeDTO";
import { administratorApproveRecipe, administratorDeclineRecipe, administratorListRecipesPending } from "../../../dao/AdministratorDAO";

import HeartSuit from '../../../images/Figmoji/heart-suit.png';
import Cooking from '../../../images/Figmoji/cooking.png';
import Watch from '../../../images/Figmoji/watch.png';

import classes from './style.module.css';
import { converterMinutes } from "../../../hooks/converterMinutes";

const RecipePending = () => {
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);
    const [isVisibleModalPending, setIsVisibleModalPending] = useState(false);
    const [listRecipes, setListRecipes] = useState<RecipeDTO[]>([]);
    const [recipeSelected, setRecipeSelected] = useState<RecipeDTO>({} as RecipeDTO);

    async function loadRecipes() {
        const result = await administratorListRecipesPending();

        if (result.statusCode === 200) {
            setListRecipes(result.data)
        } else {
            setListRecipes([])
        }

        setLoading(false)
    }

    function handleOptionRecipe(recipe: RecipeDTO) {
        setRecipeSelected(recipe)
        setIsVisibleModalPending(true)
    }

    function handleClose() {
        setRecipeSelected({} as RecipeDTO)
        setIsVisibleModalPending(false)
    }

    async function handleApprove() {
        if (recipeSelected?.id) {
            const result = await administratorApproveRecipe(recipeSelected.id)

            if (result.statusCode !== 200) {
                return showAlert({
                    message: result?.message ? result?.message : 'Falha Interna.',
                    type: "error"
                })
            }

            setRecipeSelected({} as RecipeDTO)
            setIsVisibleModalPending(false)
            setReload(true)

            return showAlert({
                message: 'Receita aprovada!',
                type: "success"
            })
        }
    }

    async function handleDecline() {
        if (recipeSelected?.id) {
            const result = await administratorDeclineRecipe(recipeSelected.id)

            if (result.statusCode !== 200) {
                return showAlert({
                    message: result?.message ? result?.message : 'Falha Interna.',
                    type: "error"
                })
            }

            setRecipeSelected({} as RecipeDTO)
            setIsVisibleModalPending(false)
            setReload(true)

            return showAlert({
                message: 'Receita recusada!',
                type: "success"
            })
        }
    }

    function handleViewRecipe(recipe: RecipeDTO) {
        navigate(`/viewRecipe/${recipe.id}`)
    }

    useLayoutEffect(() => {
        if (reload) {
            loadRecipes()
            setReload(false)
        }
    }, [reload])

    return (
        <>
            {loading ?
                <Loading text="Carregando receitas pendentes..." />
                :
                <div className={classes.container}>
                    <div className={classes.body}>
                        {Object.keys(listRecipes).length === 0 ?
                            <p>Nenhuma receita pendente.</p>
                            :
                            (listRecipes.map((recipe, index) => {
                                return (
                                    <div className={classes.itemContainer} key={index}>
                                        <button
                                            className={classes.buttonItem}
                                            onClick={() => handleViewRecipe(recipe)}
                                        >
                                            <div className={classes.itemPhoto}>
                                                <img src={recipe.photo} className={classes.itemPhotoImg} />
                                            </div>
                                            <div className={classes.itemDetails}>
                                                <>
                                                <p className={classes.itemDetailsTitle}>{recipe.title}</p>
                                                <p className={classes.itemDetailsSubTitle}>enviado por {recipe?.user?.name}</p>
                                                </>
                                                <div className={classes.itemDetailsContainer}>
                                                    <InfoDetails icon={<Star />} text={String(recipe?.evaluation?.amountAssessments ?? '5.0')} />
                                                    <InfoDetails icon={<Clock />} text={converterMinutes(recipe?.time)} />
                                                    <InfoDetails icon={<CallBell />} text={String(recipe?.portions)} />
                                                </div>
                                            </div>
                                        </button>
                                        <button className={classes.itemMenu} onClick={() => handleOptionRecipe(recipe)}>
                                            <GearSix size={32} color="#AEAEB3"/>
                                        </button>
                                    </div>
                                )
                            })
                            )
                        }
                        <button className={classes.buttonMenu} onClick={() => setIsVisibleMenu(!isVisibleMenu)} >
                            {isVisibleMenu ?
                                <X size={30} color='#FFFFFF' weight="bold" />
                                :
                                <Plus size={30} color='#FFFFFF' weight="bold" />
                            }
                        </button>
                        {isVisibleMenu &&
                            <div className={classes.menuContainer}>
                                <button className={classes.menuButton} onClick={() => navigate('/newRecipe')}>
                                    <BookBookmark size={25} color='#FFFFFF' />
                                    <p className={classes.menuButtonText}>Enviar Receita</p>
                                </button>
                                <button className={classes.menuButton} onClick={() => navigate('/administrator/recipe-categories')}>
                                    <Tag size={25} color='#FFFFFF' />
                                    <p className={classes.menuButtonText}>Categorias de Receitas</p>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            }
            {isVisibleModalPending &&
                <div className={classes.modalMenuContainer}>
                    <div className={classes.modalMenuBody}>
                        <div className={classes.modalHeader}>
                            <p className={classes.modalHeaderTitle}>
                                {recipeSelected?.title}
                            </p>
                            <button
                                className={classes.modalHeaderButton}
                                onClick={handleClose}
                            >
                                <p className={classes.modalHeaderButtonText}>x</p>
                            </button>
                        </div>
                        <div className={classes.modalBody}>

                        </div>
                        <div className={classes.modalFooter}>
                            <button
                                className={classes.buttonGreen}
                                onClick={handleApprove}
                            >
                                <p className={classes.modalFooterButtonText}>
                                    APROVAR
                                </p>
                            </button>
                            <button
                                className={classes.buttonRed}
                                onClick={handleDecline}
                            >
                                <p className={classes.modalFooterButtonText}>
                                    RECUSAR
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

interface IInfoDetails {
    icon: any;
    text: string;
}

function InfoDetails({ icon, text }: IInfoDetails) {
    return (
        <div className={classes.infoDetailsContent}>
            <IconContext.Provider value={{ size: 20, color: '#FF6E00' }} >
                {icon}
            </IconContext.Provider>
            <p className={classes.infoDetailsText}>{text}</p>
        </div>
    )
}

export default RecipePending;