import { ResponseDTO } from "../dto/ResponseDTO";

import { api } from "../services/apiClient";

async function administratorTotalizers() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/totalizers').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhum totalizador encontrado!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorListIngredientCategories() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listIngredientCategories').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma categoria encontrada!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorCreateMeasure(label: string, photo: File) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (!response.statusCode) {
        if (!photo.name) {
            response = {
                statusCode: 406,
                message: 'Selecione uma foto!'
            }
        }
    }

    if (!response.statusCode) {
        if (label.length < 2) {
            response = {
                statusCode: 406,
                message: 'Mínimo 2 caracteres!'
            }
        }
    }

    let urlPhoto: string = '';

    // MONTA E FAZ O UPLOAD DA FOTO
    if (!response.statusCode) {
        if (!!photo.name) {
            const photoFile = new FormData();
            photoFile.append('file', photo as any);

            await api.post('/upload/ingredient/measure', photoFile, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((result) => {
                if (result.status !== 201) {
                    response = {
                        statusCode: 500,
                        message: 'Falha de comunicação no servidor de imagens!'
                    }
                }
                urlPhoto = result.data.url;
            }).catch((err) => {
                response = {
                    statusCode: 500,
                    message: 'Arquivo inválido! Tamanho máximo 5MB e formatos aceitos: jpg, jpeg, png!'
                }
            });
        }
    }

    if (!response.statusCode) {
        await api.post('/administrator/measure/create', {
            photo: urlPhoto,
            label,
        }).then((result) => {
            response = {
                statusCode: 201,
                message: 'Medida criada com sucesso!'
            }
        }).catch((e) => {
            response = {
                statusCode: 400,
                message: 'Falha ao criar a medida!'
            }
        });
    }

    return response;
}

async function administratorEditMeasure(id: string, label: string, photo: File, odlPhoto: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (!response.statusCode) {
        if (label.length < 1) {
            response = {
                statusCode: 406,
                message: 'Mínimo 2 caracteres!'
            }
        }
    }

    let urlPhoto: string = '';

    // MONTA E FAZ O UPLOAD DA FOTO
    if (!response.statusCode) {
        if (photo.name) {
            const photoFile = new FormData();
            photoFile.append('file', photo as any);

            await api.post('/upload/ingredient/measure', photoFile, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((result) => {
                if (result.status !== 201) {
                    response = {
                        statusCode: 500,
                        message: 'Falha de comunicação no servidor de imagens!'
                    }
                }
                urlPhoto = result.data.url;
            }).catch((err) => {
                response = {
                    statusCode: 500,
                    message: 'Arquivo inválido! Tamanho máximo 5MB e formatos aceitos: jpg, jpeg, png!'
                }
            });
        }
    }

    try {
        await api.put('/administrator/measure/edit', {
            id,
            label,
            photo: urlPhoto.length > 0 ? urlPhoto : odlPhoto
        }).then((result) => {
            if (result.status === 200) {
                response = {
                    statusCode: 200
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Falha ao editar a medida!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorDeleteMeasure(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.delete('/administrator/measure/delete', {
            params: {
                id
            }
        }).then((result) => {
            if (result.status === 200) {
                response = {
                    statusCode: 200
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Falha ao excluir a medida!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorListMeasures() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listMeasures').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma medida encontrada!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorListRecipeCategories() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listRecipeCategories').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma categoria encontrada!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorListRecipesPending() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listPendingRecipes').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma receita pendente encontrada!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorApproveRecipe(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.put('/administrator/approveRecipe', {
            id
        }).then((result) => {
            if (result.status === 201) {
                response = {
                    statusCode: 200
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Falha ao aprovar a receita!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorDeclineRecipe(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.put('/administrator/declineRecipe', {
            id
        }).then((result) => {
            if (result.status === 201) {
                response = {
                    statusCode: 200
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Falha ao recusar a receita!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorListUsers() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listAllUsers').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma usuário encontrado!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorCreateIngredientCategory(label: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (!response.statusCode) {
        if (label.length < 3) {
            response = {
                statusCode: 406,
                message: 'Mínimo 3 caracteres!'
            }
        }
    }

    if (!response.statusCode) {
        await api.post('/administrator/createIngredientCategory', {
            label
        }).then((result) => {
            response = {
                statusCode: 201,
                message: 'Categoria criada com sucesso!'
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao criar a categoria!'
            }
        });
    }

    return response;
}

async function administratorDeleteIngredientCategory(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (!response.statusCode) {
        if (!id) {
            response = {
                statusCode: 406,
                message: 'Informe o id a ser deletado!'
            }
        }
    }

    if (!response.statusCode) {
        response = {
            statusCode: 404,
            message: 'Funcionalidade indisponível!'
        }
    }

    return response;
}

async function administratorCreateRecipeCategory(photo: File, label: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (!response.statusCode) {
        if (!photo.name) {
            response = {
                statusCode: 406,
                message: 'Selecione uma foto!'
            }
        }
    }

    if (!response.statusCode) {
        if (label.length < 3) {
            response = {
                statusCode: 406,
                message: 'Mínimo 3 caracteres!'
            }
        }
    }

    let urlPhoto: string = '';

    // MONTA E FAZ O UPLOAD DA FOTO
    if (!response.statusCode) {
        if (!!photo.name) {
            const photoFile = new FormData();
            photoFile.append('file', photo as any);

            await api.post('/administrator/createRecipeCategory/uploadPhoto', photoFile, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((result) => {
                if (result.status !== 201) {
                    response = {
                        statusCode: 500,
                        message: 'Falha de comunicação no servidor de imagens!'
                    }
                }
                urlPhoto = result.data.url;
            }).catch((err) => {
                response = {
                    statusCode: 500,
                    message: 'Arquivo inválido! Tamanho máximo 5MB e formatos aceitos: jpg, jpeg, png!'
                }
            });
        }
    }

    if (!response.statusCode) {
        await api.post('/administrator/createRecipeCategory', {
            photo: urlPhoto,
            label
        }).then((result) => {
            response = {
                statusCode: 201,
                message: 'Categoria criada com sucesso!'
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao criar a categoria!'
            }
        });
    }

    return response;
}

async function administratorActivedRecipeCategory(id: string, status: boolean) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.post('/administrator/activedRecipeCategory', {
            id,
            status
        }).then((result) => {
            if (result.status === 201) {
                response = {
                    statusCode: 201
                }
            }
        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha ao atualizar!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorListIngredients() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listAllIngredients').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhuma usuário encontrado!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorCreateIngredient(photo: File, title: string, tag: string, idCategory: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (!response.statusCode) {
        if (!photo.name) {
            response = {
                statusCode: 406,
                message: 'Selecione uma foto!'
            }
        }
    }

    if (!response.statusCode) {
        if (title.length < 2) {
            response = {
                statusCode: 406,
                message: 'Mínimo 2 caracteres!'
            }
        }
    }

    if (!response.statusCode) {
        if (idCategory.length == 0) {
            response = {
                statusCode: 406,
                message: 'Selecione uma categoria!'
            }
        }
    }

    let urlPhoto: string = '';

    // MONTA E FAZ O UPLOAD DA FOTO
    if (!response.statusCode) {
        if (!!photo.name) {
            const photoFile = new FormData();
            photoFile.append('file', photo as any);

            await api.post('/upload/ingredient/photo', photoFile, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((result) => {
                if (result.status !== 201) {
                    response = {
                        statusCode: 500,
                        message: 'Falha de comunicação no servidor de imagens!'
                    }
                }
                urlPhoto = result.data.url;
            }).catch((err) => {
                response = {
                    statusCode: 500,
                    message: 'Arquivo inválido! Tamanho máximo 5MB e formatos aceitos: jpg, jpeg, png!'
                }
            });
        }
    }

    if (!response.statusCode) {
        await api.post('/administrator/createIngredient', {
            photo: urlPhoto,
            title,
            tag,
            idCategory
        }).then((result) => {
            response = {
                statusCode: 201,
                message: 'Ingrediente criado com sucesso!'
            }
        }).catch((e) => {
            response = {
                statusCode: 400,
                message: 'Falha ao criar o ingrediente!'
            }
        });
    }

    return response;
}

async function administratorListPlans() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/plans').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Nenhum plano encontrado!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorPushNotification(idUser: string, title: string, message: string, tokenFirebase?: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {

        //PERSISTIR NA TABELA DE NOTIFICAÇÕES
        await api.post('/administrator/notification', {
            idUser,
            title,
            message,
            mobile: tokenFirebase && 1
        }).then(async (result) => {
            if (result.status !== 200) {
                return response = {
                    statusCode: 404,
                    message: 'Falha ao persistir notificação!'
                }
            }
        }).catch(() => {
            return response = {
                statusCode: 404,
                message: 'Falha ao persistir notificação!'
            }
        });

        //ENVIA NOTIFICAÇÃO PARA O CELULAR
        if (tokenFirebase) {
            await api.get('/firebase/token').then(async (result) => {
                if (result.status === 200) {
                    await administratorPushNotificationSend(result.data.token, tokenFirebase, title, message).then(send => {
                        if (send.statusCode === 200) {
                            response = {
                                statusCode: 200,
                                message: 'Notificação enviada para o celular!'
                            }
                        } else {
                            response = {
                                statusCode: 404,
                                message: 'Notificação não enviada para o celular!'
                            }
                        }
                    })

                } else {
                    response = {
                        statusCode: 404,
                        message: 'Notificação não enviada para o celular!'
                    }
                }
            }).catch(() => {
                response = {
                    statusCode: 404,
                    message: 'Notificação não enviada para o celular!'
                }
            });
        } else {
            response = {
                statusCode: 200,
                message: 'Notificação registrada no sistema!'
            }
        }
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function administratorPushNotificationSend(token: string, tokenFirebase: string, title: string, message: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.post('/firebase/pushNotification', {
            token,
            tokenFirebase,
            title,
            message,
        }).then((result) => {

            if (result.status === 200) {
                response = {
                    statusCode: 200,
                    message: 'Notificação enviada com sucesso!'
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Notificação não enviada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 404,
                message: 'Notificação não enviada!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAllCalled() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listAllCalled').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhum chamado encontrado!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAllCalledPendent() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listCalledPendent').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhum chamado encontrado!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAllCalledStatus() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/listCalledStatus').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhum status encontrado!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function updateCalled(idCalled: string, idUser: string, message: string, status: number, statusOld: string, messageOld: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (statusOld === 'Novo') {
        await api.post('/administrator/newCalled', {
            idCalled,
            idUser,
            message: messageOld,
        }).then((result) => {
            if (result.status !== 201) {
                response = {
                    statusCode: 404,
                    message: 'Nenhum chamado encontrado!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    }

    if (!response.message) {
        try {
            await api.post('/administrator/updateCalled', {
                idCalled,
                idUser,
                message,
                status
            }).then((result) => {
                if (result.status === 200) {
                    response = {
                        statusCode: 200
                    }
                } else {
                    response = {
                        statusCode: 404,
                        message: 'Nenhum chamado encontrado!'
                    }
                }

            }).catch(() => {
                response = {
                    statusCode: 500,
                    message: 'Falha interna!'
                }
            })
        } catch {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        }
    }

    return response;
}

async function validateRecipeTitle(title: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/validateRecipeTitle', {
            params: {
                title: title
            }
        }).then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma receita encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function listAllLogs() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/log').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhum log encontrado!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function recipeViewList() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/administrator/recipeViewList').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhum recipe view list encontrado!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

export {
    administratorTotalizers,
    administratorListIngredientCategories,
    administratorCreateMeasure,
    administratorEditMeasure,
    administratorDeleteMeasure,
    administratorListMeasures,
    administratorListRecipeCategories,
    administratorListRecipesPending,
    administratorApproveRecipe,
    administratorDeclineRecipe,
    administratorListUsers,
    administratorCreateIngredientCategory,
    administratorDeleteIngredientCategory,
    administratorCreateRecipeCategory,
    administratorActivedRecipeCategory,
    administratorListIngredients,
    administratorCreateIngredient,
    administratorListPlans,
    administratorPushNotification,
    listAllCalled,
    listAllCalledPendent,
    updateCalled,
    listAllCalledStatus,
    validateRecipeTitle,
    listAllLogs,
    recipeViewList
}