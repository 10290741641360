import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import HeartSuit from '../../images/Figmoji/heart-suit.png';
import Cooking from '../../images/Figmoji/cooking.png';
import Watch from '../../images/Figmoji/watch.png';

import Header from '../../components/Header';
import CardRecipeItem from '../../components/CardRecipeItem';
import Footer from '../../components/Footer';
import CardModePreparation from '../../components/CardModePreparation';
import Loading from '../../components/Loading';

import { createEvaluation, createFavorite, createViewRecipe, deleteFavorite, evaluationConsultation, favoriteConsultation, updateEvaluation, viewRecipe } from '../../dao/RecipeDAO';
import { RecipeDTO } from '../../dto/RecipeDTO';
import { RecipeModePreparationDTO } from '../../dto/RecipeModePreparationDTO';
import { IngredientDTO } from '../../dto/IngredientDTO';

import classes from './style.module.css';
import { converterMinutes } from '../../hooks/converterMinutes';
import { useAuth } from '../../hooks/auth';

import HeaderNew from '../../components/HeaderNew';
import { CallBell, Clock, Heart, IconContext, Star, Tote } from '@phosphor-icons/react';
import { PuffLoader } from 'react-spinners';

const ViewRecipe = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [buttonSelected, setButtonSelected] = useState<'INGREDIENTES' | 'MODO DE PREPARO'>('INGREDIENTES');
    const [recipe, setRecipe] = useState<RecipeDTO | null>(null);
    const [listIngredientsUser, setListIngredientsUser] = useState<IngredientDTO[]>([]);
    const [totalIngredientsUser, setTotalIngredientsUser] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFavorite, setIsFavorite] = useState(false)
    const [evaluation, setEvaluation] = useState<number>(0)
    const [loadingHandleEvaluation, setLoadingHandleEvaluation] = useState(false)
    const [loadingHandleFavorite, setLoadingHandleFavorite] = useState(false)
    const [listIngredients, setListIngredients] = useState<any[]>([])
    const [listModePreparation, setListModePreparation] = useState<any[]>([])
    const [scrollPosition, setScrollPosition] = useState(0);

    async function searchRecipe() {
        if (id !== undefined) {
            const result = await viewRecipe(id);

            if (result.statusCode === 200) {
                setRecipe(result.data)
                await createViewRecipe(id, user.id)
                return result.data
            } else {
                if (result.statusCode === 404) {
                    return navigate('/error/404')
                } else {
                    return navigate('/error/500')
                }
            }
        } else {
            return navigate('/error/404')
        }
    }

    function compare(a: RecipeModePreparationDTO, b: RecipeModePreparationDTO) {
        if (a.position < b.position)
            return -1;
        if (a.position > b.position)
            return 1;
        return 0;
    }

    async function loadInitial() {
        const listIngredientsUserStorage = localStorage.getItem('@letscook.ingredients')

        if (listIngredientsUserStorage != null) {
            setListIngredientsUser(JSON.parse(listIngredientsUserStorage))
        }

        const result = await searchRecipe()
        let groupedIngredients = groupBy(result.ingredients, 'step')
        let groupedModePreparation = groupBy(result.modePreparation, 'step')

        if (Object.keys(groupedIngredients)[0] == '') {
            groupedIngredients = groupedIngredients['']
        } else {
            groupedIngredients = Array(groupedIngredients)
        }

        if (Object.keys(groupedModePreparation)[0] == '') {
            groupedModePreparation = groupedModePreparation['']
        } else {
            groupedModePreparation = Array(groupedModePreparation)
        }

        setListIngredients(groupedIngredients)
        setListModePreparation(groupedModePreparation)

        setTimeout(() => {
            if (!result) {
                return navigate(-1)
            } else {
                setLoading(false)
            }
        }, 1000);
    }

    async function handleEvaluation(value: number) {
        if (id && user.id) {
            setLoadingHandleEvaluation(true)
            if (evaluation === 0) {
                await createEvaluation(value, id, user.id)
            } else {
                await updateEvaluation(value, id, user.id)
            }
            setEvaluation(value)
            setLoadingHandleEvaluation(false)
        }
    }

    async function handleFavorite() {
        setLoadingHandleFavorite(true)
        if (id && user.id) {
            if (!isFavorite) {
                await createFavorite(String(id), user.id)
            } else {
                await deleteFavorite(String(id), user.id)
            }
            setIsFavorite(!isFavorite)
        }
        setLoadingHandleFavorite(false)
    }

    function groupBy(array: any, key: any) {
        return array.reduce((acc: any, item: any) => ({
            ...acc,
            [item[key]]: [...(acc[item[key]] ?? []), item],
        }),
            {})
    }

    useEffect(() => {
        async function loadEvaluation() {
            if (user.id && id !== undefined) {
                const response = await evaluationConsultation(id, user.id)

                if (response.statusCode === 200) {
                    if (Object.keys(response.data).length > 0) {
                        setEvaluation(response.data[0].evaluation)
                    }
                }
            }
        }

        async function loadFavorite() {
            if (user.id && id !== undefined) {
                const response = await favoriteConsultation(id, user.id)

                if (response.statusCode === 200) {
                    if (Object.keys(response.data).length > 0) {
                        setIsFavorite(true)
                    }
                }
            }
        }

        loadEvaluation()
        loadFavorite()
    }, [user.id])

    useEffect(() => {
        loadInitial()
    }, [])

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset);
        }
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    return (
        <>
            {loading ? <Loading text='Preparando detalhes da receita...' /> :
                <body>
                    <aside>
                        <div className={classes.container}>
                            {user.id ?
                                <HeaderNew buttonRight={{ icon: <Favorite isFavorite={isFavorite} loading={loadingHandleFavorite} />, onClick: handleFavorite }} />
                                :
                                <HeaderNew />
                            }
                            <div className={classes.contentPhoto}>
                                <img src={recipe?.photo} className={classes.photo} />
                            </div>
                            <div className={scrollPosition > 80 ? classes.contentScroll : classes.content}>
                                <div className={classes.contentRecipe}>
                                    <div className={classes.infoContainer}>
                                        <p className={classes.infoTitle}>{recipe?.title}</p>
                                        <p className={classes.infoSubTitle}>enviado por {recipe?.user?.name}</p>
                                        <div className={classes.infoDetailsContainer}>
                                            <InfoDetails icon={<Star />} text={String(recipe?.evaluation?.amountAssessments ?? '5.0')} />
                                            <InfoDetails icon={<Clock />} text={converterMinutes(recipe?.time)} />
                                            <InfoDetails icon={<CallBell />} text={String(recipe?.portions)} />
                                            {(Object.keys(listIngredientsUser).length > 0) &&
                                                <InfoDetails icon={<Tote />} text={`${totalIngredientsUser} / ${recipe?.countIngredients}`} />
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.buttonsContainer}>
                                        <ButtonMenu
                                            text="INGREDIENTES"
                                            selected={buttonSelected === 'INGREDIENTES'}
                                            onClick={() => setButtonSelected('INGREDIENTES')}
                                        />
                                        <ButtonMenu
                                            text="MODO DE PREPARO"
                                            selected={buttonSelected === 'MODO DE PREPARO'}
                                            onClick={() => setButtonSelected('MODO DE PREPARO')}
                                        />
                                    </div>
                                    <div className={classes.detailsContainer}>
                                        {
                                            buttonSelected === 'INGREDIENTES' &&
                                            <>
                                                {listIngredients[0].step !== '' ?
                                                    <>
                                                        {listIngredients.map((item, index) => {
                                                            const keys = Object.keys(listIngredients[0])
                                                            return (
                                                                keys.map((key, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <p className={classes.titleEtapa}>{keys[index].toUpperCase()}</p>
                                                                            <div className={classes.containerIngredients}>
                                                                                {listIngredients[0][key].map((ingredient: any, index: any) => {
                                                                                    let selected = listIngredientsUser.findIndex(obj => obj.id == ingredient.id);
                                                                                    return (
                                                                                        <CardRecipeItem
                                                                                            key={index}
                                                                                            ingredient={ingredient}
                                                                                            selected={selected >= 0}
                                                                                        />
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <div className={classes.containerIngredients}>
                                                        {(listIngredients !== null) &&
                                                            listIngredients.map((ingredient, index) => {
                                                                let selected = listIngredientsUser.findIndex(obj => obj.id == ingredient.id);
                                                                return (
                                                                    <CardRecipeItem
                                                                        key={index}
                                                                        ingredient={ingredient}
                                                                        selected={selected >= 0}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                        {
                                            buttonSelected === 'MODO DE PREPARO' &&
                                            <div className={classes.containerMethodOfPreparation}>
                                                {listModePreparation[0].step !== '' ?
                                                    <>
                                                        {listModePreparation.map((item, index) => {
                                                            const keys = Object.keys(listModePreparation[0])
                                                            return (
                                                                keys.map((key, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <p className={classes.titleEtapa}>{keys[index].toUpperCase()}</p>
                                                                            <div className={classes.containerIngredients}>
                                                                                {listModePreparation[0][key].sort(compare).map((modePreparation: any, index: any) => {
                                                                                    return (
                                                                                        <CardModePreparation
                                                                                            key={index}
                                                                                            modePreparation={modePreparation}
                                                                                        />
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )
                                                        })
                                                        }
                                                    </>
                                                    :
                                                    <div className={classes.containerIngredients}>
                                                        {listModePreparation !== null &&
                                                            listModePreparation.sort(compare).map((modePreparation, index) => {
                                                                return (
                                                                    <CardModePreparation
                                                                        key={index}
                                                                        modePreparation={modePreparation}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </body>
            }
        </>
    )
}

interface IInfoDetails {
    icon: any;
    text: string;
}

function InfoDetails({ icon, text }: IInfoDetails) {
    return (
        <div className={classes.infoDetailsContent}>
            <IconContext.Provider value={{ size: 20, color: '#FF6E00' }} >
                {icon}
            </IconContext.Provider>
            <p className={classes.infoDetailsText}>{text}</p>
        </div>
    )
}

interface IButtonMenu extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    selected: boolean;
}

function ButtonMenu({ text, selected, ...props }: IButtonMenu) {
    return (
        <button className={selected ? classes.buttonsButtonSelected : classes.buttonsButton} {...props}>
            <p className={selected ? classes.buttonsTextSelected : classes.buttonsText}>{text}</p>
        </button>
    )
}

interface IFavorite {
    isFavorite: boolean;
    loading: boolean;
}

function Favorite({ isFavorite, loading }: IFavorite) {
    return (
        <>
            {loading ?
                <PuffLoader size={32} color="#FF6E00" />
                :
                <>
                    {isFavorite ?
                        <Heart weight='fill' />
                        :
                        <Heart />
                    }
                </>
            }
        </>
    )
}

export default ViewRecipe;