import { InputHTMLAttributes, useEffect, useState } from "react";
import { IconContext, EyeSlash, Eye } from "@phosphor-icons/react";

import classes from './style.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    icon: any;
    value: string;
    type?: string;
    marginTop?: boolean;
    marginBottom?: boolean;
    shadow?: boolean;
}

const Input = ({
    icon,
    marginTop,
    marginBottom,
    value,
    type = 'text',
    shadow = false,
    ...props
}: Props) => {
    const [typeInput, setTypeInput] = useState(type);
    const [typeInputPassword, setTypeInputPassword] = useState(type)
    const [isFocused, setIsFocused] = useState(false);

    function handleInputFocus() {
        setIsFocused(!isFocused)
    }

    function handleShowPassword() {
        typeInputPassword === 'password' ? setTypeInputPassword('text') : setTypeInputPassword('password')
    }

    return (
        <div className={`
                ${classes.container}
                ${marginTop && classes.marginTop}
                ${marginBottom && classes.marginBottom}
                ${shadow && classes.shadow}
            `}
        >
            <div className={classes.input}>
                <div className={classes.contentIcon}>
                    <IconContext.Provider
                        value={{
                            size: 32,
                            color: isFocused || !!value ? '#FF6E00' : '#AEAEB3'
                        }}
                    >
                        {icon}
                    </IconContext.Provider>
                </div>
                <input
                    onFocus={handleInputFocus}
                    onBlur={handleInputFocus}
                    value={value}
                    type={typeInputPassword}
                    {...props}
                />
                {typeInput === 'password' &&
                    <button className={classes.contentIconPassword} onClick={() => handleShowPassword()}>
                        {typeInputPassword === 'password' ?
                            <EyeSlash size={25} color='#AEAEB3' />
                            :
                            <Eye size={25} color='#AEAEB3' />
                        }
                    </button>
                }
            </div>
            {
                (isFocused || !!value) &&
                <div className={classes.line} />
            }
        </div>
    )
}

export default Input;