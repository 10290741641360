import { useNavigate } from 'react-router-dom';
import { CaretLeft, IconContext } from '@phosphor-icons/react';

import { useAuth } from '../../hooks/auth';

import classes from './style.module.css';

interface IButtonRight {
    icon: any;
    onClick: () => void;
}

interface IHeaderNew {
    buttonRight?: IButtonRight;
    title?: string;
    newAccount?: boolean;
    activationCode?: boolean;
}

const HeaderNew = ({ buttonRight, title, newAccount, activationCode }: IHeaderNew) => {
    const { logoutUser } = useAuth();
    const navigate = useNavigate();

    function handleBack() {
        { activationCode && logoutUser() }
        return navigate(-1);
    }

    return (
        <div className={classes.container}>
            <Button icon={<CaretLeft />} onClick={handleBack} />
            {newAccount ?
                <button className={classes.buttonNewAccount} onClick={() => navigate('/newAccount')}>
                    <p className={classes.buttonNewAccountText}>Criar Conta</p>
                </button>
                :
                <>
                    {buttonRight ?
                        <Button icon={buttonRight.icon} onClick={buttonRight.onClick} />
                        :
                        <>
                            {title &&
                                <div className={classes.titleContent}>
                                    <p className={classes.title}>{title}</p>
                                </div>
                            }
                        </>
                    }
                </>
            }
        </div >
    )
}

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: any;
}

function Button({ icon, ...props }: IButton) {
    return (
        <button className={classes.button} {...props}>
            <IconContext.Provider value={{ size: 32, color: '#FF6E00' }}>
                {icon}
            </IconContext.Provider>
        </button>
    )
}

export default HeaderNew;