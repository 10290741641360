import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

import { ResponseDTO } from "../dto/ResponseDTO";
import { UserCreateDTO } from "../dto/UserCreateDTO";

import { convertDateInput, validateDateOfBirth, validateMail } from '../hooks/validateForm'

import { api } from "../services/apiClient";
import { parseCookies } from 'nookies';
import { IngredientDTO } from '../dto/IngredientDTO';
import { convertBase64 } from '../hooks/convertBase64';

async function newAccount(user: UserCreateDTO) {
    let response: ResponseDTO = {} as ResponseDTO;
    let base64Photo: string = '';
    let base64Certificate: string = '';
    let id = uuidv4();
    const passwordCrypto = CryptoJS.MD5(user.password);

    if (!response.statusCode) {
        if (!user.name) {
            response = {
                statusCode: 406,
                message: 'Preencha o nome completo!'
            }
        }
    }

    if (!response.statusCode) {
        if (user.name.indexOf(' ') === -1) {
            response = {
                statusCode: 406,
                message: 'Nome inválido!'
            }
        }
    }

    if (!response.statusCode) {
        if (!user.email) {
            response = {
                statusCode: 406,
                message: 'Preencha o e-mail!'
            }
        }
    }

    if (!response.statusCode) {
        if (!validateMail(user.email)) {
            response = {
                statusCode: 406,
                message: 'E-mail inválido!'
            }
        }
    }

    if (!response.statusCode) {
        if (!user.password) {
            response = {
                statusCode: 406,
                message: 'Preencha a senha!'
            }
        }
    }

    if (!response.statusCode) {
        if (!user.birthDate) {
            response = {
                statusCode: 406,
                message: 'Preencha a data de nascimento!'
            }
        }
    }

    if (!response.statusCode) {
        if (!validateDateOfBirth(user.birthDate)) {
            response = {
                statusCode: 406,
                message: 'Data de nascimento inválida!'
            }
        }
    }

    if (!response.statusCode) {
        if (!user.state) {
            response = {
                statusCode: 406,
                message: 'Selecione o seu estado!'
            }
        }
    }

    if (!response.statusCode) {
        if (!user.city) {
            response = {
                statusCode: 406,
                message: 'Selecione a sua cidade!'
            }
        }
    }

    if (!response.statusCode) {
        if (user.isNutritionist) {
            if (!user.certificate.name) {
                response = {
                    statusCode: 406,
                    message: 'Anexe seu certificado de nutricionista.'
                }
            }
        }
    }

    if (!response.statusCode) {
        if (!user.termsOfUse) {
            response = {
                statusCode: 406,
                message: 'É obrigatório ler os termos e aceitar!'
            }
        }
    }

    // VERIFICA SE O E-MAIL É UNICO
    if (!response.statusCode) {
        await api.post('/user/searchEmail', {
            email: user.email
        }).then((result) => {
            if (result.status === 200) {
                response = {
                    statusCode: 406,
                    message: 'E-mail já cadastrado!'
                }
            }
        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        });
    }

    // MONTA E FAZ O ENVIO DO CERTIFICADO
    if (!response.statusCode) {
        if (user.isNutritionist) {
            base64Certificate = String(await convertBase64(user.certificate));
            // const certificateFile = new FormData();
            // certificateFile.append('file', user.certificate as any);

            // await api.post('/user/uploadCertificate', certificateFile, {
            //     headers: {
            //         "content-type": "multipart/form-data"
            //     }
            // }).then((result) => {
            //     if (result.status !== 201) {
            //         response = {
            //             statusCode: 500,
            //             message: 'Falha de comunicação no servidor de certificados!'
            //         }
            //     }
            //     urlCertificate = result.data;
            // }).catch(() => {
            //     response = {
            //         statusCode: 500,
            //         message: 'Arquivo inválido! Tamanho máximo 5MB e formatos aceitos: jpg, jpeg, png!'
            //     }
            // });
        }
    }

    // MONTA E FAZ O UPLOAD DA FOTO
    if (!response.statusCode) {
        if (!!user.photo.name) {
            base64Photo = String(await convertBase64(user.photo));
            // const photoFile = new FormData();
            // photoFile.append('file', user.photo as any);

            // await api.post('/user/uploadPhoto', photoFile, {
            //     headers: {
            //         "Content-Type": "multipart/form-data"
            //     }
            // }).then((result) => {
            //     if (result.status !== 201) {
            //         response = {
            //             statusCode: 500,
            //             message: 'Falha de comunicação no servidor de imagens!'
            //         }
            //     }
            //     urlPhoto = result.data;
            // }).catch((err) => {
            //     response = {
            //         statusCode: 500,
            //         message: 'Arquivo inválido! Tamanho máximo 5MB e formatos aceitos: jpg, jpeg, png!'
            //     }
            // });
        }
    }

    // REGISTRA A NOVA CONTA
    if (!response.statusCode) {
        await api.post('/user/create', {
            id,
            photo: base64Photo,
            name: user.name,
            email: user.email,
            password: String(passwordCrypto),
            birthDate: convertDateInput(user.birthDate),
            state: user.state,
            city: user.city,
            isNutritionist: user.isNutritionist,
            certificate: base64Certificate
        }).then((result) => {
            response = {
                statusCode: 201,
                message: 'Conta criada com sucesso!'
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao criar a conta!'
            }
        });
    }

    return response
}

async function activateAccount(code: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        if (!response.statusCode) {
            await api.post('/user/activated', {
                code
            }).then((result) => {

                if (result.status !== 201) {
                    response = {
                        statusCode: 404,
                        message: 'Código inválido!'
                    }
                } else {
                    response = {
                        statusCode: 201,
                        message: 'Usuário ativado!'
                    }
                }
            }).catch(() => {
                response = {
                    statusCode: 500,
                    message: 'Falha interna!'
                }
            });
        }
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function authentication() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        const { '@letscook.token': token } = parseCookies();

        if (token) {
            await api.get('/user/me').then((result: any) => {

                const { id, photo, name, birthDate, state, city, category, isActive, token } = result.data;

                response = {
                    statusCode: 200,
                    data: {
                        id,
                        photo,
                        name,
                        birthDate,
                        state,
                        city,
                        category,
                        isActive,
                        token
                    }
                }

            }).catch(() => {
                response = {
                    statusCode: 404,
                    message: 'Usuário inválido!'
                }
            })
        } else {
            response = {
                statusCode: 404,
                message: 'Usuário inválido!'
            }
        }
    } catch (error) {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function saveSelectedIngredients(ingredients: IngredientDTO[]) {
    const { '@letscook.token': token } = parseCookies();
    let response: ResponseDTO = {} as ResponseDTO;

    localStorage.setItem('@letscook.ingredients', JSON.stringify(ingredients))

    if (token) {
        try {
            await api.post('/user/saveSelectedIngredients', {
                ingredients
            }).then((result) => {
                if (result.status === 201) {
                    response = {
                        statusCode: 200,
                        message: 'Ingredients salvos com sucesso.'
                    }
                }
            }).catch(() => {
                response = {
                    statusCode: 500,
                    message: 'Falha ao salvar lista.'
                }
            });
        } catch {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        }
    } else {
        response = {
            statusCode: 200,
            message: 'Ingredients salvos com sucesso.'
        }
    }

    return response;
}

async function recoverSelectedIngredients() {
    const { '@letscook.token': token } = parseCookies();
    let response: ResponseDTO = {} as ResponseDTO;

    const ingredients = localStorage.getItem('@letscook.ingredients')

    if (token && ingredients == null) {
        try {
            await api.get('/user/recoverSelectedIngredients')
                .then((result) => {
                    if (result.status === 201) {
                        response = {
                            statusCode: 200,
                            message: result.data
                        }

                        localStorage.setItem('@letscook.ingredients', JSON.stringify(result.data))
                    }
                }).catch(() => {
                    response = {
                        statusCode: 500,
                        message: 'Falha ao buscar lista.'
                    }
                });
        } catch {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        }
    } else {
        const ingredients = localStorage.getItem('@letscook.ingredients')

        if (ingredients !== null) {
            response = {
                statusCode: 200,
                message: JSON.parse(ingredients)
            }
        } else {
            response = {
                statusCode: 200,
                message: ''
            }
        }

    }

    return response;
}

async function forgotPasswordStepOne(email: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (!response.statusCode) {
        if (!email) {
            response = {
                statusCode: 406,
                message: 'Preencha o e-mail!'
            }
        }
    }

    if (!response.statusCode) {
        if (!validateMail(email)) {
            response = {
                statusCode: 406,
                message: 'E-mail inválido!'
            }
        }
    }

    if (!response.statusCode) {
        await api.post('/user/recoverPasswordStepOne', {
            email
        }).then((result) => {
            if (result.status === 201) {
                response = {
                    statusCode: 200,
                    message: 'E-mail enviado com sucesso!',
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'E-mail não encontrado!'
                }
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao enviar o e-mail!'
            }
        });
    }

    return response
}

async function forgotPasswordStepTwo(password: string, passwordTwo: string, id: string) {
    let response: ResponseDTO = {} as ResponseDTO;
    const passwordCrypto = CryptoJS.MD5(password);

    if (!response.statusCode) {
        if (!password) {
            response = {
                statusCode: 406,
                message: 'Preencha o campo senha!'
            }
        }
    }

    if (!response.statusCode) {
        if (!passwordTwo) {
            response = {
                statusCode: 406,
                message: 'Preencha o campo repetir senha!'
            }
        }
    }

    if (!response.statusCode) {
        if (password !== passwordTwo) {
            response = {
                statusCode: 406,
                message: 'Digite a mesma senha em ambos os campos de senha.'
            }
        }
    }

    if (!response.statusCode) {
        await api.post('/user/recoverPasswordStepTwo', {
            id,
            password: String(passwordCrypto),
        }).then((result) => {
            if (result.status === 201) {
                response = {
                    statusCode: 200,
                    message: 'Senha atualizada!'
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Falha ao recuperar a senha!'
                }
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha interna!'
            }
        });
    }

    return response
}

async function newCalled(type: string, description: string, idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    if (!response.statusCode) {
        if (!type) {
            response = {
                statusCode: 406,
                message: 'É obrigatório selecionar o tipo do chamado!'
            }
        }
    }

    if (!response.statusCode) {
        if (!description) {
            response = {
                statusCode: 406,
                message: 'É obrigatório descrever sobre o chamado!'
            }
        }
    }

    if (!response.statusCode) {
        await api.post('/user/called', {
            type,
            description,
            idUser
        }).then((result) => {
            response = {
                statusCode: 201,
                message: 'Enviado com sucesso!'
            }
        }).catch(() => {
            response = {
                statusCode: 400,
                message: 'Falha ao enviar!'
            }
        });
    }

    return response;
}

async function listCalled(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/user/called', {
            params: {
                id: id
            }
        }).then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhum chamado encontrado!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function deleteAccount(id: string, password: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    const passwordCrypto = CryptoJS.MD5(password);

    try {
        await api.post('/user/delete', {
            id,
            password: String(passwordCrypto)
        }).then((result) => {
            response = {
                statusCode: 200,
                message: 'Excluido com sucesso!'
            }
        }).catch((e) => {
            if (e.message === 'Request failed with status code 404') {
                response = {
                    statusCode: 400,
                    message: 'Senha inválida!'
                }
            } else {
                response = {
                    statusCode: 400,
                    message: 'Falha ao excluir a conta!'
                }
            }
        });

    } catch {
        response = {
            statusCode: 401,
            message: 'Senha inválida!'
        }
    }

    return response;
}

async function listNotification(idUser: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/user/notification', {
            params: {
                idUser: idUser
            }
        }).then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma notificação encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function viewNotification(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.put('/user/notification/view', {
            id
        }).then((result) => {
            if (result.status === 201) {
                response = {
                    statusCode: 200,
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma notificação encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

async function deleteNotification(id: string) {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.put('/user/notification/delete', {
            id
        }).then((result) => {
            if (result.status === 201) {
                response = {
                    statusCode: 200,
                }
            } else {
                response = {
                    statusCode: 404,
                    message: 'Nenhuma notificação encontrada!'
                }
            }

        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        })
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

export {
    newAccount,
    deleteAccount,
    activateAccount,
    authentication,
    saveSelectedIngredients,
    recoverSelectedIngredients,
    forgotPasswordStepOne,
    forgotPasswordStepTwo,
    newCalled,
    listCalled,
    listNotification,
    viewNotification,
    deleteNotification
};