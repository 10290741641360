import { Navigate } from "react-router-dom";
import { parseCookies } from "nookies";
import { useJwt } from "react-jwt";


export const ProtectedRoute = ({ children }: any) => {
  const { '@letscook.token': token } = parseCookies();
  const { isExpired } = useJwt(token);

  if (!token) {
    return <Navigate to="/error/401" />;
  }

  if(isExpired) {
    //deslogar
  }
  
  return children;
};