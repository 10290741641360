import { useEffect, useState } from "react";
import { Hamburger, IconContext, Pepper, PersonSimpleWalk, TextAlignJustify } from "@phosphor-icons/react";

import useWindowDimensions from "../../hooks/windowDimensions";

import classes from './style.module.css'
import { administratorTotalizers, listAllCalledPendent, updateCalled } from "../../dao/AdministratorDAO";
import { TotalizersDTO } from "../../dto/TotalizersDTO";
import Loading from "../../components/Loading";
import { CalledDTO } from "../../dto/CalledDTO";
import { dateFormat } from "../../hooks/dateFormat";
import Modal from "../../components/Modal";
import Textarea from "../../components/Textarea";
import ButtonNeutro from "../../components/ButtonNeutro";
import { useAlert } from "../../hooks/alert";
import { useAuth } from "../../hooks/auth";

const AdministratorDetails = () => {
    const { user } = useAuth();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [totalizers, setTotalizers] = useState<TotalizersDTO[]>([]);
    const [listCalledPendent, setListCalledPendent] = useState<CalledDTO[]>([]);
    const [calledSelected, setCalledSelected] = useState<CalledDTO>({} as CalledDTO);

    const [textCalled, setTextCalled] = useState('');

    async function loadInitial() {
        const [totalizers, called] = await Promise.all([administratorTotalizers(), listAllCalledPendent()])

        if (totalizers.statusCode === 200) {
            setTotalizers(totalizers.data)
        }

        if (called.statusCode === 200) {
            setListCalledPendent(called.data)
        }

        setLoading(false)
    }

    function handleViewCalled(called: CalledDTO) {
        setCalledSelected(called);
    }

    function handleCloseModal() {
        setCalledSelected({} as CalledDTO);
    }

    async function handleSaveCalled(type: 'Finalizar' | 'Responder') {
        const status = type === 'Finalizar' ? 5 : 2;

        const response = await updateCalled(calledSelected.id, user.id, textCalled, status, calledSelected.status, calledSelected.description);

        if (response.statusCode === 200) {
            setTextCalled('');
            handleCloseModal();
            setReload(true);

            return showAlert({
                message: 'Chamado atualizado com sucesso.',
                type: 'success'
            })
        } else {
            return showAlert({
                message: 'Falha ao atualizar o chamado.',
                type: 'warning'
            })
        }
    }

    useEffect(() => {
        if (reload) {
            loadInitial();
            setReload(false);
        }
    }, [reload])

    return (
        <body>
            {loading ? <Loading /> :
                <div className={classes.infoContainer}>
                    <div className={classes.infoContent}>
                        <CardInfo icon={<PersonSimpleWalk />} >
                            <p className={classes.cardInfoTitle}>Usuários</p>
                            <p className={classes.cardInfoText}>Ativos</p>
                            <p className={classes.cardInfoSubTitle}>{totalizers[0].userActive}</p>
                            <p className={classes.cardInfoText}>Pendentes</p>
                            <p className={classes.cardInfoSubTitle}>{totalizers[0].userPending}</p>
                        </CardInfo>
                        <CardInfo icon={<Hamburger />} >
                            <p className={classes.cardInfoTitle}>Receitas</p>
                            <p className={classes.cardInfoText}>Ativas</p>
                            <p className={classes.cardInfoSubTitle}>{totalizers[0].recipeApproved}</p>
                            <p className={classes.cardInfoText}>Pendentes</p>
                            <p className={classes.cardInfoSubTitle}>{totalizers[0].recipePending}</p>
                        </CardInfo>
                        <CardInfo icon={<Pepper />} >
                            <p className={classes.cardInfoTitle}>Ingredientes</p>
                            <p className={classes.cardInfoText}>Cadastrados</p>
                            <p className={classes.cardInfoSubTitle}>{totalizers[0].ingredientTotal}</p>
                        </CardInfo>
                    </div>
                    <div className={classes.calledContent}>
                        <p className={classes.calledTitle}>{`Chamados ( ${Object.keys(listCalledPendent).length} novos )`}</p>
                        <div className={classes.calledContentList}>
                            {listCalledPendent.map((item, index) => {
                                return (
                                    <button className={classes.calledContentListButton} onClick={() => handleViewCalled(item)} key={index}>
                                        <p className={classes.calledContentListButtonTitle}>{item.description}</p>
                                        <div className={classes.calledContentListButtonInfo}>
                                            <p className={classes.calledContentListButtonText}>{dateFormat(item.createdAt)} - {item.type}</p>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
            {calledSelected.id &&
                <Modal
                    title='Detalhes do chamado'
                    onRequestClose={handleCloseModal}
                    onRequestConfirm={() => handleSaveCalled('Responder')}
                    textButtonConfirm="Responder"
                    loading={loadingModal}
                >
                    <div className={classes.modalContent}>
                        <p className={classes.modalDescription}>
                            <b>Descrição do cliente:</b> {calledSelected.description}
                        </p>
                    </div>
                    <Textarea
                        id='refTextarea'
                        icon={<TextAlignJustify />}
                        value={textCalled}
                        onChange={(e) => setTextCalled(e.target.value)}
                        placeholder='Descrever a solução'
                        marginBottom
                    />
                    <ButtonNeutro
                        text="Finalizar Chamado"
                        onClick={() => handleSaveCalled('Finalizar')}
                    />
                </Modal>
            }
        </body>
    )
}


interface ICardInfo {
    icon: any;
    children: React.ReactNode;
}

function CardInfo({ icon, children }: ICardInfo) {
    const { width } = useWindowDimensions();

    return (
        <div className={classes.cardInfoContainer}>
            <IconContext.Provider value={{ size: width <= 600 ? 60 : 100, weight: "fill", color: '#FFFFFF' }}>
                {icon}
            </IconContext.Provider>
            <div className={classes.cardInfoContent}>
                {children}
            </div>
        </div>
    )
}

export default AdministratorDetails;