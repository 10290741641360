import Footer from '../../../components/Footer';
import Header from '../../../components/Header';

import classes from './style.module.css';

const WhoWeAre = () => {
    return (
        <div className={classes.container}>
            <div className={classes.containerHeader}>
                <Header back />
            </div>
            <div className={classes.body}>
                <div className={classes.content}>
                    <p className={classes.title}>Quem somos</p>
                    <div className={classes.line} />
                    <p>Bem-vindo ao Let's Cook, o seu destino para a culinária descomplicada e personalizada! Somos apaixonados por comida que acredita que cozinhar pode ser divertido, criativo e acessível a todos. Nosso objetivo é ajudar você a explorar o mundo da culinária e descobrir novos sabores, tudo isso de forma prática e conveniente.</p>

                    <p>No Let's Cook, compreendemos os desafios de encontrar a receita perfeita para os ingredientes que você tem em casa. É por isso que desenvolvemos uma plataforma intuitiva que permite buscar e descobrir receitas deliciosas e personalizadas com base nos ingredientes que você deseja utilizar.</p>

                    <p>Trabalhamos incansavelmente para criar um banco de dados abrangentes, que abrangem diversas cozinhas e preferências alimentares.</p>

                    <p>Ao usar o Let's Cook, basta inserir os ingredientes que você possui em sua despensa ou geladeira, e nossa inteligência artificial irá sugerir uma variedade de receitas que se encaixam perfeitamente no seu perfil. Quer esteja buscando uma refeição rápida para o jantar ou planejando um banquete especial, estamos aqui para ajudar você a criar pratos incríveis com o que já tem à mão.</p>

                    <p>No Let's Cook, acreditamos que a culinária é uma forma de expressão e conexão. É um momento para reunir amigos e familiares, criar memórias e compartilhar momentos especiais ao redor da mesa. Queremos que você se sinta inspirado a explorar novos sabores, experimentar técnicas diferentes e se divertir enquanto cozinha.</p>

                    <p>Agradecemos por escolher o Let's Cook como seu parceiro culinário. Junte-se a nós nesta jornada saborosa, onde você poderá descobrir, criar e saborear as delícias que a culinária tem a oferecer.</p>

                    <p>Vamos cozinhar juntos!</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default WhoWeAre;