import { ResponseDTO } from "../dto/ResponseDTO";

import { api } from "../services/apiClient";

async function listAll() {
    let response: ResponseDTO = {} as ResponseDTO;

    try {
        await api.get('/ingredient/listAll').then((result) => {
            if (Object.keys(result.data).length > 0) {
                response = {
                    statusCode: 200,
                    data: result.data
                }
            }
        }).catch(() => {
            response = {
                statusCode: 500,
                message: 'Falha interna!'
            }
        });
    } catch {
        response = {
            statusCode: 500,
            message: 'Falha interna!'
        }
    }

    return response;
}

export { listAll }