import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import TermOfUse from '../../../components/TermOfUse';

import classes from './style.module.css';

const TermsOfUse = () => {
    return (
        <div className={classes.container}>
            <div className={classes.containerHeader}>
                <Header back />
            </div>
            <div className={classes.body}>
                <div className={classes.content}>
                    <p className={classes.title}>Termos de uso</p>
                    <div className={classes.line} />
                    <TermOfUse />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TermsOfUse;