import { useEffect, useState } from 'react';
import { CaretRight, ChatDots, FolderOpen, IconContext, Question } from '@phosphor-icons/react';

import { useAuth } from '../../../hooks/auth';
import { useAlert } from '../../../hooks/alert';
import { dateFormat } from '../../../hooks/dateFormat';

import { CalledDTO } from '../../../dto/CalledDTO';
import { newCalled, listCalled } from '../../../dao/UserDAO';

import HeaderNew from '../../../components/HeaderNew';
import Loading from '../../../components/Loading';
import ButtonPrimary from '../../../components/ButtonPrimary';
import Modal from '../../../components/Modal';
import Dropdown from '../../../components/Dropdown';
import Textarea from '../../../components/Textarea';

import classes from './style.module.css'

const Help = () => {
    const { user } = useAuth();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [listTypeCalled, setListTypeCalled] = useState([{
        label: 'Reclamação',
        value: 'Reclamação'
    },
    {
        label: 'Sugestão',
        value: 'Sugestão'
    },
    {
        label: 'Elogio',
        value: 'Elogio'
    },
    {
        label: 'Solicitação',
        value: 'Solicitação'
    },
    {
        label: 'Denúncia',
        value: 'Denúncia'
    },
    {
        label: 'Reportar Bug',
        value: 'Reportar Bug'
    }])
    const [typeCalled, setTypeCalled] = useState('');
    const [descriptionCalled, setDescriptionCalled] = useState('');
    const [list, setList] = useState<CalledDTO[]>([]);

    function handleCancelModal() {
        setModal(false)
    }

    async function handleConfirmModal() {
        setLoading(true)

        const response = await newCalled(typeCalled, descriptionCalled, user.id);

        if (response.statusCode === 201) {
            setModal(false)
            await showAlert({
                message: 'Chamado registrado com sucesso.',
                type: 'success'
            })
            await loadInitial();
        } else if (response.statusCode === 406) {
            await showAlert({
                message: response?.message ?? 'Falha ao registrar o chamado.',
                type: 'error'
            })
        } else {
            await showAlert({
                message: 'Falha ao registrar o chamado.',
                type: 'warning'
            })
        }

        setLoading(false)
    }

    async function loadInitial() {
        const response = await listCalled(user.id);

        if (response.statusCode === 200) {
            setList(response.data)
        }

        setLoading(false)
    }

    useEffect(() => {
        loadInitial()
    }, [])


    return (
        <>
            {loading ? <Loading />
                :
                <body>
                    <div className={classes.container}>
                        <div className={classes.containerHeader}>
                            <div className={classes.contentHeader}>
                                <HeaderNew />
                            </div>
                        </div>
                        <div className={classes.containerBody}>
                            <div className={classes.contentBody}>
                                <p className={classes.title}>Meus Chamados</p>
                                <div className={classes.contentList}>
                                    {list.map((item, index) => {
                                        return (
                                            <ButtonCalled
                                                icon={<Question />}
                                                title={item.description}
                                                date={dateFormat(item.createdAt)}
                                                type={item.type}
                                                status={item.status}
                                                key={index}
                                            />
                                        )
                                    })}
                                </div>
                                <ButtonPrimary
                                    text="Abrir Chamado"
                                    marginTop
                                    onClick={() => setModal(true)}
                                />
                            </div>
                        </div>
                    </div>
                    {modal &&
                        <Modal
                            loading={loading}
                            title="Novo Chamado"
                            onRequestConfirm={handleConfirmModal}
                            textButtonConfirm="Enviar"
                            onRequestClose={handleCancelModal}
                        >
                            <Dropdown
                                icon={<FolderOpen />}
                                listData={listTypeCalled}
                                value={typeCalled}
                                onChange={e => setTypeCalled(e.target.value)}
                                placeholder='Selecione o tipo'
                                marginBottom
                            />
                            <Textarea
                                icon={<ChatDots />}
                                placeholder='Como podemos ajudar?'
                                onChange={e => setDescriptionCalled(e.target.value)}
                                value={descriptionCalled}
                                marginBottom={false}
                            />
                        </Modal>
                    }
                </body >
            }
        </>
    )
}


interface IButtonCalled {
    icon: any;
    title: string;
    date: string;
    type: string;
    status: string;
}

function ButtonCalled({ icon, title, date, type, status }: IButtonCalled) {
    return (
        <button className={classes.buttonContainer}>
            <IconContext.Provider value={{ size: 32, color: '#272727' }}>
                {icon}
            </IconContext.Provider>
            <div className={classes.buttonContent}>
                <p className={classes.buttonTitle}>{title}</p>
                <p className={classes.buttonText}>{`${date} - ${type} - ${status}`}</p>
            </div>
            <CaretRight size={20} color='#272727' />
        </button>
    )
}


export default Help;
