import { useEffect, useState } from 'react';

import { termsOfUse } from '../../dao/SystemDAO'

import Header from '../Header';

import classes from './style.module.css';
import Loading from '../Loading';

interface Term {
    title: string;
    text: string;
}

interface TermInfo {
    term: Term[];
    updateDate: string;
}

const TermOfUse = () => {
    const [term, setTerm] = useState<TermInfo>();

    function loadTermsOfUse() {
        const response = termsOfUse()
        setTerm(response)
    }

    useEffect(() => {
        loadTermsOfUse()
    }, [])

    return (
        <>
            {term === null ? <Loading /> :
                <div className={classes.container}>
                    <div className={classes.containerBody}>
                        {
                            term?.term.map((term, index) => {
                                return (
                                    <div key={index} className={classes.info} >
                                        {(term.title && index !== 0)&& <p className={classes.titleTop}>{term.title}</p>}
                                        {(term.title && index === 0) && <p className={classes.title}>{term.title}</p>}
                                        <p className={classes.text}>{term.text}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default TermOfUse;