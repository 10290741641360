import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChatCircleDots, DeviceMobileCamera, Eye, Hamburger, IconContext, Notepad, Pepper, Ruler, SignOut, Storefront, User } from "@phosphor-icons/react";

import useWindowDimensions from "../../hooks/windowDimensions";

import { ReactComponent as Logo } from '../../images/logo-orange.svg';
import { ReactComponent as LogoIcon } from '../../images/logo-icon.svg';

import classes from './style.module.css'
import { administratorTotalizers } from "../../dao/AdministratorDAO";
import { TotalizersDTO } from "../../dto/TotalizersDTO";

const Administrator = () => {
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const [reload, setReload] = useState(true);
    const [children, setChildren] = useState('/administrator/dashboard');
    const [totalizers, setTotalizers] = useState<TotalizersDTO[]>([]);

    function handleButton(route: string) {
        setReload(true)
        setChildren(route)
    }

    async function loadInitial() {
        const response = await administratorTotalizers();

        if (response.statusCode === 200) {
            setTotalizers(response.data)
        }

        console.log(response)
    }

    useEffect(() => {
        if (reload) {
            loadInitial();
            setReload(false);
        }
    }, [reload])

    return (
        <div className={classes.container}>
            <div className={classes.menu}>
                <button className={classes.contentLogo} onClick={() => handleButton('/administrator/dashboard')}>
                    {width <= 600 ?
                        <LogoIcon className={classes.logo} />
                        :
                        <Logo className={classes.logo} />
                    }
                </button>
                <div className={classes.contentMenu}>
                    <ButtonMenu
                        icon={<User />}
                        text="Usuários"
                        onClick={() => handleButton('/administrator/users')}
                        numberNotifications={totalizers[0]?.userPending ?? 0}
                    />
                    <ButtonMenu
                        icon={<Ruler />}
                        text="Medidas"
                        onClick={() => handleButton('/administrator/ingredient-measurements')}
                    />
                    <ButtonMenu
                        icon={<Pepper />}
                        text="Ingredientes"
                        onClick={() => handleButton('/administrator/ingredients')}
                    />
                    <ButtonMenu
                        icon={<Hamburger />}
                        text="Receitas"
                        onClick={() => handleButton('/administrator/recipe-pending')}
                        numberNotifications={totalizers[0]?.recipePending ?? 0}
                    />
                    <ButtonMenu
                        icon={<ChatCircleDots />}
                        text="Chamados"
                        onClick={() => handleButton('/administrator/called')}
                        numberNotifications={totalizers[0]?.calledPending ?? 0}
                    />
                    <ButtonMenu
                        icon={<Storefront />}
                        text="Planos"
                        onClick={() => handleButton('/administrator/plans')}
                    />
                    <ButtonMenu
                        icon={<DeviceMobileCamera />}
                        text="Notificações"
                        onClick={() => handleButton('/administrator/push-notification')}
                    />
                    <div className={classes.line} />
                    <ButtonMenu
                        icon={<Notepad />}
                        text="Logs"
                        onClick={() => handleButton('/administrator/log')}
                    />
                    <ButtonMenu
                        icon={<Eye />}
                        text="Receitas Visitadas"
                        onClick={() => handleButton('/administrator/recipeViewList')}
                    />
                </div>
                <ButtonMenu
                    icon={<SignOut />}
                    text="Sair do Painel"
                    onClick={() => navigate('/')}
                />
            </div>
            <iframe
                id="iframe"
                src={children}
                className={classes.content}
            />
        </div>
    )
}

interface IButtonMenu {
    icon: any;
    text: string;
    onClick: () => void;
    numberNotifications?: number;
}

function ButtonMenu({ icon, text, onClick, numberNotifications = 0 }: IButtonMenu) {
    return (
        <button
            className={classes.buttonMenu}
            onClick={onClick}
        >
            {numberNotifications > 0 &&
                <div className={classes.buttonMenuNotification}>
                    <p className={classes.buttonMenuNotificationText}>{numberNotifications}</p>
                </div>
            }
            <IconContext.Provider value={{ size: "30", color: '#272727' }}>
                {icon}
            </IconContext.Provider>
            <p className={classes.textMenu}>{text}</p>
        </button>
    )
}

export default Administrator;