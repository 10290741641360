import { useLayoutEffect, useState } from "react";
import { Camera, Check, Lightning, LightningSlash, PencilSimple, Plus, TextAa, Trash } from "@phosphor-icons/react";

import Header from "../../../components/Header";
import Loading from "../../../components/Loading";

import { administratorActivedRecipeCategory, administratorCreateRecipeCategory, administratorListRecipeCategories } from "../../../dao/AdministratorDAO";
import { RecipeCategoryDTO } from "../../../dto/RecipeCategoryDTO";

import classes from './style.module.css';
import { useAlert } from "../../../hooks/alert";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import InputAttachment from "../../../components/InputAttachment";
import CardStatus from "../../../components/CardStatus";
import AlertConfirm from "../../../components/AlertConfirm";

const RecipeCategories = () => {
    const { showAlert } = useAlert();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [listCategories, setListCategories] = useState<RecipeCategoryDTO[]>([]);
    const [photo, setPhoto] = useState<File>({} as File);
    const [photoUrl, setPhotoUrl] = useState('');
    const [titleCategory, setTitleCategory] = useState('');

    const handleChangePhoto = (e: any) => {
        const selectedFile = e.target.files[0];
        const objectUrl = URL.createObjectURL(selectedFile)

        setPhotoUrl(objectUrl)
        setPhoto(selectedFile)
    };

    async function loadRecipes() {
        const result = await administratorListRecipeCategories();

        if (result.statusCode === 200) {
            setListCategories(result.data)
        }

        setLoading(false)
    }

    function closeModal() {
        setIsVisibleModal(false);
        setTitleCategory('');
        setPhoto({} as File);
        setPhotoUrl('');
    }

    async function handleSaveNewCategory() {
        if (!photo.name) {
            return showAlert({
                message: 'Selecione uma foto.',
                type: 'error'

            })
        }

        if (titleCategory.length < 3) {
            return showAlert({
                message: 'Preencha o título da categoria.',
                type: 'error'

            })
        }

        setLoadingModal(true);

        const response = await administratorCreateRecipeCategory(photo, titleCategory);

        if (response.statusCode === 201) {
            setReload(true);
            setIsVisibleModal(false);
            setLoadingModal(false);
            setTitleCategory('');
            setPhoto({} as File);
            setPhotoUrl('');

            return showAlert({
                message: 'Categoria cadastrada com sucesso.',
                type: 'success'

            })
        } else {
            setLoadingModal(false);
            return showAlert({
                message: response.message ? response.message : 'Falha ao criar categoria.',
                type: 'warning'
            })
        }

    }

    async function handleUpdateStatus(id: string, status: boolean | undefined) {
        if (status !== undefined) {

            const response = await administratorActivedRecipeCategory(id, !status);

            if (response.statusCode === 201) {
                setReload(true);

                showAlert({
                    message: 'Categoria atualizada com sucesso.',
                    type: 'success'

                })
            } else {
                showAlert({
                    message: response.message ? response.message : 'Falha ao atualizar a categoria.',
                    type: 'warning'
                })
            }
        }
    }

    useLayoutEffect(() => {
        if (reload) {
            loadRecipes()
            setReload(false)
        }
    }, [reload])

    return (
        <>
            {loading ? <Loading text="Pesquisando categorias..." />
                :
                <div className={classes.container}>
                    <div className={classes.body}>
                        {listCategories.map((item, index) => {
                            return (
                                <div className={classes.item} key={index}>
                                    <div className={classes.photo}>
                                        <img src={item.photo} className={classes.photo} />
                                    </div>
                                    <p className={classes.text}>{item.label}</p>
                                    <div className={classes.itemStatus}>
                                        {item.isActive ?
                                            <CardStatus text="Ativo" type="green" />
                                            :
                                            <CardStatus text="Desativo" type="red" />
                                        }
                                        <div className={classes.itemMenu}>
                                            <button onClick={() => handleUpdateStatus(item.id, item.isActive)} className={classes.buttonStatus}>
                                                {item.isActive ? <LightningSlash size={25} color="#AEAEB3" /> : <Lightning size={25} color="#AEAEB3" />}
                                            </button>
                                            <button onClick={() => { }} className={classes.buttonStatus}>
                                                <PencilSimple size={25} color="#AEAEB3" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <button className={classes.buttonMenu} onClick={() => setIsVisibleModal(true)} >
                            <Plus size={30} color='#FFFFFF' weight="bold" />
                        </button>
                    </div>
                </div>
            }
            {isVisibleModal &&
                <Modal
                    title='Adicionar Categoria'
                    onRequestClose={closeModal}
                    onRequestConfirm={handleSaveNewCategory}
                    loading={loadingModal}
                >
                    <div className={classes.contentPhoto}>
                        {
                            photoUrl !== '' ?
                                <img
                                    src={photoUrl}
                                    className={classes.photo}
                                />
                                :
                                <>
                                    <Camera
                                        size={32}
                                        color='#272727'
                                    />
                                    <p className={classes.textPhoto}>
                                        Nenhuma foto selecionada.
                                    </p>
                                </>
                        }
                    </div>
                    <InputAttachment
                        value={photo.name}
                        onChange={e => handleChangePhoto(e)}
                        placeholder="Foto da categoria"
                        marginBottom
                    />
                    <Input
                        icon={<TextAa size={32} />}
                        value={titleCategory}
                        onChange={(e) => setTitleCategory(e.target.value)}
                        placeholder="Título da categoria"
                    />
                </Modal>
            }
        </>
    )
}

export default RecipeCategories;