import Footer from '../../../components/Footer';
import Header from '../../../components/Header';

import classes from './style.module.css';

const Contact = () => {
    return (
        <div className={classes.container}>
            <div className={classes.containerHeader}>
                <Header back />
            </div>
            <div className={classes.body}>
                <div className={classes.content}>
                    <p className={classes.title}>Contato</p>
                    <div className={classes.line} />
                    <p>Suas opiniões, dúvidas, críticas, elogios e sugestões são muito importantes para nossa equipe.</p>
                    <p>Todas as mensagens recebidas são lidas e nos ajudam a aprimorar os serviços oferecidos, porém pedimos sua compreensão para o fato de que devido ao grande número de e-mails recebidos diariamente, nem sempre nos é possível oferecer um suporte individual para todos os usuários do site.</p>
                    <p>Para entrar em contato, envie um e-mail para <a href='mailto:contato@letscook.com.br' className={classes.link}>contato@letscook.com.br</a>.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact;