import { useEffect, useState } from 'react';

import { listAllLogs } from '../../../dao/AdministratorDAO';

import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';

import { LogDTO } from '../../../dto/LogDTO';

import classes from './style.module.css';
import { dateFormat } from '../../../hooks/dateFormat';
import { hourFormat } from '../../../hooks/hourFormat';

const RegistryLog = () => {
    const [loading, setLoading] = useState(true);
    const [listLogs, setListLogs] = useState<LogDTO[]>([]);
    const [logSelected, setLogSelected] = useState<LogDTO>({} as LogDTO);

    function handleCloseModal() {
        setLogSelected({} as LogDTO);
    }

    function handleViewModal(item: LogDTO) {
        setLogSelected(item);
    }

    async function loadInitial() {
        const response = await listAllLogs();
        console.log(response)

        if (response.statusCode === 200) {
            setListLogs(response.data)
        }

        setLoading(false);
    }

    useEffect(() => {
        loadInitial();
    }, [])

    return (
        <>
            {loading ? <Loading text="Carregando logs..." /> :
                <div className={classes.container}>
                    <div className={classes.body}>
                        <h1>Called</h1>
                        <div className={classes.content}>
                            {listLogs.map((item, index) => {
                                return (
                                    <button className={classes.buttonCalled} onClick={() => handleViewModal(item)} key={index}>
                                        <div className={classes.buttonCalleContent}>
                                            <p className={classes.buttonCalledTitle}>{item.description}</p>
                                            <div>
                                                <p className={classes.buttonCalledText}>{item.name} - {dateFormat(item.createdAt)} {hourFormat(item.createdAt)}</p>
                                            </div>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
            {logSelected.id &&
                <Modal
                    title='Detalhes do log'
                    onRequestConfirm={handleCloseModal}
                    textButtonConfirm='Fechar'
                >
                    <div className={classes.modalForm}>
                        <div className={classes.modalContent}>
                            <p className={classes.modalDescription}><b>Usuário:</b> {logSelected.name}</p>
                            <p className={classes.modalDescription}><b>Descrição:</b> {logSelected.description}</p>
                            <p className={classes.modalDescription}><b>Data:</b> {dateFormat(logSelected.createdAt)} {hourFormat(logSelected.createdAt)}</p>
                        </div>
                        {logSelected.data &&
                            <>
                                <div className={classes.modalLine} />
                                <p className={classes.modalDescription}>{logSelected.data}</p>
                                <div className={classes.modalLine} />
                            </>
                        }
                    </div>
                </Modal>
            }
        </>
    )
}

export default RegistryLog;