import Footer from '../../../components/Footer';
import Header from '../../../components/Header';

import classes from './style.module.css';

const Advertise = () => {
    return (
        <div className={classes.container}>
            <div className={classes.containerHeader}>
                <Header back />
            </div>
            <div className={classes.body}>
                <div className={classes.content}>
                    <p className={classes.title}>Anuncie</p>
                    <div className={classes.line} />
                    <p>Para conhecer as opções de publicidade disponíveis no Let's Cook e conversar sobre possíveis parcerias, entre em contato por <a href='mailto:anuncie@letscook.com.br' className={classes.link}>anuncie@letscook.com.br</a>.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Advertise;