import { useAlert } from '../../hooks/alert';

import classes from './style.module.css';

interface AlertProps {
    visible: boolean;
    children: React.ReactNode;
}

const Alert = ({
    visible,
    children
}: AlertProps) => {
    const { message, type } = useAlert();
    const title = (type === 'success' && 'Sucesso') || (type === 'warning' && 'Ops!') || (type === 'error' && 'Atenção!')

    return (
        <div className={classes.container}>
            {children}
            {visible &&
                <div className={classes.containerAlert}>
                    <div className={classes.content}>
                        <div className={classes.containerHeader}>
                            <p className={classes.title}>
                                {title}
                            </p>
                        </div>
                        <p className={classes.descripton}>
                            {message}
                        </p>
                    </div>
                    <div className={`
                        ${type === 'success' && classes.success}
                        ${type === 'warning' && classes.warning}
                        ${type === 'error' && classes.error}
                    `}
                    />
                </div>
            }
        </div>
    )
}

export default Alert;