import { IconContext, Paperclip } from "@phosphor-icons/react";
import { InputHTMLAttributes, useState } from "react";

import classes from './style.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    value: string;
    marginTop?: boolean;
    marginBottom?: boolean;
    shadow?: boolean;
    placeholder: string;
}

const InputAttachment = ({
    marginTop,
    marginBottom,
    value,
    shadow = false,
    placeholder,
    ...props
}: Props) => {
    const [isFocused, setIsFocused] = useState(false);

    function handleInputFocus() {
        setIsFocused(!isFocused)
    }

    const handleInputPhotoClick = () => {
        const input = document.getElementById('file-input-attachment');

        if (input) {
            input.click();
        }
    };

    return (
        <button className={`
                ${classes.container}
                ${marginTop && classes.marginTop}
                ${marginBottom && classes.marginBottom}
                ${shadow && classes.shadow}
            `}
            onClick={handleInputPhotoClick}
        >
            <div className={classes.input}>
                <div className={classes.contentIcon}>
                    <IconContext.Provider
                        value={{
                            size: 32,
                            color: isFocused || !!value ? '#FF6E00' : '#AEAEB3'
                        }}
                    >
                        <Paperclip />
                    </IconContext.Provider>
                </div>
                <p className={`
                 ${value ? classes.text : classes.placeholder}   
                `}>
                    {value ? value : placeholder}
                </p>
                <input
                    type="file"
                    id="file-input-attachment"
                    style={{ display: "none" }}
                    onFocus={handleInputFocus}
                    onBlur={handleInputFocus}
                    {...props}
                />
            </div>
            {
                (isFocused || !!value) &&
                <div className={classes.line} />
            }
        </button>
    )
}

export default InputAttachment;