import { useEffect, useState } from 'react';
import { TextAa, TextAlignJustify } from '@phosphor-icons/react';

import Input from '../../../components/Input';
import ButtonPrimary from '../../../components/ButtonPrimary';
import Textarea from '../../../components/Textarea';

import { ReactComponent as Logo } from '../../../images/logo.svg';

import classes from './style.module.css';
import { useAlert } from '../../../hooks/alert';
import LoadingTransparent from '../../../components/LoadingTransparent';
import { administratorListUsers, administratorPushNotification } from '../../../dao/AdministratorDAO';
import { UserDTO } from '../../../dto/UserDTO';
import Loading from '../../../components/Loading';
import Checkbox from '../../../components/Checkbox';

interface UserProps {
    id: string;
    tokenFirebase: string;
}


const PushNotification = () => {
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [listUsers, setListUsers] = useState<UserDTO[]>([]);
    const [listUsersSelected, setListUsersSelected] = useState<UserProps[]>([]);

    async function handleSelectedUser(user: UserDTO) {
        let index = listUsersSelected.findIndex(obj => obj.id === user.id);

        if (index === -1) {
            if (user.tokenFirebase) {
                setListUsersSelected([...listUsersSelected, {
                    id: user.id,
                    tokenFirebase: user.tokenFirebase
                }])
            }
        } else {
            setListUsersSelected(listUsersSelected.filter((item) => item.id !== user.id))
        }
    }

    async function handleSend() {
        if (title.length < 3) {
            return showAlert({
                message: 'Digite um título.',
                type: 'error'
            })
        }

        if (message.length < 3) {
            return showAlert({
                message: 'Digite uma mensagem.',
                type: 'error'
            })
        }

        if (Object.keys(listUsersSelected).length > 0) {
            setLoading(true)

            listUsersSelected.map(async (user) => {
                await administratorPushNotification(user.id, title, message, user.tokenFirebase);
            })

            setTitle('');
            setMessage('');
            setListUsersSelected([]);

            showAlert({
                message: 'Notificações registradas no sistema.',
                type: 'success'
            })

            setLoading(false);
        } else {
            return showAlert({
                message: 'Selecione a lista de usuários.',
                type: 'error'
            })
        }
    }

    async function handleSelectedAll() {
        if (Object.keys(listUsers).length === Object.keys(listUsersSelected).length) {
            setListUsersSelected([])
        } else {
            let list: UserProps[] = [];
            listUsers.map((user) => {
                if (user.tokenFirebase) {
                    list.push({
                        id: user.id,
                        tokenFirebase: user.tokenFirebase
                    })
                }
            })
            setListUsersSelected(list)
        }
    }

    async function loadInitial() {
        const result = await administratorListUsers();

        if (result.statusCode === 200) {
            let list: UserDTO[] = [];
            result.data.map((user: UserDTO) => {
                if (user.tokenFirebase && user?.isActive !== -1) {
                    list.push(user)
                }
            })
            setListUsers(list)
        }

        setLoadingInitial(false)
    }

    useEffect(() => {
        loadInitial()
    }, [])

    return (
        <div className={classes.container}>
            {loadingInitial || loading ?
                <>
                    {loadingInitial && <Loading text='Carregando informações...' />}
                    {loading && <LoadingTransparent />}
                </>
                :
                <div className={classes.body}>
                    <div className={classes.form}>
                        <p className={classes.title}>Enviar Notificação no Celular</p>
                        <div className={classes.viewContent}>
                            <div className={classes.viewContainerPhoto}>
                                <Logo className={classes.viewPhoto} />
                            </div>
                            <div className={classes.viewContainerInfo}>
                                <p className={classes.infoTitle}>{title}</p>
                                <p className={classes.infoText}>{message}</p>
                            </div>
                        </div>
                        <Input
                            icon={<TextAa size={32} />}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Título"
                            marginBottom
                        />
                        <Textarea
                            id='refMethodOfPreparation'
                            icon={<TextAlignJustify />}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder='Mensagem'
                            marginBottom
                        />
                        <ButtonPrimary
                            text='ENVIAR NOTIFICAÇÃO'
                            onClick={handleSend}
                            marginTop={false}
                        />
                    </div>
                    <div className={classes.list}>
                        <div className={classes.listContent}>
                            <div className={classes.listHeader}>
                                <p className={classes.title}>Usuários</p>
                                <button onClick={() => handleSelectedAll()} className={classes.listHeaderButton}>
                                    <p className={classes.listHeaderButtonText}>
                                        {Object.keys(listUsers).length === Object.keys(listUsersSelected).length ? 'Desmarcar todos' : 'Marcar todos'}
                                    </p>
                                </button>
                            </div>
                            <div className={classes.listContainer}>
                                {listUsers.map((user, index) => {
                                    let selected = listUsersSelected.findIndex((obj: UserProps) => obj.id === user.id);
                                    return <Checkbox label={user.name} value={selected >= 0} onClick={() => handleSelectedUser(user)} key={index} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PushNotification;