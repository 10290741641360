import { useLayoutEffect, useState } from "react";
import { Plus, TextAa, Trash } from "@phosphor-icons/react";

import Header from "../../../components/Header";
import Loading from "../../../components/Loading";

import { administratorCreateIngredientCategory, administratorDeleteIngredientCategory, administratorListIngredientCategories } from "../../../dao/AdministratorDAO";
import { IngredientCategoryDTO } from "../../../dto/IngredientCategoryDTO";

import classes from './style.module.css';
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import { useAlert } from "../../../hooks/alert";

const IngredientCategories = () => {
    const { showAlert } = useAlert();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [listCategories, setListCategories] = useState<IngredientCategoryDTO[]>([]);
    const [titleCategory, setTitleCategory] = useState('');
    const [categorySelected, setCategorySelected] = useState<IngredientCategoryDTO>({} as IngredientCategoryDTO);

    async function loadRecipes() {
        const result = await administratorListIngredientCategories();

        if (result.statusCode === 200) {
            setListCategories(result.data)
        }

        setLoading(false)
    }

    function closeModal() {
        setIsVisibleModal(false);
        setTitleCategory('');
        setIsVisibleModalDelete(false);
        setCategorySelected({} as IngredientCategoryDTO);
    }

    async function handleSaveNewCategory() {
        if (titleCategory.length < 3) {
            return showAlert({
                message: 'Preencha o título da categoria.',
                type: 'error'

            })
        }

        setLoadingModal(true);

        const response = await administratorCreateIngredientCategory(titleCategory);

        if (response.statusCode === 201) {
            setIsVisibleModal(false);
            setLoadingModal(false);
            setTitleCategory('');
            setReload(true);

            return showAlert({
                message: 'Categoria cadastrada com sucesso.',
                type: 'success'

            })
        } else {
            setLoadingModal(false);
            return showAlert({
                message: response.message ? response.message : 'Falha ao criar categoria.',
                type: 'warning'
            })
        }

    }

    function openModalDelet(category: IngredientCategoryDTO) {
        setCategorySelected(category);
        setIsVisibleModalDelete(true);
    }

    async function handleDeleteCategory() {
        if (!categorySelected.id) {
            return showAlert({
                message: 'Falha ao deletar a categoria.',
                type: 'warning'

            })
        }

        const response = await administratorDeleteIngredientCategory(categorySelected.id);

        if (response.statusCode === 201) {
            setIsVisibleModalDelete(false);
            setCategorySelected({} as IngredientCategoryDTO);
            setReload(true);

            return showAlert({
                message: 'Categoria deletada com sucesso.',
                type: 'success'

            })
        } else {
            return showAlert({
                message: response.message ? response.message : 'Falha ao deletar categoria.',
                type: 'warning'
            })
        }
    }

    useLayoutEffect(() => {
        if (reload) {
            loadRecipes()
            setReload(false)
        }
    }, [reload])


    return (
        <>
            {loading ? <Loading text="Pesquisando categorias..." /> :
                <div className={classes.container}>
                    <div className={classes.containerHeader}>
                        <Header back />
                    </div>
                    <div className={classes.body}>
                        {listCategories.map((item, index) => {
                            return (
                                <div className={classes.item} key={index}>
                                    <p>{item.label} ({item.total})</p>
                                    <div className={classes.itemMenu}>
                                        <button className={classes.itemButton} onClick={() => openModalDelet(item)} >
                                            <Trash size={25} />
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                        <button
                            className={classes.buttonMenu}
                            onClick={() => setIsVisibleModal(true)}
                        >
                            <Plus size={30} color='#FFFFFF' weight="bold" />
                        </button>
                    </div>
                </div>
            }
            {isVisibleModal &&
                <Modal
                    title='Adicionar Categoria'
                    onRequestClose={closeModal}
                    onRequestConfirm={handleSaveNewCategory}
                    loading={loadingModal}
                >
                    <Input
                        icon={<TextAa size={32} />}
                        value={titleCategory}
                        onChange={(e) => setTitleCategory(e.target.value)}
                        placeholder="Título da categoria"
                    />
                </Modal>
            }
            {isVisibleModalDelete &&
                <Modal
                    title='Atenção'
                    onRequestClose={closeModal}
                    textButtonCancel="Não"
                    onRequestConfirm={handleDeleteCategory}
                    textButtonConfirm="Sim"
                    loading={false}
                >
                    <p>Deseja excluir a categoria <b>{categorySelected.label}</b>?</p>
                </Modal>
            }
        </>
    )
}

export default IngredientCategories;