import { createContext, ReactNode, useContext, useState } from "react";

interface IAlert {
    message: string;
    type: 'success' | 'warning' | 'error';
}

interface AlertContextData {
    visible: boolean;
    showAlert: ({ message, type }: IAlert) => Promise<void>;
    hideAlert: () => Promise<void>;
    message: string;
    type: string;
};

interface AlertProviderProps {
    children: ReactNode;
};

const AlertContext = createContext<AlertContextData>({} as AlertContextData);

function AlertProvider({ children }: AlertProviderProps) {
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');

    async function showAlert({ message, type }: IAlert) {
        setMessage(message)
        setType(type)
        setVisible(true)
        hideAlert()
    }

    async function hideAlert() {
        setTimeout(() => {
            setVisible(false)
        }, 5000);
    }

    return (
        <AlertContext.Provider
            value={{
                showAlert,
                hideAlert,
                visible,
                message,
                type
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}

function useAlert(): AlertContextData {
    const context = useContext(AlertContext);

    return context;
}

export { AlertProvider, useAlert };