import { IconContext } from "@phosphor-icons/react";
import { SelectHTMLAttributes, useState } from "react";

import classes from './style.module.css';

interface OptionProps {
    value: any;
    label: string;
}

interface DropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
    icon: any;
    value: string;
    marginTop?: boolean;
    marginBottom?: boolean;
    shadow?: boolean;
    listData: OptionProps[];
    placeholder: string;
}

const Dropdown = ({
    icon,
    marginTop,
    marginBottom,
    value,
    shadow = false,
    listData,
    placeholder,
    ...props
}: DropdownProps) => {
    const [isFocused, setIsFocused] = useState(false);

    function handleInputFocus() {
        setIsFocused(!isFocused)
    }

    return (
        <div className={`
                ${classes.container}
                ${marginTop && classes.marginTop}
                ${marginBottom && classes.marginBottom}
                ${shadow && classes.shadow}
            `}
        >
            <div className={classes.select}>
                <div className={classes.contentIcon}>
                    <IconContext.Provider
                        value={{
                            size: 32,
                            color: isFocused || !!value ? '#FF6E00' : '#AEAEB3'
                        }}
                    >
                        {icon}
                    </IconContext.Provider>
                </div>
                <select
                    defaultValue={value}
                    onChange={props.onChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputFocus}
                    {...props}
                    className={!value ? classes.placeholder : classes.notPlaceholder}
                >
                    <option value="" className={classes.placeholder} > {placeholder} </option>
                    {listData.map((option, index) => {
                        return (
                            <option
                                key={index}
                                value={typeof option.value == "object" ? JSON.stringify(option.value) : option.value}
                            >
                                {option.label}
                            </option>
                        )
                    })}
                </select>
            </div>
            {(isFocused || !!value) && <div className={classes.line} />}
        </div>
    )
}

export default Dropdown;