
import HeaderNew from '../../../../components/HeaderNew';

import classes from './style.module.css'

const MyRecipes = () => {

    return (
        <body>
            <div className={classes.container}>
                <div className={classes.containerHeader}>
                    <div className={classes.contentHeader}>
                        <HeaderNew title='Minhas Receitas' />
                    </div>
                </div>
                <div className={classes.containerBody}>
                    <div className={classes.contentBody}>
                        
                    </div>
                </div>
            </div>
        </body >
    )
}

export default MyRecipes;