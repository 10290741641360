import { SpinnerDotted } from 'spinners-react';

import classes from './style.module.css';

const LoadingTransparent = () => {
    return (
        <div className={classes.container}>
            <SpinnerDotted
                size={50}
                thickness={100}
                speed={100}
            />
        </div>
    )
}

export default LoadingTransparent;