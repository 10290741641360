import { useState } from 'react';
import { IngredientDTO } from '../../dto/IngredientDTO';
import classes from './style.module.css';

interface CardIngredientProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    ingredient: IngredientDTO;
    selected: boolean;
}

const ButtonIngredient = ({
    ingredient,
    selected,
    ...props
}: CardIngredientProps) => {
    return (
        <button
            {...props}
            className={selected ? classes.containerSelected : classes.container}
        >
            <div className={classes.containerPhoto}>
                <img
                    src={ingredient.photo}
                    className={classes.photo}
                />
            </div>
            <div className={classes.containerInfo}>
                <p className={selected ? classes.titleSelect : classes.title}>
                    {ingredient.title}
                </p>
            </div>
        </button>
    )
}

export default ButtonIngredient;