import { useEffect, useState } from 'react';

import { dateFormat } from '../../../hooks/dateFormat';
import { hourFormat } from '../../../hooks/hourFormat';

import { recipeViewList } from '../../../dao/AdministratorDAO';

import Loading from '../../../components/Loading';

import { RecipeViewDTO } from '../../../dto/RecipeViewDTO';

import classes from './style.module.css';

const RecipeViewList = () => {
    const [loading, setLoading] = useState(true);
    const [listRecipeView, setListRecipeView] = useState<RecipeViewDTO[]>([]);


    async function loadInitial() {
        const response = await recipeViewList();
        console.log(response)

        if (response.statusCode === 200) {
            setListRecipeView(response.data)
        }

        setLoading(false);
    }

    useEffect(() => {
        loadInitial();
    }, [])

    return (
        <>
            {loading ? <Loading text="Carregando logs..." /> :
                <div className={classes.container}>
                    <div className={classes.body}>
                        <h1>Called</h1>
                        <div className={classes.content}>
                            {listRecipeView.map((item, index) => {
                                return (
                                    <button className={classes.buttonCalled} onClick={() => {}} key={index}>
                                        <div className={classes.buttonCalleContent}>
                                            <p className={classes.buttonCalledTitle}>{item.title}</p>
                                            <div>
                                                <p className={classes.buttonCalledText}>{dateFormat(item.createdAt)} {hourFormat(item.createdAt)} {item.user?.name && `- ${item.user?.name}`}</p>
                                            </div>
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default RecipeViewList;