import { IngredientRecipeDTO } from '../../dto/IngredientRecipeDTO';
import classes from './style.module.css';

interface CardRecipeItemProps {
    ingredient: IngredientRecipeDTO;
    selected?: boolean;
    buttonRemove?: () => void;
}

const CardRecipeItem = ({
    ingredient,
    selected,
    buttonRemove
}: CardRecipeItemProps) => {
    return (
        <div className={selected ? classes.containerSelected : classes.container}>
            {buttonRemove && <button className={classes.buttonRemove} onClick={buttonRemove}><p className={classes.buttonRemoveText}>x</p></button>}
            <div className={classes.containerPhoto}>
                <img
                    src={ingredient.photo}
                    className={classes.photo}
                />
            </div>
            <div className={classes.containerInfo}>
                <p className={selected ? classes.titleSelected : classes.title}>
                    {ingredient.title} {ingredient.complement && `- ${ingredient.complement}`}
                </p>
                <p className={classes.textDetails}>
                    {ingredient.amount} {ingredient.measure.label}
                </p>
            </div>
        </div>
    )
}

export default CardRecipeItem;