import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnvelopeSimple, Key, Lock } from '@phosphor-icons/react';

import { useAlert } from '../../hooks/alert';

import { forgotPasswordStepOne, forgotPasswordStepTwo } from '../../dao/UserDAO';

import HeaderNew from '../../components/HeaderNew';
import Input from '../../components/Input';
import ButtonPrimary from '../../components/ButtonPrimary';

import classes from './style.module.css'

const RecoverPassword = () => {
    const { showAlert } = useAlert()
    const navigate = useNavigate();
    const [buttonLoading, setButtonLoading] = useState(false)
    const [idUser, setIdUser] = useState('')
    const [activationCode, setActivationCode] = useState('')
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    async function handleContinue() {
        setButtonLoading(true)

        if (step === 1) {
            const response = await forgotPasswordStepOne(email);

            setTimeout(() => {
                if (response.statusCode === 200) {
                    setActivationCode(response.data[0].activationCode)
                    setIdUser(response.data[0].id)
                    setStep(step + 1)
                    showAlert({ message: 'E-mail enviado com sucesso!', type: 'success' })
                } else {
                    showAlert({ message: response.message ? response.message : 'E-mail inválido!', type: 'warning' })
                }
                setButtonLoading(false)
            }, 1000);
        }

        if (step === 2) {
            setTimeout(() => {
                if (code === activationCode) {
                    setStep(step + 1)
                    showAlert({ message: 'Código válidado com sucesso!', type: 'success' })
                } else {
                    showAlert({ message: 'Código inválido!', type: 'warning' })
                }
                setButtonLoading(false)
            }, 5000);
        }

        if (step === 3) {
            const response = await forgotPasswordStepTwo(password, newPassword, idUser);

            setTimeout(() => {
                if (response.statusCode === 200) {
                    navigate('/login')
                    showAlert({ message: 'Senha atualizada!', type: 'success' })
                } else {
                    showAlert({ message: response.message ? response.message : 'Senha inválida!', type: 'warning' })
                }
                setButtonLoading(false)
            }, 1000);
        }

        if (step > 3) { navigate('/login') }
    }

    return (
        <body>
            <div className={classes.container}>
                <div className={classes.containerHeader}>
                    <div className={classes.contentHeader}>
                        <HeaderNew />
                    </div>
                </div>
                <div className={classes.containerBody}>
                    <div className={classes.contentBody}>
                    <div className={classes.containerForm}>
                        <div className={classes.contentFormTitle}>
                            <p className={classes.contentFormTitleText}>Recuperar Senha</p>
                        </div>
                        <div className={classes.contentProgress}>
                            <div className={step >= 1 ? classes.progress : classes.progressOpacity}>
                                <p className={step >= 1 ? classes.progressText : classes.progressTextOpacity}>1</p>
                            </div>
                            <div className={`${step > 1 ? classes.progressBar : classes.progressBarOpacity}`} />
                            <div className={step >= 2 ? classes.progress : classes.progressOpacity}>
                                <p className={step >= 2 ? classes.progressText : classes.progressTextOpacity}>2</p>
                            </div>
                            <div className={`${step > 2 ? classes.progressBar : classes.progressBarOpacity}`} />
                            <div className={step >= 3 ? classes.progress : classes.progressOpacity}>
                                <p className={step >= 3 ? classes.progressText : classes.progressTextOpacity}>3</p>
                            </div>
                        </div>
                        <div className={classes.contentTitle}>
                            {step === 1 && <p>Vou enviar um código para o email cadastrado e assim podemos prosseguir com a recuperação da sua senha.</p>}
                            {step === 2 && <p>Digite o código que foi enviado para o email cadastrado.</p>}
                            {step === 3 && <p>Cadastre sua nova senha.</p>}
                        </div>
                        <div className={classes.body}>
                            {step === 1 && <>
                                <Input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    icon={<EnvelopeSimple />}
                                    placeholder='E-mail'
                                />
                                <ButtonPrimary
                                    text='ENVIAR'
                                    onClick={handleContinue}
                                    disabled={buttonLoading}
                                    loading={buttonLoading}
                                />
                            </>}
                            {step === 2 && <>
                                <Input
                                    icon={<Key />}
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder='Código'
                                />
                                <ButtonPrimary
                                    text='VALIDAR'
                                    onClick={handleContinue}
                                    disabled={buttonLoading}
                                    loading={buttonLoading}
                                />
                            </>}
                            {step === 3 && <>
                                <div className={classes.contentFormPassword}>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type='password'
                                        icon={<Lock />}
                                        placeholder='Senha'
                                        marginBottom
                                        autoComplete="new-password"
                                        id="new-password"
                                    />
                                    <Input
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        type='password'
                                        icon={<Lock />}
                                        placeholder='Repetir Senha'
                                        autoComplete="new-password"
                                        id="new-password"
                                    />
                                </div>
                                <ButtonPrimary
                                    text='SALVAR'
                                    onClick={handleContinue}
                                    disabled={buttonLoading}
                                    loading={buttonLoading}
                                />
                            </>}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </body >
    )
}

export default RecoverPassword;