import { useEffect, useState } from 'react';

import HeaderNew from '../../../../components/HeaderNew';

import { useAuth } from '../../../../hooks/auth';
import { RecipeDTO } from '../../../../dto/RecipeDTO';
import { listAllFavorites } from '../../../../dao/RecipeDAO';

import classes from './style.module.css'
import Loading from '../../../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { Clock } from '@phosphor-icons/react';
import { converterMinutes } from '../../../../hooks/converterMinutes';

const FavoritesRecipes = () => {
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [listRecipes, setListRecipes] = useState<RecipeDTO[]>([]);

    async function loadInitial() {
        const result = await listAllFavorites(user.id);

        if (result.statusCode === 200) {
            setListRecipes(result.data);
        }

        setLoading(false)
    }

    useEffect(() => {
        loadInitial();
    }, [])

    return (
        <>
            {loading ? <Loading /> :
                <body>
                    <div className={classes.container}>
                        <div className={classes.containerHeader}>
                            <div className={classes.contentHeader}>
                                <HeaderNew title='Receitas Favoritas' />
                            </div>
                        </div>
                        <div className={classes.containerBody}>
                            <div className={classes.contentBody}>
                                <div className={classes.contentRecipes}>
                                    {listRecipes.map((item, index) => <ButtonCardRecipe recipe={item} key={index} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </body >
            }
        </>
    )
}


interface IButtonCardRecipe {
    recipe: RecipeDTO;
}

function ButtonCardRecipe({ recipe }: IButtonCardRecipe) {
    const navigate = useNavigate();

    function handleViewRecipe(id: string) {
        navigate(`/viewRecipe/${id}`)
    }

    return (
        <button className={classes.buttonRecipeContent} onClick={() => handleViewRecipe(recipe.id)}>
            <div className={classes.buttonRecipeTimeContent}>
                <Clock size={16} color="#FF6E00" weight="bold" />
                <p className={classes.buttonRecipeTimeText}>{converterMinutes(recipe.time)}</p>
            </div>
            <img src={recipe.photo} className={classes.buttonRecipePhoto} />
            <p className={classes.buttonRecipeTitle}>{recipe.title}</p>
        </button>
    )
}

export default FavoritesRecipes;