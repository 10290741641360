
import { useState } from 'react';
import { Lock } from '@phosphor-icons/react';

import { useAuth } from '../../../hooks/auth';
import { useAlert } from '../../../hooks/alert';

import { deleteAccount } from '../../../dao/UserDAO';

import ButtonPrimary from '../../../components/ButtonPrimary';
import HeaderNew from '../../../components/HeaderNew';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';

import Image from '../../../images/vector-pizza.png'

import classes from './style.module.css'

const DeleteAccount = () => {
    const { user, logoutUser } = useAuth();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [password, setPassword] = useState('');

    function cancelModal() {
        setPassword('')
        setModal(false)
    }

    async function handleDelete() {
        if (password.length === 0) {
            return await showAlert({
                message: 'Digite sua senha',
                type: 'error'
            })
        }

        setLoading(true);

        setTimeout(async () => {
            const response = await deleteAccount(user.id, password);

            if (response.statusCode === 200) {
                setModal(false)
                await showAlert({
                    message: 'Conta excluída.',
                    type: 'success'
                })
                return logoutUser();
            } else {
                setLoading(false)
                return await showAlert({
                    message: response.message ?? 'Falha ao excluir a conta!',
                    type: 'warning'
                })
            }
        }, 1000);
    }

    return (
        <body>
            <div className={classes.container}>
                <div className={classes.containerHeader}>
                    <div className={classes.contentHeader}>
                        <HeaderNew />
                    </div>
                </div>
                <div className={classes.containerBody}>
                    <div className={classes.contentBody}>
                        <img src={Image} className={classes.image} />
                        <p className={classes.text}>
                            Lamento ver você partir. Se você deseja excluir permanentemente sua conta, clique em excluir conta.
                        </p>
                        <ButtonPrimary
                            text="Excluir Conta"
                            onClick={() => setModal(true)}
                        />
                    </div>
                </div>
            </div>
            {modal &&
                <Modal
                    loading={loading}
                    title="Confirmar Exclusão"
                    onRequestConfirm={handleDelete}
                    textButtonConfirm="Excluir"
                    onRequestClose={cancelModal}
                >
                    <Input
                        icon={<Lock />}
                        placeholder='Senha'
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type='password'
                    />
                </Modal>
            }
        </body >
    )
}

export default DeleteAccount;