import { Route, Routes } from "react-router-dom";
import { useAlert } from "../hooks/alert";

import ErrorPage from "../pages/ErrorPage";
import Home from "../pages/Home";
import MyAccount from "../pages/MyAccount";
import ListIngredients from "../pages/ListIngredients";
import NewAccount from "../pages/NewAccount";
import ViewRecipe from "../pages/ViewRecipe";
import ActivationCode from "../pages/ActivationCode";
import NewRecipeOld from "../pages/NewRecipe";
import ViewListRecipes from "../pages/ViewListRecipes";
import NewReportBug from "../pages/NewReportBug";

import WhoWeAre from "../pages/Footer/WhoWeAre";
import PrivacyPolicy from "../pages/Footer/PrivacyPolicy";
import TermsOfUse from "../pages/Footer/TermsOfUse";
import Advertise from "../pages/Footer/Advertise";
import Contact from "../pages/Footer/Contact";

import Administrator from "../pages/Administrator";
import IngredientCategories from "../pages/Administrator/IngredientCategories";
import IngredientMeasurements from "../pages/Administrator/IngredientMeasurements";
import RecipeCategories from "../pages/Administrator/RecipeCategories";
import RecipePending from "../pages/Administrator/RecipePending";
import Users from "../pages/Administrator/Users";
import Called from "../pages/Administrator/Called";
import PlansAdmin from "../pages/Administrator/Plans";
import Ingredients from "../pages/Administrator/Ingredients";
import PushNotification from "../pages/Administrator/PushNotification";

import Login from "../pages/Login";
import RecoverPassword from "../pages/RecoverPassword";

import Menu from "../pages/Menu";
import Help from "../pages/Menu/Help";
import Plans from "../pages/Menu/Plans";
import DeleteAccount from "../pages/Menu/DeleteAccount";
import Notifications from "../pages/Menu/Notifications";

import Alert from "../components/Alert";

import { ProtectedRoute } from "./ProtectedRoute";
import { ProtectedRouteAdmin } from "./ProtectedRouteAdmin";
import AdministratorDetails from "../pages/Administrator/details";
import NewRecipe from "../pages/Menu/Recipes/New";
import FavoritesRecipes from "../pages/Menu/Recipes/Favorites";
import MyRecipes from "../pages/Menu/Recipes/MyRecipes";
import RegistryLog from "../pages/Administrator/RegistryLog";
import RecipeViewList from "../pages/Administrator/RecipeViewList";
import Index from "../pages/IndexOLD";


const Router = () => {
  const { visible } = useAlert();

  return (
    <Alert visible={visible}>
      <Routes>
        {/* PUBLICAS */}
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recoverPassword" element={<RecoverPassword />} />
        <Route path="/newAccount" element={<NewAccount />} />
        <Route path="/listIngredients" element={<ListIngredients />} />
        <Route path="/viewRecipe/:id" element={<ViewRecipe />} />
        <Route path="/viewListRecipes" element={<ViewListRecipes />} />
        {/* FOOTER */}
        <Route path="/quem-somos" element={<WhoWeAre />} />
        <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route path="/termos-de-uso" element={<TermsOfUse />} />
        <Route path="/anuncie" element={<Advertise />} />
        <Route path="/contato" element={<Contact />} />
        {/* ERROR */}
        <Route path="*" element={<ErrorPage />} />
        <Route path="/error/:type" element={<ErrorPage />} />
        {/* PROTEGIDAS */}
        <Route path="/activationCode" element={
          <ProtectedRoute>
            <ActivationCode />
          </ProtectedRoute>
        } />
        <Route path="/menu" element={
          <ProtectedRoute>
            <Menu />
          </ProtectedRoute>
        } />
        <Route path="/menu/recipes/new" element={
          <ProtectedRoute>
            <NewRecipe />
          </ProtectedRoute>
        } />
        <Route path="/menu/recipes/favorites" element={
          <ProtectedRoute>
            <FavoritesRecipes />
          </ProtectedRoute>
        } />
        <Route path="/menu/recipes/myRecipes" element={
          <ProtectedRoute>
            <MyRecipes />
          </ProtectedRoute>
        } />
        <Route path="/menu/plans" element={
          <ProtectedRoute>
            <Plans />
          </ProtectedRoute>
        } />
        <Route path="/menu/help" element={
          <ProtectedRoute>
            <Help />
          </ProtectedRoute>
        } />
        <Route path="/menu/deleteAccount" element={
          <ProtectedRoute>
            <DeleteAccount />
          </ProtectedRoute>
        } />
        <Route path="/menu/notifications" element={
          <ProtectedRoute>
            <Notifications />
          </ProtectedRoute>
        } />
        <Route path="/myAccount" element={
          <ProtectedRoute>
            <MyAccount />
          </ProtectedRoute>
        } />
        <Route path="/newRecipe" element={
          <ProtectedRoute>
            <NewRecipeOld />
          </ProtectedRoute>
        } />
        <Route path="/newReportBug" element={
          <ProtectedRoute>
            <NewReportBug />
          </ProtectedRoute>
        } />
        <Route path="/newReportBug/:type" element={
          <ProtectedRoute>
            <NewReportBug />
          </ProtectedRoute>
        } />
        {/* ADMINISTRADOR */}
        <Route path="/administrator" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <Administrator />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/dashboard" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <AdministratorDetails />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/ingredient-categories" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <IngredientCategories />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/ingredient-measurements" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <IngredientMeasurements />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/recipe-categories" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <RecipeCategories />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/ingredients" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <Ingredients />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/recipe-pending" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <RecipePending />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/users" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <Users />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/called" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <Called />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/plans" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <PlansAdmin />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/push-notification" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <PushNotification />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
        <Route path="/administrator/log" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <RegistryLog />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
      <Route path="/administrator/recipeViewList" element={
          <ProtectedRoute>
            <ProtectedRouteAdmin>
              <RecipeViewList />
            </ProtectedRouteAdmin>
          </ProtectedRoute>
        } />
      </Routes>
    </Alert>
  )
}

export default Router;