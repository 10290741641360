import { Camera, Pencil } from '@phosphor-icons/react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

import { useAuth } from '../../hooks/auth';

import classes from './style.module.css';

const MyAccount = () => {
    const { user, logoutUser } = useAuth();

    return (
        <div className={classes.container}>
            <div className={classes.containerHeader}>
                <Header back />
            </div>
            <div className={classes.body}>
                <div className={classes.content}>
                    <button className={classes.buttonPhoto}>
                        {user.photo ?
                            <>
                                <img src={user.photo} className={classes.photo} />
                                <Pencil className={classes.iconAbsolute} />
                            </>
                            :
                            <div className={classes.contentPhoto}>
                                <Camera className={classes.icon} />
                                <p>Adicionar foto.</p>
                            </div>
                        }
                    </button>
                    <p className={classes.name}>{user.name}</p>
                    <div className={classes.cardContent}>
                        <div className={classes.cardContentTitle} style={{ backgroundColor: user.category?.color || '#AEAEB3' }}>
                            <p className={classes.cardTitle}>{user.category?.label}</p>
                        </div>
                    </div>
                    <div className={classes.line}><p className={classes.lineText}>MEUS DADOS</p></div>
                    <p className={classes.text}>{user.birthDate}</p>
                    <p className={classes.textMarginTop}>{user.city}/{user.state}</p>
                    <div className={classes.line}></div>
                    <button className={classes.buttonLogout} onClick={logoutUser}>Sair</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MyAccount;