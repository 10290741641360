import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App';

import './index.css';

import AppProvider from './routes/AppProvider';

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter basename="/">
    <AppProvider>
      <App />
    </AppProvider>
  </BrowserRouter>
)
