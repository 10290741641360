import Footer from '../../../components/Footer';
import Header from '../../../components/Header';

import classes from './style.module.css';

const PrivacyPolicy = () => {
    return (
        <div className={classes.container}>
            <div className={classes.containerHeader}>
                <Header back />
            </div>
            <div className={classes.body}>
                <div className={classes.content}>
                    <p className={classes.title}>Política de privacidade</p>
                    <div className={classes.line} />
                    <p className={classes.subTitle}>Coleta de Informações:</p>
                    <p>Informações fornecidas voluntariamente: Podemos coletar informações pessoais, como nome, endereço de e-mail, data de nascimento, cidade, estado, preferências alimentares e outras informações relevantes quando você se inscrever para receber newsletters, criar uma conta de usuário ou interagir com o site de qualquer outra forma.</p>
                    <p>Informações de uso: Podemos coletar informações sobre como você utiliza o site, como páginas visitadas, receitas visualizadas, pesquisas realizadas e interações com o conteúdo. Essas informações são coletadas de forma anônima e podem ser usadas para melhorar a experiência do usuário.</p>
                    <p className={classes.subTitle}>Uso das Informações:</p>
                    <p>Personalização da experiência do usuário: As informações coletadas podem ser usadas para personalizar o conteúdo do site, recomendar receitas com base nas preferências do usuário e fornecer uma experiência mais relevante.</p>
                    <p>Comunicações: Podemos enviar comunicações, como newsletters, atualizações de receitas, ofertas especiais e informações relevantes ao seu interesse. Você pode optar por não receber essas comunicações a qualquer momento.</p>
                    <p> Análise de dados: As informações coletadas podem ser usadas para análises estatísticas e estudos de mercado, a fim de melhorar o conteúdo, a funcionalidade e a usabilidade do site.</p>
                    <p className={classes.subTitle}>Compartilhamento de Informações:</p>
                    <p>Terceiros: Podemos compartilhar informações pessoais com parceiros confiáveis, como fornecedores de serviços de e-mail marketing, análise de dados ou hospedagem de site, para auxiliar na operação do site e fornecer serviços relacionados. Esses terceiros estão sujeitos a obrigações de confidencialidade.</p>
                    <p>Consentimento: Não compartilharemos suas informações pessoais com terceiros sem seu consentimento expresso, exceto quando exigido por lei ou para proteger nossos direitos legais.</p>
                    <p className={classes.subTitle}>Cookies e Tecnologias de Rastreamento:</p>
                    <p> Cookies: Utilizamos cookies e tecnologias similares para melhorar a experiência do usuário, fornecer funcionalidades personalizadas e realizar análises. Você pode gerenciar as preferências de cookies do seu navegador a qualquer momento.</p>
                    <p> Análise de Terceiros: Podemos utilizar serviços de terceiros, como Google Analytics, para coletar informações sobre o uso do site e realizar análises estatísticas. Esses serviços podem usar cookies e tecnologias similares para rastrear informações anônimas sobre os usuários.</p>
                    <p className={classes.subTitle}> Segurança das Informações:</p>
                    <p> Implementamos medidas de segurança adequadas para proteger as informações pessoais dos usuários contra acesso não autorizado, alteração, divulgação ou destruição.</p>
                    <p> No entanto, não podemos garantir a segurança absoluta das informações transmitidas ou armazenadas eletronicamente. O uso do site é por conta e risco do usuário.</p>
                    <p className={classes.subTitle}> Links para Sites de Terceiros:</p>
                    <p> Nosso site pode conter links para sites de terceiros. Não nos responsabilizamos pela privacidade, segurança ou conteúdo desses sites. Recomendamos que os usuários revisem as políticas de privacidade desses sites antes de fornecer qualquer informação pessoal.</p>
                    <p> Esta Política de Privacidade pode ser atualizada periodicamente, e qualquer modificação será publicada nesta página. É recomendado que você revise regularmente esta política para estar ciente de quaisquer alterações.</p>
                    <p> Se você tiver alguma dúvida ou preocupação em relação a esta Política de Privacidade, entre em contato conosco através dos canais de comunicação fornecidos no site.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;