import { useEffect, useState } from 'react';
import { MagnifyingGlass } from '@phosphor-icons/react';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Loading from '../../components/Loading';

import { IngredientDTO } from '../../dto/IngredientDTO';

import Accordion from '../../components/Accordion';
import { IngredientCategoryDTO } from '../../dto/IngredientCategoryDTO';
import Footer from '../../components/Footer';
import ButtonIngredient from '../../components/ButtonIngredient';
import ButtonPrimary from '../../components/ButtonPrimary';
import { listAll } from '../../dao/IngredientDAO';
import { useAlert } from '../../hooks/alert';
import { removeAccents } from '../../hooks/removeAccents';
import { parseCookies } from 'nookies';
import { recoverSelectedIngredients, saveSelectedIngredients } from '../../dao/UserDAO';
import CardNotFound from '../../components/CardNotFound';
import { useNavigate } from 'react-router-dom';

import HeaderNew from '../../components/HeaderNew';
import classes from './style.module.css'

const ListIngredients = () => {
    const navigate = useNavigate()
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [listIngredients, setListIngredients] = useState<IngredientCategoryDTO[]>([]);
    const [listIngredientsFoundSearch, setListIngredientsFoundSearch] = useState<IngredientDTO[]>([]);
    const [listSelectedIngredients, setListSelectedIngredients] = useState<IngredientDTO[]>([]);
    const [textSearch, setTextSearch] = useState('');

    async function loadListAllIngredients() {
        const result = await listAll();

        if (result.statusCode === 200) {
            setListIngredients(result.data)
        }
    }

    function handleSelectdIngredient(ingredient: IngredientDTO) {
        let index = listSelectedIngredients.findIndex(obj => obj == ingredient);

        if (index === -1) {
            setListSelectedIngredients([...listSelectedIngredients, ingredient])
        } else {
            setListSelectedIngredients(listSelectedIngredients.filter((item) => item.id !== ingredient.id))
        }
    };

    function handleFoundSearch() {
        if (textSearch.length > 2) {
            let ingredients: IngredientDTO[] = [];

            listIngredients.map(category => {
                category?.ingredients &&
                    category.ingredients.find(item => {
                        if (removeAccents(item.title).toUpperCase().includes(removeAccents(textSearch).toUpperCase())) {
                            ingredients.push(item);
                        }
                    })
            })

            setListIngredientsFoundSearch(ingredients);
        }
    };

    async function loadStorage() {
        await recoverSelectedIngredients();

        const ingredients = localStorage.getItem('@letscook.ingredients')

        if (ingredients !== null) {
            const myIngredients: IngredientDTO[] = JSON.parse(ingredients);

            if (Object.keys(myIngredients).length > 0) {
                setListSelectedIngredients(myIngredients);
            }
        }
    }

    async function handleContinue() {
        if (Object.keys(listSelectedIngredients).length > 0) {
            setButtonLoading(true)

            const result = await saveSelectedIngredients(listSelectedIngredients)

            if (result.statusCode === 200) {
                showAlert({
                    message: 'Ingredientes salvos com sucesso.',
                    type: 'success'
                })
                return navigate('/')
            } else {
                showAlert({
                    message: `${result.message}`,
                    type: 'error'
                })
            }

            setButtonLoading(false)
        } else {
            showAlert({
                message: 'Você não tem ingredientes selecionados.',
                type: 'error'
            })
        }
    };

    useEffect(() => {
        handleFoundSearch()
    }, [textSearch]);

    async function loadInitial() {
        await loadListAllIngredients()
        await loadStorage()

        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    useEffect(() => {
        loadInitial()
    }, [])

    return (
        <>
            {loading ?
                <Loading text='Carregando ingredientes...' />
                :
                <body>
                    <div className={classes.container}>
                        <div className={classes.containerHeader}>
                            <div className={classes.contentHeader}>
                                <HeaderNew title='Ingredientes' />
                            </div>
                        </div>
                        <div className={classes.containerBody}>
                            <div className={classes.contentBody}>
                                <div className={classes.contentInput}>
                                    <Input
                                        icon={<MagnifyingGlass />}
                                        placeholder='Pesquisar ingrediente'
                                        onChange={e => setTextSearch(e.target.value)}
                                        value={textSearch}
                                    />
                                </div>
                                <div className={classes.contentList}>
                                    {textSearch.length <= 2 ?
                                        listIngredients.map((category, index) => {
                                            return (
                                                <Accordion key={index} category={category} >
                                                    <>
                                                        {(category?.ingredients) ?
                                                            <div className={classes.containerIngredients}>
                                                                {category.ingredients.map((ingredient, index) => {
                                                                    let selected = listSelectedIngredients.findIndex((obj: IngredientDTO) => obj.id === ingredient.id);

                                                                    return (
                                                                        <ButtonIngredient
                                                                            key={index}
                                                                            ingredient={ingredient}
                                                                            selected={selected >= 0}
                                                                            onClick={() => handleSelectdIngredient(ingredient)}
                                                                        />
                                                                    )
                                                                })}
                                                            </div>
                                                            :
                                                            <div className={classes.containerNotFound}>
                                                                <p className={classes.textNotFound}>
                                                                    NENHUM INGREDIENTE ENCONTRADO
                                                                </p>
                                                            </div>
                                                        }
                                                    </>
                                                </Accordion>
                                            )
                                        })
                                        :
                                        <>{
                                            (Object.keys(listIngredientsFoundSearch).length > 0) ?
                                                <>
                                                    <div className={classes.containerIngredientTitle}>
                                                        <p className={classes.ingredientTitle}>INGREDIENTE(S) ENCONTRADO(S)</p>
                                                    </div>
                                                    <div className={classes.containerIngredients}>

                                                        {
                                                            listIngredientsFoundSearch.map((ingredient, index) => {
                                                                let selected = listSelectedIngredients.findIndex(obj => obj.id === ingredient.id);

                                                                return (
                                                                    <ButtonIngredient
                                                                        key={index}
                                                                        ingredient={ingredient}
                                                                        selected={selected >= 0}
                                                                        onClick={() => handleSelectdIngredient(ingredient)}
                                                                    />
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </>
                                                :
                                                <div className={classes.containerNotFound}>
                                                    <p className={classes.textNotFound}>
                                                        NENHUM INGREDIENTE ENCONTRADO
                                                    </p>
                                                </div>
                                        }
                                        </>
                                    }
                                </div>
                                <div className={classes.buttonContent}>
                                    <ButtonPrimary
                                        text={Object.keys(listSelectedIngredients).length > 0 ? `SALVAR (${Object.keys(listSelectedIngredients).length})` : 'SALVAR'}
                                        onClick={handleContinue}
                                        marginTop={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </body >
            }
        </>
    )
}

export default ListIngredients;