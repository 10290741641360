import { useNavigate, useParams } from 'react-router-dom';

import ButtonPrimary from '../../components/ButtonPrimary';

import { ReactComponent as Logo } from '../../images/logo-orange.svg';

import Error401 from '../../images/vetor-error-401.jpg';
import Error404 from '../../images/vetor-error-404.jpg';
import Error500 from '../../images/vetor-error-500.jpg';

import classes from './style.module.css';
import ButtonSecondary from '../../components/ButtonSecondary';


const ErrorPage = () => {
  let { type } = useParams();
  const navigate = useNavigate();

  type = (type == undefined ? '404' : type)
  const typeError = ['401', '404', '500'];

  if (typeError.indexOf(type) == -1) {
    type = '404';
  }

  const text401 = 'Você precisa criar uma conta para acessar essa página.';
  const text404 = 'Desculpe, não temos mais o que você procura.';
  const text500 = 'Desculpe, ocorreu um erro interno.';

  function handleRoute(route: string) {
    navigate(route);
  }


  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.details}>
          <Logo className={classes.logo} />
          <p className={classes.text}>
            {type == '401' && text401}
            {type == '404' && text404}
            {type == '500' && text500}
          </p>
        </div>
        {type == '401' && <img src={Error401} className={classes.image} />}
        {type == '404' && <img src={Error404} className={classes.image} />}
        {type == '500' && <img src={Error500} className={classes.image} />}
      </div>
      <div className={classes.containerButton}>
        <ButtonPrimary
          text='voltar'
          marginTop={false}
          onClick={() => handleRoute('/')}
          />
          {type == '401' &&
            <ButtonSecondary
              text='CRIAR CONTA'
              marginTop={false}
              onClick={() => handleRoute('/newAccount')}
            />
          }
      </div>
    </div>
  );
}

export default ErrorPage;