export function validateName(nome: string) {
    const fullName = /[A-z][ ][A-z]/;
    return fullName.test(nome);
}

export function validateMail(mail: string) {
    let usuario = mail.substring(0, mail.indexOf("@"));
    let dominio = mail.substring(mail.indexOf("@") + 1, mail.length);

    if ((usuario.length >= 1) &&
        (dominio.length >= 3) &&
        (usuario.search("@") == -1) &&
        (dominio.search("@") == -1) &&
        (usuario.search(" ") == -1) &&
        (dominio.search(" ") == -1) &&
        (dominio.search(".") != -1) &&
        (dominio.indexOf(".") >= 1) &&
        (dominio.lastIndexOf(".") < dominio.length - 1)) {

        return true;
    }
    else {
        return false;
    }
}

export function validateDateOfBirth(date: string) {

    let currentDate = new Date();
    let maximumDate = convertDate('01/01/1900');
    let birthDate;
    let birthDateConverted;

    if (date.indexOf('-') === 1) {
        // FORMAT 00/00/0000
        birthDate = date;
        birthDateConverted = convertDate(date);
    } else {
        // FORMATO 0000-00-00
        birthDate = convertDateInput(date);
        birthDateConverted = convertDate(convertDateInput(date));
    }

    maximumDate = new Date(maximumDate);
    birthDateConverted = new Date(birthDateConverted);

    let day = birthDate.split("/")[0];
    let month = birthDate.split("/")[1];
    let year = birthDate.split("/")[2];
    let MyData = new Date(birthDateConverted);

    if ((MyData.getMonth() + 1 != Number(month)) ||
        (MyData.getDate() != Number(day)) ||
        (MyData.getFullYear() != Number(year))) {
        return false;
    }

    if (birthDateConverted < currentDate && birthDateConverted > maximumDate) {
        return true;
    } else {
        return false;
    }
}

function convertDate(dateString: any) {
    var dateParts = dateString.split("/");
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    return dateObject
}

export function convertDateInput(date: any) {
    let year = date.split("-")[0];
    let month = date.split("-")[1];
    let day = date.split("-")[2];
    const newDate = day + '/' + month + '/' + year

    return newDate
}