import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RecipeDTO } from '../../dto/RecipeDTO';
import { listRecipesWithMyIngredients } from '../../dao/RecipeDAO';
import { IngredientDTO } from '../../dto/IngredientDTO';
import { RecipeCategoryDTO } from '../../dto/RecipeCategoryDTO';

import Loading from '../../components/Loading';
import CardRecipe from '../../components/CardRecipe';
import CardCategoryRecipe from '../../components/CardCategoryRecipe';
import HeaderNew from '../../components/HeaderNew';

import { useAlert } from '../../hooks/alert';

import classes from './style.module.css'

const ViewListRecipes = () => {
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(true)

    const [listIngredientsUser, setListIngredientsUser] = useState<IngredientDTO[]>([]);
    const [listRecipesData, setListRecipesData] = useState<RecipeDTO[]>([]);
    const [listRecipesFoundSearch, setListRecipesFoundSearch] = useState<RecipeDTO[]>([]);
    const [listCategories, setListCategories] = useState<RecipeCategoryDTO[]>([]);
    const [listCategoriesFoundSearch, setListCategoriesFoundSearch] = useState<RecipeCategoryDTO[]>([]);
    const [isFindRecipe, setIsFindRecipe] = useState(false);

    const [listRecipesTotal, setListRecipesTotal] = useState<RecipeDTO[]>([]);
    const [listRecipesOne, setListRecipesOne] = useState<RecipeDTO[]>([]);
    const [listRecipesTwo, setListRecipesTwo] = useState<RecipeDTO[]>([]);
    const [listRecipesOthers, setListRecipesOthers] = useState<RecipeDTO[]>([]);

    function handleFindCategoryRecipe(category: RecipeCategoryDTO) {
        setListCategoriesFoundSearch([category])
        setIsFindRecipe(true)
    }

    async function loadStorageIngredients() {
        const listIngredientsUserStorage = localStorage.getItem('@letscook.ingredients')

        if (listIngredientsUserStorage == null) {
            return navigate(-1)
        }

        setListIngredientsUser(JSON.parse(listIngredientsUserStorage))

        const result = await listRecipesWithMyIngredients(JSON.parse(listIngredientsUserStorage))

        if (result.statusCode === 200) {
            setListRecipesData(result.data);
            setListRecipesFoundSearch(result.data)
        } else {
            showAlert({
                'message': 'Não encontramos nenhuma receita!',
                'type': 'warning'
            })
            return navigate('/')
        }
    }

    async function loadFilterRecipes() {
        setListRecipesTotal([])
        setListRecipesOne([])
        setListRecipesTwo([])
        setListRecipesOthers([])

        listRecipesFoundSearch.map((recipe) => {
            const totalIngredients = loadInformation(listIngredientsUser, recipe);

            if (totalIngredients === 0) {
                setListRecipesTotal(old => [...old, recipe])
            } else if (totalIngredients === 1) {
                setListRecipesOne(old => [...old, recipe])
            } else if (totalIngredients === 2) {
                setListRecipesTwo(old => [...old, recipe])
            } else {
                setListRecipesOthers(old => [...old, recipe])
            }
        })
    }

    async function loadInitial() {
        await loadStorageIngredients();

        setLoading(false)
    }

    async function loadListCategories() {
        let list: RecipeCategoryDTO[] = [];
        let categoryAll = listRecipesData[0]?.categories.find(item => item.label == 'Todas');

        listRecipesData.map(recipe => {
            recipe.categories.map(categoryRecipe => {
                if (categoryRecipe.label !== 'Todas') {
                    if (categoryRecipe.isActive) {
                        const indexExists = list.findIndex(category => category.id === categoryRecipe.id)
                        if (indexExists === -1) {
                            list.push(categoryRecipe)
                        }
                    }
                }
            })
        })

        list.sort((a, b) => {
            let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        })

        if (categoryAll) {
            list.unshift(categoryAll)
        }

        setListCategories(list)
        setListCategoriesFoundSearch([list[0]])
    }

    function loadInformation(listOfIngredients: IngredientDTO[], recipe: RecipeDTO) {
        let total = 0;
        listOfIngredients.map(ingredientUser => {
            let selected = recipe.ingredients.findIndex((ingredientRecipe: IngredientDTO) => ingredientRecipe.id === ingredientUser.id);
            if (selected >= 0) {
                total = total + 1;
            }
        })
        return recipe.countIngredients - total;
    }

    useEffect(() => {
        loadFilterRecipes();
    }, [listRecipesFoundSearch])

    useEffect(() => {
        loadListCategories();
    }, [Object.keys(listRecipesData).length > 0])

    useEffect(() => {
        if (isFindRecipe) {
            let recipes: RecipeDTO[] = [];

            listRecipesData.map(recipe => {
                listCategoriesFoundSearch.map(filterCategory => {
                    recipe.categories.map(categoryList => {
                        if (categoryList.id === filterCategory.id) {
                            recipes.push(recipe);
                        }
                    })
                })
            })

            setListRecipesFoundSearch(recipes);
            setIsFindRecipe(false)
        }
    }, [isFindRecipe])


    useEffect(() => {
        loadInitial()
    }, [])

    return (
        <>
            {loading ? <Loading text='Pesquisando receitas com seus ingredientes selecionados...' /> :
                <body>
                    <div className={classes.container}>
                        <div className={classes.containerHeader}>
                            <div className={classes.contentHeader}>
                                <HeaderNew title='Resultado' />
                            </div>
                        </div>

                        <div className={classes.containerBody}>
                            <div className={classes.contentBody}>

                                {(Object.keys(listCategories).length > 0) &&
                                    <div className={classes.containerCategories}>
                                        <p className={classes.title}>Categorias</p>
                                        <div className={classes.contentCategories}>
                                            {listCategories.map((category, index) => {
                                                let selected = listCategoriesFoundSearch.findIndex(obj => obj == category);
                                                return (
                                                    <CardCategoryRecipe
                                                        key={index}
                                                        category={category}
                                                        selected={selected >= 0}
                                                        onClick={() => handleFindCategoryRecipe(category)}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                }

                                {Object.keys(listRecipesTotal).length > 0 &&
                                    <ContainerRecipe
                                        title='Encontramos essas receitas com os seus ingredientes'
                                        listData={listRecipesTotal}
                                        listIngredientsUser={listIngredientsUser}
                                    />
                                }
                                {Object.keys(listRecipesOne).length > 0 &&
                                    <ContainerRecipe
                                        title='Falta apenas 1 ingrediente'
                                        listData={listRecipesOne}
                                        listIngredientsUser={listIngredientsUser}
                                    />
                                }
                                {Object.keys(listRecipesTwo).length > 0 &&
                                    <ContainerRecipe
                                        title='Falta apenas 2 ingredientes'
                                        listData={listRecipesTwo}
                                        listIngredientsUser={listIngredientsUser}
                                    />
                                }
                                {Object.keys(listRecipesOthers).length > 0 &&
                                    <ContainerRecipe
                                        title='Faltando alguns ingredientes'
                                        listData={listRecipesOthers}
                                        listIngredientsUser={listIngredientsUser}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </body >
            }
        </>
    )
}

interface IContainerRecipe {
    title: string;
    listData: any[];
    listIngredientsUser: any[];
}

function ContainerRecipe({ title, listData, listIngredientsUser }: IContainerRecipe) {
    return (
        <div className={classes.containerRecipes}>
            <div className={classes.contentRecipesHeader}>
                <p className={classes.containerRecipesTitle}>{title}</p>
            </div>
            <div className={classes.contentRecipe}>
                {listData.map((recipe, index) => {
                    return (
                        <CardRecipe
                            key={index}
                            recipe={recipe}
                            listSelectedIngredientsUser={listIngredientsUser}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default ViewListRecipes;