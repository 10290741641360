import { ReactNode } from "react";
import { AuthProvider } from "../hooks/auth";

import { AlertProvider } from "../hooks/alert";

interface AppProviderProps {
    children: ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
    return (
        <AlertProvider>
            <AuthProvider>
                {children}
            </AuthProvider>
        </AlertProvider>
    )
};

export default AppProvider;